import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import {
    Container,
    Row,
    Col,
    Button
} from 'react-bootstrap'
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const NewsCards = () => {

    const data = useStaticQuery(graphql`
    {
      allPrismicBlogPost(sort: {fields: data___published_date, order: DESC}, limit: 3) {
        nodes {
          data {
            published_date(formatString: "MMMM, D YYYY")
            post_title {
              text
            }
            post_excerpt {
              text
            }
            post_content {
              text
            }
            featured_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(aspectRatio: 1.5, layout: FULL_WIDTH, placeholder: BLURRED, transformOptions: {cropFocus: CENTER})
                }
              }
              alt
            }
          }
          uid
          url
          tags
        }
      }
    }
  `)

    const document = data.allPrismicBlogPost.nodes;
    console.log(document);
  return (
      <>
                <Container>
                    <Row className="py-4">
                        <Col>
                            <h2 className="text-center sectionTitle">Teton Valley Mental Health Coalition News</h2>
                        </Col>
                    </Row>
                    <Row className="pb-4">
                        {document.map((post, index) => {
                            return (
                                <>
                                    <Col key={index} md={4} className="p-2" d="flex">
                                      <div style={{backgroundColor: '#D8D8F6F2', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', borderRadius: '30px'}} className="p-4">
                                        <div>
                                            <h3>{post.data.post_title.text}</h3>
                                            <p>{`Published ${post.data.published_date}`}</p>
                                        </div>
                                        <div className="mb-4">
                                          {post.data.featured_image.localFile !== null ? (
                                            <GatsbyImage image={getImage(post.data.featured_image.localFile)} alt={post.data.featured_image.alt} />
                                          ) : (
                                            <StaticImage src="../images/tvmhc-logo_1.png" alt="Placeholder image" />
                                          )}
                                            
                                        </div>
                                        <div>
                                        <p>{post.data.post_excerpt.text}</p>
                                        </div>
                                        <div className="text-right">
                                        <Link to={`/blog/${post.uid}`}>
                                            <Button className="purpleButton">
                                                {`Read More`} <span><FontAwesomeIcon icon={faAngleRight} /></span>
                                            </Button>
                                            </Link>
                                        </div>
                                        </div>
                                    </Col>
                                </>
                            )
                        }
                        )}
                    </Row>
                    <Row>
                        <Col className="text-right">
                            <Link to="/blog" style={{fontSize: '1.25rem'}}>More News <span><FontAwesomeIcon icon={faAngleRight} /></span></Link>
                        </Col>
                    </Row>
                </Container>
      </>
  );
}

export default NewsCards;