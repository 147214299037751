import React from 'react'
import styled from 'styled-components'

import Fade from 'react-reveal/Fade';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCommentExclamation,
        faLongArrowAltRight,
        faCalendarAlt,
        faComments,
        faHandHoldingUsd } from "@fortawesome/pro-solid-svg-icons"

const CardWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    margin-bottom: 4rem;
    justify-items: center;
        .arrowIcon {
            transform: rotate(90deg);
        }
    
    @media(min-width: 768px) {
        grid-template-columns: 1fr .5fr 1fr .5fr 1fr .5fr 1fr;
        .arrowIcon {
            transform: rotate(0deg);
        }
    }
`;

const Card = styled.div`
    text-align: center;
    width: 100%;
`;

const CardTitle = styled.p`
    color: #0093AA;
    font-family: mr-eaves-xl-modern, sans-serif;
    font-weight: bold;
    font-size: 24px;
`;

const CardCopy = styled.p`
    color: #0093AA;
    font-family: proxima-nova, sans-serif;
`;

const ProgramCards = (props) => {
    return (
        <CardWrapper>
            <Fade>
                <Card>
                <FontAwesomeIcon
                        icon={faCommentExclamation}
                        aria-hidden="true"
                        style={{
                            color: '#8DA7D9',
                            width: '80px',
                            height: 'auto'
                        }}                   
                    />
                <CardTitle>
                    Someone needs help
                </CardTitle>
                <CardCopy>
                    Our website guides you to local therapists and mental health resources.
                </CardCopy>
                </Card>
            </Fade>
            <Fade delay={500}>
                <Card delay={500}>
                <FontAwesomeIcon
                    className="arrowIcon"
                        icon={faLongArrowAltRight}
                        aria-hidden="true"
                        style={{
                            color: '#87C3CC',
                            width: 'auto',
                            height: '68px',
                        }}                   
                    />
                </Card>
            </Fade>
            <Fade delay={1000}>
                <Card>
                <FontAwesomeIcon
                        icon={faCalendarAlt}
                        aria-hidden="true"
                        style={{
                            color: '#8DA7D9',
                            width: '65px',
                            height: 'auto'
                        }}                   
                    />
                <CardTitle>
                    Pick a therapist
                </CardTitle>
                <CardCopy>
                <a href="mailto:info@tetonvalleymentalhealth.com">Contact us</a> to ask about free services and to set up your first appointment.
                </CardCopy>
                </Card>
            </Fade>
            <Fade delay={1500}>
                <Card>
                <FontAwesomeIcon
                className="arrowIcon"
                        icon={faLongArrowAltRight}
                        aria-hidden="true"
                        style={{
                            color: '#87C3CC',
                            width: 'auto',
                            height: '68px',
                        }}                   
                    />
                </Card>
            </Fade>
            <Fade delay={2000}>
                <Card>
                <FontAwesomeIcon
                        icon={faComments}
                        aria-hidden="true"
                        style={{
                            color: '#8DA7D9',
                            width: '80px',
                            height: 'auto'
                        }}                   
                    />
                <CardTitle>
                    Start therapy
                </CardTitle>
                <CardCopy>
                Get the help you or your loved one needs to begin the journey towards better mental health.
                </CardCopy>
                </Card>
            </Fade>
            <Fade delay={2500}>
                <Card>
                <FontAwesomeIcon
                className="arrowIcon"
                        icon={faLongArrowAltRight}
                        aria-hidden="true"
                        style={{
                            color: '#87C3CC',
                            width: '60px',
                            height: 'auto',
                        }}                   
                    />
                </Card>
            </Fade>
            <Fade delay={3000}>
                <Card>
                <FontAwesomeIcon
                        icon={faHandHoldingUsd}
                        aria-hidden="true"
                        style={{
                            color: '#8DA7D9',
                            width: '80px',
                            height: 'auto'
                        }}                   
                    />
                <CardTitle>
                    Needs Based Pay
                </CardTitle>
                <CardCopy>
                With community donations, the Coalition is able to offer limited free counseling to those in financial need.
                </CardCopy>
                </Card>
            </Fade>

        </CardWrapper>
    )
}

export default ProgramCards