import React from 'react'
import { Modal, Button, Row, Col } from 'react-bootstrap'

function HomepageModal(props) {
    return (
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
            <Modal.Body>
                <p style={{fontSize: '1.25rem'}}>
                To access our free counseling program, please contact us at <a href="mailto:info@tetonvalleymentalhealth.com">info@tetonvalleymentalhealth.com</a> to determine eligibility and apply for sessions.
                </p>
                <p className="text-right"><Button className="btn btn-action purpleButton" onClick={props.onHide}>Ok</Button></p>
            </Modal.Body>
        </Modal>
    );
}

export default HomepageModal