import React from 'react'

import { AnchorLink } from 'gatsby-plugin-anchor-links'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'

const CardWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;

    @media(min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px;
    background-color: ${props => props.cardBackground};
    border-radius: 25px;
    height: 100%;
    justify-content: space-between;

    > h3 {
        margin-top: 25px;
        font-size: 35px;
        color: #434081;
        font-weight: 700;
    }
    > p {
        color: #434081;
        font-weight: 300;
        font-size: larger;
    }
    > button {
        background-color: #434081;
        border-color: #434081;
    }
    > button:hover {
        background-color: #353266;
        border-color: #353266;
    }
    > button:active {
        background-color: #353266 !important;
        border-color: #353266 !important;
    }
    > button:focus {
        background-color: #353266 !important;
        border-color: #353266 !important;
        outline: none !important
    }
`;

const GetStartedCards = (props) => {
    return (
        <CardWrapper>
            <Card cardBackground="#D8D8F6F2">
                <h3>Child &amp; Family Therapy</h3>
                <p>Counseling tailored to meet the unique developmental needs of children and help families better communicate and resolve conflict.</p>
                <AnchorLink to="/counselors-programs/child-family-therapy#counseling-programs" stripHash>
                    <Button className="purpleButton">See Child &amp; Family Therapists</Button>
                </AnchorLink>
            </Card>
            <Card cardBackground="#BDC37FA7">
                <h3>Adult Therapy</h3>
                <p>Counseling to help individuals learn healthy coping mechanisms and skills to meet life challenges.</p>
                <AnchorLink to="/counselors-programs/adult-therapy#counseling-programs" stripHash><Button className="purpleButton">See Adult Therapists</Button></AnchorLink>
            </Card>
            <Card cardBackground="#C97D6079">
                <h3>Who Qualifies</h3>
                <p>We are limited to help with financial assistance only to residents with these qualifications.</p>
                <AnchorLink to="/counselors-programs/adult-therapy#counseling-programs" stripHash><Button className="purpleButton">See Qualifying Details</Button></AnchorLink>
            </Card>
        </CardWrapper>
    )
}

export default GetStartedCards