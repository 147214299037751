import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import styled from "styled-components"

const HelpedWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  > div > h2 {
    font-size: 6rem;
    text-align: center;
  }
  > div > p {
        text-align: center;
        color: #434081;
        font-weight: 300;
        font-size: 25px
  }
`

const WeHaveHelped = () => {
  return (
    <Container
      fluid
      style={{
        backgroundColor: "#D8D8F6",
        marginTop: '-5px'
      }}
    >
      <Container>
        <Row>
          <Col>
            <h2 className="sectionTitle">We have helped</h2>
          </Col>
        </Row>
        <Row className="pb-4">
          <Col>
            <HelpedWrapper>
              <div>
                <h2 className="sectionTitle">1,300</h2>
                <p>FREE counseling sessions provided to community members in need, as of 2020</p>
              </div>
              <div>
                <h2 className="sectionTitle">100</h2>
                <p>children and youth served this school year with FREE school-based mental health services </p>
              </div>
              <div>
                <h2 className="sectionTitle">18</h2>
                <p>local, professionally licensed counselors in our free counseling program</p>
              </div>
            </HelpedWrapper>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default WeHaveHelped
