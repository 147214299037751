import React from "react";
import styled from "styled-components"

const Floater = styled.svg `
margin: 0 20px 0 20px;
width: 85%;
  height: auto;
  .cls-1 {
        fill: #434081;
      }
`;

const ProfessionalTraining = (props) => {
    return (
<Floater xmlns="http://www.w3.org/2000/svg" viewBox="0 0 566.65998 73.97">
  <defs>
    <style>

    </style>
  </defs>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <path class="cls-1" d="M8.96,33.13l-.15-.12-.12.22-.11.13h0l-.11-.18-.19-.17-.15-.27v-.3l-.11-.52v-.53l.07-.21-.07-.31V28.39l-.19-.48v-.26l.18-.31v-.78l-.14-.7-.08-.14h0v.18H7.71V22.94l-.08-.44.12-.39-.08-.31.08-.52V17.32l-.07-.31v-.17l.07-.52v-.61l.08-.35v-.48l.07-.44v-.39l-.03-.22v-.22l.12-.26V12.26l.07-.74V7.43l.07-2.09V2.2l.34-.19h.15l.34-.13.34.09h.15l.15-.22h.18l.15.05.15.17.12-.08.11-.18h.23l.18.05.23-.05h.37l.23-.13.38.05.37-.13h.68l.19.13.19-.09.15.13h3.21l1.54.22.64.17,1.13.43.71.44,1.24,1.13,1,1.31.45.83.64,1.87.34,2.05.11,1.87v.83l-.15,1.19-.26,1-.24.7-.56,1.17-.71,1.13-.64.92-.9.91-1.39,1.09-1.16.44-1.09.3-1.1.18-2,.13H13.8l-.08.43v.44l-.11.22.11.35v.43l-.15.44.08.3.07.22-.11.3v1.88l-.08,1v1l-.08,1.09-.11,1.13-.27.35-.15.18-.05-.14-.19.09-.19.22-.19.3-.22.31h-.19l-.15.13h-.15v-.3l-.08-.05-.07.09-.19.09-.08.3v.13l-.15.05-.15-.18v-.22h-.08l-.07.13-.11.13-.19.09-.12.3-.11.22h-.07v-.3H9.87l-.19.3H9.34ZM7.87,22.76V21.59h0v1.17Zm6.55-7.32,1.39-.3.56-.13.71-.31.61-.43.26-.18.38-.52.37-1.09V11.13l-.53-1.52-.9-.66-.4-.17h-.34l-.34-.08H15.12l-1,.13-.07.69-.08.92v.87l-.07,1.22v1.74l-.13.74.07.52h.12Zm7.7,2.79.08-.22-.15.09-.08.22Z"/>
      <path class="cls-1" d="M38.96,32.48l-.49-1-.87-1.39-.48-.74-1-1.57-.9-1.39-.64-1-.42-.44-.49-.65-1.61-2.22-.38-.48h-.15v2.61l-.07,1.48v1l-.08.53v4l-.26.57-.38.13-.3-.09-.34.13-.23.05-.3-.09-.38.26-.18-.13h-.08l-.19.13H28.4l-.26.13-.27-.1-.22.14-.15-.09-.15.09-.53.08-.41-.08H26l-.13-.09V23.55l.08-.57V16.36l.08-1.09V7.56l.08-.7-.08-.34V3.21l-.07-.79v-.5l.07-.09H27.2l.07-.08h.68l.19-.13.33.05.23-.05h1l.19-.13h.68l.22.09h1.66l.45-.13.38.13h.33l1.36.08.37.09.53.09.45.17h.26l.27.21,1,.4.86.61.53.52.37.56.38.88.37,1.17.3,1.09.19.65.12.35.07.7.23,1,.07.48-.07.65.15.61v.17l-.11.48v.57l-.15.3-.15.83-.34,1-.19.48-.64,1.09-.15.3-.53.57-.37.52-.34.31-.53.43-.34.35.19.48.19.39.53.7.9,1.26.9,1.44.68,1.05.56.95.9,1.44.64,1.13.34.79.3.65-.26.26-.3.31-.23.13h-.11l-.08.22-.11.21h-.34l-.18.44h-.23v.13l-.22.13h-.27l-.11.26h-.11l-.23.13-.11.22h-.15l-.19.18-.22.22-.15.08-.11.09h-.16v.13l-.19.09h-.41ZM32.6,14.84h.72l.94-.09.22-.17.49-.35.6-.57.45-.52.27-.69v-.83l-.15-1.22-.42-1.39-.45-.35-.4-.14-.75-.31-.68-.08H31.82l-.07.56V14.7l.12.14Z"/>
      <path class="cls-1" d="M51.21,34.01l-1-.35-.79-.39-.79-.53-1-.78-.64-.57-.71-.78-.6-.74-.44-.86-.53-.87-.41-.91-.23-.61-.49-1.18-.26-.87-.38-1.83-.18-1.65V18.15l.11-.74V16.8l.11-1.22.11-.7.15-.87.23-.74.19-1.09.18-.74.23-.48.07-.44.12-.48.15-.48.07-.3.19-.35.19-.65.22-.53.15-.47.38-.53.26-.72.45-.53.37-.69.68-1,.79-.83.83-.7.6-.48,1.09-.56,1-.44L53.44.3l.68-.13L56.3,0h.57l.78.13.79.26.72.35,1.35.83,1,1,.91,1.13.94,1.52.56,1.44.45,1.44.23,1.13.11.83.16,1.29v.78l.07,1v4.45l-.07,1.13-.11,1-.12.7-.11.13-.19.83-.19,1.09v.43l-.19.35.08.39-.11.31-.15.74-.19,1.18-.38,1.39-.34,1.13-.07.39-.19.26-.26.53-.3.48-.19.43-.3.18-.75.87-1.17,1-.68.34-.82.35-.83.22-.68.13-.64.22H53.54l-.86.21Zm3.35-6.8.56-.13h.23l.22-.09.15-.17.38-.17.45-.22.15-.18.27-.34.3-.48.33-.53.27-.89.23-.69.07-.44.11-.31V22.1l.15-.48.15-.26v-.53l.07-.3.19-.31-.11-.35.19-.39v-.35l.08-.56v-.79l.12-.61v-.43l.07-1V13.48l-.07-.78V11.65l-.19-.74L58.52,9.6l-.65-.73L57,7.78l-.71-.48-.83-.29-1.39.22-.6.21h-.41l-.19.09-.38.22L52,8.18l-.3.27-.07.26-.34.3-.49.83-.3.57-.11.34-.19.53-.08.43-.15.39-.15.75-.15.6-.07.4-.15.61-.12,1-.15,1.53-.07,1.3.11,1.09.08,1.05.15,1.17.22.83.12.48.26.57.56,1,1.26,1.29,1.2.83.6.3.15.09-.07.17.15.09Zm-.45.48L54,27.56h-.13l-.08.09h.15l.15.13Zm.52,0v-.08h-.11v.08Zm.49-.26h0L55,27.6Zm3.61-5.49v-.13l.08-.3V21.2h0l-.08.22v.26l-.07.35.07.05Z"/>
      <path class="cls-1" d="M69.75,32.65h0l-.07.13h-.45l-.38.17-.26-.09-.12-.08-.15.08-.3.09-.15.06-.3-.09h-.34l-.15-.13-.15-.18v-.78l-.06-.44v-1l-.08-1.48V26.38l-.11-1.7v-.87l.07-.43V15.79l.08-.65V2.51l.11-.31.27-.13h.3l.26-.09.19.09h2.41l.37-.09h.68l.45-.17h4.51l.42.08h1.12l.34.09.3-.13.6.08h1.47l.45-.13.72.09h.52l.23.13.07.3.19.35v.39l.15.44.19.48-.11.26v.78l-.12.31v.35l.08.3-.27.35v.52l-.26.31-.22.22-.31.13h-.11l.11.21-.07.18-.23-.09-.19.18H80.39l-.19.08-.26-.06h-.27l-.15.17-.11.09h-.19l-.07-.13h-.69l-.25-.13-.23.09h-.56v.09h.07v.08H76.4l-.22.09-.31-.02H75.5l-.26.13H73.66l-.76.09-.26.3v4.58H74.3l.26.09h2.63l.41.09h.38l1,.09.53-.05.6.09v.35l.12.17v.44l.07.26v.52l.12.22v.17l-.07.48v.48l-.07.39.07.22-.07.13-.16.13v.26l.19.14h.23l.07.17-.37.09-.46.31-.15.3h-.15l-.11.18-.11.13.11.09h.19l-.08.17-.33.18-.19.26h-.23l-.19-.05-.22.09h-.3l-.3-.09-.3.09-.42.13H74.37l-.56.09H72.3V21.3l-.08.78v3.93l.08.74-.08.22v1.3l-.11.44v.39l-.08-.26v-.39h0l-.08.09v.34l.07.35-.07.44v1.26l.15.09.12-.13h.07v.18l-.07.17.11.26-.38.18v.34l-.19.09-.15.18-.07-.09h-.11l-.12.14-.18.13h-.27l-.11.17-.11-.13-.23.13-.15.13-.3.09-.08.22h0Zm2.29-5.44v-.14h-.07v.17l.07.09Zm0-1.49v0Zm.11,1.83h0v-.09l-.12.05.08.08Zm8.8-19.82V7.65h-.08Z"/>
      <path class="cls-1" d="M88.36,32.87H87.04l-.37.14-.27-.14h-.37l-.6.09-.23-.05h-.34l-.19-.13h-.15l-.07-.34.11-.53v-.65l.07-.87V26.77l.08-.26V23.2l.16-1.48V11.13L84.8,9.61V8.78l.07-1.61.08-.79V2.25l.26-.09.15-.17h.19l.32.02.52-.05h1.34l.15-.05H88.9l.22-.13h.42l.67-.09h.64l.3-.09h.38l1.09.09,1.8.08.53.05h.45l.53.13h2.52l.15-.05.34.05h1.95l.15.26.15.31.08.43.22.31.23,1.13-.12.52-.07.31v.22l.07.43.15.44.12.21-.23.18-.3.17-.34.57-.45.57-.26.26-.19.13h-.26l-.19-.13H98.07l-.19.13H90.87v4.92l.15.09h3.65l1.12.08h.42l.34.13h1.01l.6.18h.49l.38.44.07.35v.43l.26.35-.15.35.15.3v.74l.15.31-.15.26-.07.35v.61l.15.13.12.13-.12.17h-.15l-.3.27-.26.52.11.26v.13l-.45.13-.26.35h-.64l-2.93-.09-1.24.05-1.66.08-.94.13-.3.22v.26l-.07.92v4.27h1.58l.38.09H93.4l.45.08.94.13h3.53l.49-.09.6.05.61-.05.45.09.15.26.11.35v.35l.22.22v.21l-.15.22.15.18.08.3-.15.09h-.11v.13l.18.18.08.3-.15.44-.08.56-.15.31.27.22.15.13-.08.08-.3.18-.34.26-.11.35v.43l-.75.44H96.33l-.71.05h-.45l-.31.09H88.81l-.15.17h0Z"/>
      <path class="cls-1" d="M106.87,33.17l-.64-.21-.53-.31h-.49l-.48-.26-.86-.48-.49-.31-.3-.26-.49-.35-.34-.3-.34-.22-.26-.3-.38-.35-.3-.39v-.18l-.08-.22v-.21l.12-.18.22-.21.15-.22v-.2l.08-.13.26-.31h.11v-.26l.15-.18v-.17l.08-.13.3-.31.15-.43.18-.09.12-.39.15-.35.19-.13v-.26l.08-.09.11-.26h.3l.19.17.11.13h0l-.08-.09v-.17l.22-.13.75.56.94.74.72.48.68.13.78.05,1-.44.6-.43.53-1,.41-1.43v-.87l-.15-.35-.19-.48-.15-.52-.15-.22-.19-.26-.26-.18-.19-.22-.27-.39-.26-.35-.3-.3-.19-.26-.26-.22-1.06-1.28-.75-.92-.52-.69-1.06-1.44-.75-1.53-.22-.69-.23-1-.07-1.22V8.09l.15-.7.49-1.09.3-.56.27-.44.18-.3.53-.66.49-.56.49-.48.6-.52.75-.57,1-.39.52-.26.83-.31,1.58-.22,1.77.09,1.05.13.38.09.22.13v.17l-.11.05-.08.08.11.18.19.09v.34l-.11.22.15.61h-.15l.08.13v.57l.12.26V4.3h-.15v.18l.22.26-.11.13.11.13v.44l-.19.08v.09l.11.13.23.13.11.13v.09h-.11l-.19.09v.7l-.12.17v.48l-.11.57-.11.17-.26-.22-.91-.3-.67-.13h-1.47l-.9.35-.26.26-.31.26-.33.43-.15,1,.45.87.79.87,1,1.14.64.65.6.74,1,1.22,1.06,1.48.94,1.48.3.74.49,1.62.26,1.56.07,1-.07,1.09-.45,1.65-.38.92-.79,1.57-.67.87-.42.39-.26.39-1.09,1.09-.71.52-.79.48-.94.39-.66.36-.6.13-1.35.13Z"/>
      <path class="cls-1" d="M122.76,33.17l-.64-.21-.53-.31h-.48l-.49-.26-.75-.48-.49-.31-.3-.26-.49-.35-.34-.3-.34-.22-.26-.3-.38-.35-.3-.39v-.18l-.1-.24V28.8l.11-.18.22-.21.15-.22v-.18l.08-.13.26-.31h.12v-.26l.15-.18v-.17l.08-.13.3-.31.15-.43.19-.09.11-.39.15-.35.19-.13v-.26l.08-.09.11-.26h.3l.19.17.11.13h0l-.07-.09v-.17l.22-.13.76.56.94.74.71.48.68.13.79.05,1-.44.6-.43.53-1,.41-1.43v-.87l-.15-.35-.19-.48-.15-.52-.15-.22-.19-.26-.26-.18-.19-.22-.26-.39-.27-.35-.3-.3-.19-.26-.26-.22-1.06-1.28-.76-.92-.52-.69-1.06-1.44-.75-1.53-.22-.69-.23-1-.07-1.22V8.09l.15-.7.49-1.09.3-.56.27-.44.18-.3.53-.66.49-.56.49-.48.6-.52.75-.57,1-.39.53-.26.82-.31,1.58-.22,1.77.09,1.05.13.38.09.22.13v.17l-.11.05-.08.08.12.18.18.09v.34l-.12.22.15.61h-.13l.08.13v.57l.11.26V4.3h-.15v.18l.23.26-.12.13.12.13v.44l-.19.08v.09l.11.13.23.13.11.13v.09h-.11l-.19.09v.7l-.11.17v.48l-.12.57-.11.17-.26-.22-.91-.3-.67-.13h-1.47l-.9.35-.26.26-.3.26-.34.43-.15,1,.45.87.79.87,1,1.14.64.65.6.74,1.06,1.22,1,1.48.94,1.48.3.74.49,1.62.26,1.56.08,1-.08,1.09-.45,1.65-.38.92-.79,1.57-.67.87-.42.39-.26.39-1.09,1.09-.71.52-.79.48-.94.39-.75.27-.61.13-1.35.13Z"/>
      <path class="cls-1" d="M135.24,33.35l-.07-.57-.42-.09h-.18l-.19-.31-.19-.52-.08-.57-.18-.3v-.78l.11-.22v-.21l.15-.35v-.66l-.11-.3V24.85l.11-.43-.07-.87V18.01l-.12-.34v-.4l-.07-.39V14.97l.15-2.27.08-.74V2.16l.19-.35.22-.09.26.09h2.15l.15-.05.15.09.19-.09.18.05h.27l.26-.13.19.13.15-.09.3-.08.34.17h.26l.23-.09.26.09V8.17l-.07.31v.13h.12v.26l-.19.08.10007.06.08.22.07.3.08.26v1.35l-.08.44-.07.22h-.11l-.08.22v.65h.11l.08.09v.57h-.08v.87l.11.09v.17l-.11.17v1.38l.15.14-.08.17v.74l.15.83.08.91v5.93l-.07.74v3.18l-.08,1.13v.44l-.15.61v.43l-.19.26-.27.22h-.22l-.26.17-.08.26h-.15l-.19-.09-.19.13h-.18l-.19.09-.15-.14h-.11l-.12.26-.07.39v-.2l-.12-.44h-.15l-.11.18-.3.34v.18h0l-.08-.18-.19-.13-.15.18-.07.52v.35h0l-.07-.26v-.34l-.39-.1-.15.09-.19.09-.07.26-.08.61-.07.39h-.08ZM139.87,4.6V4.51l-.08.09v.09Zm.15,6.14v-.17h-.07v.09Z"/>
      <path class="cls-1" d="M150.21,34.01l-1-.35-.79-.39-.79-.53-1-.78-.64-.57-.71-.78-.6-.74-.45-.79-.53-.87-.41-.91-.23-.61-.49-1.18-.26-.87-.38-1.83-.18-1.65V18.15l.08-.74V16.8l.11-1.22.11-.7.15-.87.23-.74.19-1.09.18-.74.23-.48.08-.44.11-.48.15-.48.07-.3.19-.35.19-.65.23-.53.15-.47.37-.53.3-.72.45-.53.38-.69.67-1,.79-.83.83-.7.6-.48,1.09-.56,1-.44L152.45.3l.67-.13L155.3,0h.57l.79.13.79.26.71.35,1.35.83,1.06,1,.9,1.13.94,1.52.56,1.44.45,1.44.23,1.13.11.83.15,1.13v.78l.07,1v4.45l-.11,1.29-.11,1-.12.7-.11.13-.19.83-.19,1.09v.43l-.19.35.08.39-.11.31-.15.74-.19,1.18-.38,1.39-.34,1.13-.07.39-.19.26-.26.53-.3.48-.19.43-.3.18-.75.87-1.17,1-.68.34-.82.35-.83.22-.68.13-.64.22h-2.51l-.87.21Zm3.34-6.8.57-.13h.22l.23-.09.15-.17.37-.17.45-.22.15-.18.27-.34.3-.48.34-.53.27-.89.23-.69.07-.44.12-.31V22.1l.15-.48.15-.26v-.53l.08-.3.18-.31-.11-.35.19-.39v-.35l.08-.56v-.79l.12-.61v-.43l.07-1V13.48l-.07-.78V11.65l-.18-.74-.42-1.31-.37-.69-.87-1.09-.71-.48-.87-.26-1.39.22-.6.21h-.41l-.19.09-.38.22-.49.43-.3.27-.07.26-.34.3-.49.83-.3.57-.11.34-.19.53-.07.43-.16.39-.15.75-.15.6-.07.4-.15.61-.11,1-.15,1.53-.08,1.3.11,1.09.08,1.05.15,1.17.22.83.12.48.26.57.56,1,1.21,1.22,1.2.83.6.3.15.09-.07.17.15.09Zm-.45.48-.11-.13h-.12l-.08.09h.15l.15.13Zm.53,0v-.08h-.11v.08Zm.49-.26h0l-.12.17Zm3.6-5.49v-.13l.08-.3V21.2h0l-.08.22v.26l-.07.35.07.05Z"/>
      <path class="cls-1" d="M181.48,32.91l-.15-.56-.22-.05-.38.18-.22-.09-.15.13-.19-.09-.3-.3-.49-.92-.6-1.39-.53-1.35-.41-.83-.38-.91-.75-1.62L176,23.63l-.79-1.83-.61-1.26-.41-.78-.9-1.66-.64-1.48-.56-1.18-.38-.69-.41-.74v-.09h-.08v4.83l-.08,1.09v5.71l-.15.22.07.26v.35l-.07.26.07.35v.22l-.07.34-.3.13v.22l.11.18.08.13.11-.05h.08v.22l-.23.09-.26.09-.12.3.12.22h0l.08.13v.09l-.08.35v.26l.11-.09.12.09-.08.22-.11.22.07.43v.61h.15l.12.35v.34l-.19.09-.23.13-.34.05-.41.13h-.3l-.23.08-.3.05-.22.08h-.15l-.3.17-.3.27-.27-.22h-.11l-.15.35h-.08l-.15-.08-.11.08h-.86l-.19.08-.19-.08-.23-.18v-.61l.19-.48.27-.13.15-.13V31.6l-.11-.17h-.34l-.19-.48V29.51l-.07-.52v-.74l.07-1-.07-1,.07-1V21.64l.07-1.87-.07-1.75V8.88l.08-1.18.07-.7V5.48l.07-.74.11-1.53V2.3l.19-.09.38-.08.37.08h.9l.19-.08.45.17.15-.13h.64l.3.09h.3l.15-.13h.38l.15-.09h1.08l.23-.13.41.35.6,1,.83,1.44.86,1.61.38.78.41.74.79,1.57.38.74.9,1.7.71,1.52.49,1.18.72,1.35.37.74.45.83h.08V15.15l-.07-.35v-.57l-.12-.35.19-.87-.11-.43v-.57l.07-.57V3.6l.11-1V2.21l.37-.13h1.81l.22.08.23-.08h.34l.3-.13h.34l.52-.09.3.05.3-.05h.68l.23.09h.18V4.43L186,6V7.26l-.07,1.57V9.7l-.07,1.48v1.79l.01,1.3v7.49l-.1,1.25v6.4l-.11,1.35v1.09l-.12.61-.22.09h-.6l-.23.17h-.11l-.19-.09-.15-.09-.11.18-.08.08h0l-.3-.13-.3.35h-.18l-.19-.26h-.19l-.15.26h-.19l-.07-.22h-.08l-.11.21-.07.48-.08.35h-.11Z"/>
      <path class="cls-1" d="M190.51,32.01h-.15l-.23-.18-.22.09v-.13h-.11l-.19.13h-.6l-.19-.09-.15-.17-.11.22-.19.17-.23-.13-.22-.26h-.3l-.19-.13-.11.09-.12.13-.11-.18-.26-.13-.08-.34.19-.79.23-1.31.11-.82.3-1.53.26-1.39.15-.87.15-1,.23-1.31.26-1.39.3-1.7.19-1,.3-1.92.19-1,.3-1.92.19-1.26.22-1,.34-1.57.3-1.48.34-1.48.26-1.31.38-1.22.23-.74.18-.78.19-.53V1.57h.15l.15-.13.38.13.3.09.18-.18.34.09.23.13.19-.09.3.09h.3l.3.08.37-.13.15.05h.15l.16.08.33-.13.27.05h.9l.34-.09.19.09.18-.17.15-.05h.19l.3.83.27.78.33,1.09.38,1.35.3,1.09.19.74.3,1.35.19.83.11.48.26,1,.23,1,.22.83.15.83.27.87.19.82.37,1.62.15.82.34,1.75.26,1.3v.35l.15.7.42,2.05.22,1.13.08.56.07.66.15,1,.19.87v.57l.11.52v.44l-.23.26-.26.13-.34.22-.15.13h-.09l-.27.13-.18.21h-.15l-.38.26h-.11l-.15.13h-.15l-.12.3-.22.17-.23-.13h-.19l-.3.43-.11-.08-.15-.13-.53.26-.52.13-.23-.31-.22-1.09-.3-1.26L199,28.01l-.3-1.35-.56-.08h-.71l-.64-.05-2.11.05-.64-.05-.56.09-.08.26-.11.52-.11.4-.19.95-.15.83-.19.83-.26,1-.26.22h-.27l-.26.22h-.3l-.11.09H191l-.23-.22-.15.3h-.07Zm6.8-11.94-.07-.83-.23-1.17-.11-.57-.08-.35-.22-.87-.15-.87-.19-.78-.19-.87-.11-.4-.08-.08-.07.13-.15.87-.19,1-.19,1.09-.26,1.7-.15.78-.15,1.09.41.09h.79l1.09.09Z"/>
      <path class="cls-1" d="M214.01,31.87h-7.99l-.46-.22-.11-.39V30.25l.12-.3V16.54l.07-.52v-1.7l.08-1.48.11-1.83.07-1.53V6.91l.08-1.22-.08-.74V2.64l.08-.35v-.7l.11-.48h1.13l.19-.05.11.09.11-.09.23.09h.22l.15-.09.23.17h.07l.12-.09h.3l.26-.17h.38l.37-.09h.26l.16-.08.15-.05.15.05.07.08h.64l.48.13.15-.08.08.13V2.71l.08.43V5.63l-.07,1h-.15l-.09.38v2l-.08,1-.07,1.35V14.8l-.07,1.13v3.01l-.35,1.07-.08.83v.87l-.12.21.08.44-.08.65v.53l-.15.39.08.52v.39l.19.31h2.14l1.05.09.72.08h.75l.3.09h.45l.75.08h2.97v1.35l-.15.39.15.09.11.13v.13l-.11.26v.22l-.08.08v.44l-.15.22.15.08.27.09.15.17-.19.14-.19.26.08.21.18.09v.09l-.11.09-.15.17v.35l-.11.13-.08.13v.26l-.19.35-.19.22h-1.05l-.19.22-.3.13-.3-.09v-.13h-.22l-.12.13-.11-.13-.3-.09h-.45l-.15.17h-.11l-.38-.17h-.94l-.22.22h-.61Z"/>
      <path class="cls-1" d="M234.61,32.3l-.3-.13V29.21l.08-.39-.08-.61V22.37l-.08-1.83V18.01l.08-3.4V11.35l-.07-1.26v-1l-.08-.7h-2.33l-1.84.18h-1l-.57.05-.3-.18.08-.22V7.74l.11-.47-.11-.22V6.79l.19-.35-.11-.26.07-.35V4.74l.11-.48V3.91l.07-.35.11-1V2.25l.41-.39.68-.09.68.13h1.28l.41.09.56-.09h.21l.38-.08h3.62l.31.05.75-.05h.79l1.27-.17,1.13-.13h5.32l.45.05.08.52-.11.52.07.39-.07.44.07.3-.11.22-.08.31-.11.13.08.22v.34l.08.18V5.4l-.19.17-.11.22.26.39-.26.35-.12.35v.26l.15.18-.26.26-.23.35h-.79l-.37.22-.38.09-.79-.05-2.82.05-.41.17-.45.31v.82l.07,1.4V23.53l-.07,1v3.7l-.15,1.37v1.96l-.23.18-.48.13-.34.39h-.15l-.12-.35-.18.13-.12.09-.15-.09-.15.31v-.18h-.15l-.08.13-.19.09-.22-.09h-.15l-.15-.13-.15.09h-.72l-.19-.09-.11.09h-.19l-.11.18Z"/>
      <path class="cls-1" d="M260.81,32.48l-.49-1-.86-1.39-.49-.74-1-1.57-.9-1.39-.64-1-.41-.44-.49-.65-1.62-2.22-.37-.48h-.15v2.61l-.08,1.48v1l-.07.53v4l-.27.57-.37.13-.3-.09-.34.13-.12.09-.3-.09-.37.26-.19-.13h-.07l-.19.13h-.34l-.26.13-.23-.09-.23.14-.15-.09-.15.09-.52.08-.42-.08H247.87l-.15-.09V23.55l.15-.54V16.4l.07-1.09V7.56l.07-.7-.07-.34V3.21l-.08-.79v-.5l.08-.09h1.3l.08-.08h.7l.19-.13.34.05.23-.05h1l.19-.13h.67l.23.09h1.65l.46-.13.37.13h.34l1.35.08.38.09.53.09.45.17h.26l.26.21,1,.4.86.61.53.52.38.56.37.88.38,1.17.3,1.09.19.65.11.35.08.7.22,1,.08.48-.08.65.15.61v.17l-.11.48v.57l-.15.3-.15.83-.34,1-.19.48-.68,1.12-.16.3-.52.57-.38.52-.34.31-.52.43-.34.35.19.48.19.39.52.7.9,1.26.91,1.44.67,1.05.57.95.9,1.44.64,1.13.34.79.3.65-.27.26-.3.31-.22.13h-.08l-.07.22-.11.21h-.34l-.19.44h-.29v.13l-.23.13h-.26l-.11.26h-.12l-.23.13-.11.22h-.15l-.19.18-.23.22-.15.08-.11.09h-.15v.13l-.19.09h-.41Zm-6.35-17.64h.71l.94-.09.23-.17.49-.35.6-.57.45-.52.26-.69v-.83l-.15-1.22-.41-1.39-.45-.35-.42-.17-.75-.31-.67-.08h-1.62l-.08.56v6.01l.11.14Z"/>
      <path class="cls-1" d="M268.41,32.01h-.15l-.23-.18-.22.09v-.13h-.11l-.19.13h-.6l-.19-.09-.15-.17-.11.22-.19.17-.23-.13-.22-.26h-.3l-.19-.13-.12.09-.11.13-.11-.18-.26-.13-.08-.34.19-.79.22-1.31.12-.82.3-1.53.26-1.39.15-.87.15-1,.23-1.31.26-1.39.3-1.7.19-1,.3-1.92.19-1,.3-1.92.19-1.26.22-1,.34-1.57.3-1.48.34-1.48.26-1.31.38-1.22.22-.74.19-.78.19-.53V1.57h.15l.15-.13.37.13.31.09.18-.18.34.09.23.13.19-.09.3.09h.3l.3.08.37-.13.15.05h.15l.15.08.34-.13.27.05h.9l.34-.09.18.09.19-.17.15-.05h.19l.3.83.26.78.34,1.09.38,1.35.3,1.09.19.74.3,1.35.19.83.11.48.26,1,.23,1,.22.83.15.83.27.87.18.82.38,1.62.15.82.34,1.75.26,1.3v.35l.15.7.41,2.05.23,1.13.08.56.07.66.15,1,.19.87v.57l.11.52v.44l-.23.26-.26.13-.34.22-.15.13h-.22l-.27.13-.19.21h-.15l-.37.26h-.11l-.15.13h-.16l-.11.3-.22.17-.23-.13h-.19l-.3.43-.11-.08-.15-.13-.53.26-.52.13-.23-.31-.22-1.09-.31-1.26-.25-1.46-.3-1.35-.56-.08h-.71l-.64-.05-2.11.05-.64-.05-.56.09-.08.26-.11.52-.11.4-.19.95-.15.83-.19.83-.26,1-.27.22h-.26l-.26.22h-.3l-.12.09h-.18l-.23-.22-.15.3h-.07Zm6.8-11.94-.07-.83-.27-1.18-.11-.57-.08-.35-.22-.87-.15-.87-.19-.78-.19-.87-.11-.4-.08-.08-.07.13-.15.87-.19,1-.19,1.09-.26,1.7-.15.78-.15,1.09.41.09H273.87l1.09.09Z"/>
      <path class="cls-1" d="M284.69,33.35l-.08-.57-.41-.09h-.19l-.19-.31-.19-.52-.07-.57-.19-.3v-.78l.12-.22v-.21l.15-.35v-.66l-.12-.3V24.85l.12-.43-.08-.87V18.01l-.11-.34v-.4l-.08-.39V14.97l.15-2.27.07-.74V2.16l.19-.35.23-.09.26.09h2.14l.15-.05.15.09.19-.09.19.05h.26l.26-.13.19.13.15-.09.3-.08.34.17h.26l.23-.09.26.09V8.17l-.08.31v.13h.11v.26l-.19.08-.07.05.07.22.08.3.07.26v1.35l-.07.44-.08.22h-.11l-.07.22v.65h.12l.07.09v.57h-.11v.87l.11.09v.17l-.11.17v1.39l.15.14-.07.17v.74l.15.83.07.91v5.93l-.08.74v3.18l-.07,1.13v.44l-.15.61v.43l-.19.26-.26.22h-.23l-.26.17-.07.26h-.15l-.19-.09-.19.13h-.19l-.19.09-.15-.14h-.11l-.11.26-.08.39v-.2l-.11-.44h-.15l-.11.18-.3.34v.18h0l-.08-.18-.18-.13-.15.18-.08.52v.35h0l-.08-.26v-.34l-.07-.13-.15.09-.19.09-.08.26-.11023.69-.08.39h-.07ZM289.27,4.6V4.51l-.07.09v.09Zm.15,6.14v-.17h-.08v.09Z"/>
      <path class="cls-1" d="M307.58,32.91l-.15-.56-.22-.05-.38.18-.23-.09-.15.13-.18-.09-.3-.3-.49-.92-.6-1.39-.53-1.35-.41-.83-.38-.91-.75-1.62-.72-1.48-.79-1.83-.6-1.26-.41-.78-.9-1.66-.64-1.48-.57-1.18-.37-.69-.42-.74v-.09h-.08v4.83l-.07,1.09v5.71l-.15.22.08.26v.35l-.08.26.08.35v.22l-.07.34-.31.13v.22l.11.18.08.13.11-.05h.07v.22l-.22.09-.27.09-.11.3.11.22h0l.08.13v.09l-.07.35v.26l.11-.09.12.09-.08.22-.11.22.07.43v.61h.15l.11.35v.34l-.18.09-.23.13-.34.05-.41.13h-.3l-.23.08-.3.05-.23.08h-.15l-.3.17-.3.27-.26-.22h-.11l-.15.35h-.08l-.15-.08-.11.08h-.87l-.18.08-.19-.08-.23-.18v-.61l.19-.48.26-.13.15-.13V31.6l-.11-.17h-.34l-.19-.48V29.51l-.08-.52v-.74l.08-1-.08-1,.08-1V21.64l.08-1.87-.08-1.75V8.88l.07-1.18.09-.69V5.49l.08-.74.11-1.53V2.31l.19-.09.37-.08.38.08h.9l.19-.08.45.17.15-.13h.64l.3.09h.3l.15-.13h.38l.15-.09h.94l.22-.13.42.35.6,1,.82,1.44.87,1.61.38.78.41.74.79,1.57.37.74.91,1.7.71,1.52.49,1.18.71,1.35.38.74.45.83h.08V15.16l-.07-.35v-.57l-.11-.35.19-.87-.11-.43v-.58l.07-.57V3.6l.12-1V2.21l.38-.13h1.81l.22.08.23-.08h.33l.65-.07h.34l.53-.09.3.05.3-.05h.68l.22.09h.19V4.49l-.07,1.57V7.32l-.07,1.57v.87l-.08,1.48v1.79l-.08,1.26v7.49l-.26,1.23v6.4l-.12,1.35v1.09l-.11.61-.22.09h-.61l-.22.17h-.11l-.19-.09-.15-.09-.12.18-.07.08h0l-.3-.13-.3.35h-.19l-.18-.26h-.19l-.15.26h-.19l-.08-.22h-.08l-.11.21-.08.48-.07.35h-.12Z"/>
      <path class="cls-1" d="M315.67,33.35l-.08-.57-.41-.09h-.19l-.19-.31-.19-.52-.07-.57-.19-.3v-.78l.12-.22v-.21l.15-.35v-.66l-.12-.3V24.85l.12-.43-.08-.87V18.01l-.11-.34v-.4l-.08-.39V14.97l.15-2.27.07-.74V2.16l.19-.35.22-.09.27.09h2.14l.15-.05.15.09.19-.09.19.05h.26l.26-.13.19.13.15-.09.3-.08.34.17h.3l.23-.09.26.09V8.17l-.08.31v.13h.11v.26l-.19.08-.07.05.07.22.08.3.07.26v1.35l-.07.44-.08.22h-.11l-.08.22v.65h.11l.08.09v.57h-.11v.87l.11.09v.17l-.11.17v1.39l.15.14-.08.17v.74l.16.83.07.91v5.93l-.08.74v3.18l-.08,1.13v.44l-.15.61v.43l-.19.26-.26.22h-.23l-.26.17-.08.26h-.15l-.18-.09-.19.13h-.19l-.19.09-.15-.14h-.11l-.11.26-.08.39v-.2l-.11-.44h-.15l-.12.18-.3.34v.18h0l-.08-.18-.18-.13-.15.18-.08.52v.35h0l-.08-.26v-.34l-.07-.13-.16.09-.18.09-.08.26-.10025.69-.08.39h-.07ZM320.25,4.6V4.51l-.07.09v.09Zm.15,6.14v-.17h-.07v.09Z"/>
      <path class="cls-1" d="M338.56,32.91l-.15-.56-.23-.05-.37.18-.23-.09-.15.13-.18-.09-.31-.3-.48-.92-.59-1.39-.52-1.35-.42-.83-.37-.91-.75-1.62-.72-1.48-.79-1.83-.6-1.26-.41-.78-.9-1.66-.64-1.48-.57-1.18-.37-.69-.44-.74v-.09h-.08v4.83l-.07,1.09v5.71l-.15.22.08.26v.35l-.08.26.08.35v.22l-.08.34-.3.13v.22l.11.18.07.13.12-.05h.07v.22l-.22.09-.27.09-.11.3.11.22h0l.08.13v.09l-.07.35v.26l.11-.09.11.09-.07.22-.11.22.07.43v.61h.15l.11.35v.34l-.19.09-.22.13-.34.05-.41.13h-.3l-.23.08-.3.05-.23.08h-.15l-.3.17-.3.27-.26-.22h-.11l-.15.35h-.08l-.15-.08-.11.08h-.87l-.19.08-.18-.08-.23-.18v-.61l.19-.48.26-.13.15-.13V31.6l-.11-.17h-.34l-.19-.48V29.51l-.08-.52v-.74l.08-1-.08-1,.08-1V21.64l.08-1.87-.08-1.75V8.88l.07-1.18.10992-.69V5.49l.08-.74.11-1.53V2.31l.19-.09.37-.08.38.08h.9l.19-.08.45.17.15-.13h.64l.3.09h.3l.15-.13h.38l.15-.09h.94l.22-.13.42.35.6,1,.82,1.44.87,1.61.37.78.42.74.79,1.57.37.74.91,1.7.71,1.52.49,1.18.71,1.35.38.74.45.83h.08V15.16l-.07-.35v-.57l-.11-.35.19-.87-.11-.43v-.58l.07-.57V3.6l.12-1V2.21l.38-.13h1.8l.23.08.23-.08h.33l.65-.07h.34l.53-.09.3.05.3-.05h.68l.22.09h.19V4.49l-.07,1.57V7.32l-.08,1.57v.87l-.08,1.48v1.79l-.08,1.26v7.49l-.25,1.23v6.4l-.12,1.35v1.09l-.11.61-.23.09h-.6l-.22.17h-.12l-.18-.09-.15-.09-.12.18-.07.08h0l-.3-.13-.3.35h-.19l-.19-.26h-.18l-.15.26h-.19l-.08-.22h-.07l-.11.21-.08.48-.07.35h-.12Z"/>
      <path class="cls-1" d="M354.31,34.01l-1-.22h-.38l-.52-.26-.34-.17-.34-.13-.53-.31-.41-.13-.19-.26h-.26l-.11-.13-.36-.39-.68-.74-.45-.57-.26-.13-.15-.26-.41-.61-.31-.7-.15-.39-.15-.31-.37-.52-.26-.87-.27-.65-.11-.39V25.6l-.08-.21-.11-.18-.08-.39-.11-.35-.07-.7-.23-1-.08-.91-.11-.44v-.91l-.3-1.53-.18-1.09.07-.52V15.79l.11-.56v-.79l.15-.52V12.74l-.07-.17.15-.31.07-.3-.11-.13.15-.35v-.65l.15-.44.12-.35.15-.61.07-.48.23-.69.22-1,.27-.35.3-.61.45-1,.18-.48.23-.17.83-1,.56-.52.6-.61.53-.39,1.32-.74.86-.27.15.05.41-.13.53-.13.34-.18.26.05.23-.09h.3l.3-.13h.41l.23-.09h1.2l1.17.13h.26l.3.13.94.22h.26l.49.13.42.18.78.39.72.48.9.7.34.3.07.22-.07.26-.15.39-.26.22-.15.22v.35l-.19.13-.15.13v.3l-.19.26-.22.18v.17h-.23l-.07.31.07.26h-.3l-.11.22-.19.17v.26l-.19.09-.11.17-.11.27-.15.17-.12.17-.18.13-.23.27h-.3l-.49-.39-.52-.39-.49-.27-1.06-.26h-.94l-.22-.09h-.19l-1.13.18-.23.08-.15.22-.71.26-.79.57-.56.61-.57,1.3-.41,1.49-.19.91v.78l-.07.92-.08.83-.07.48.07,1,.08.74v.3l.07,1.27.19,1.09.15.82.15.7.27.91.3,1,.3.74.41,1,.79987.76.67.34.68.31.75.26h.98l.56-.09.38-.17.37-.35.34-.43V24.57l-.07-1.48-.12-1.31v-.87l-.07-.3H358l-2.71.35-1.12.18-.3-.05v-.08l.07-.09v-.09l-.15-.26v-.65l.11-.48V16.87l-.07-.26.07-1,.08-.18-.08-.3.08-.39v-.13l.52-.26,1.09-.27,2-.08h3.96l.23.13.23-.17h.3l.37-.08h.27l.15-.09h.48l.34-.09h.3l.12.35.07,1v6.57l.08,1.4V25.2l-.15.87v.91l-.12.61-.26.18-.23.74-.26.61-.07.13-.15.35-.31.47-.3.44-.6.74-.86.83-.57.39-.18.22-.38.26-.45.3-.19.27H358.87l-.3.08h-.26l-.3.22-.46.09-.3-.09h-.82l-.42-.08-.41.08-.45-.08-.38.13h-.22v.09Z"/>
      <path class="cls-1" d="M376.65,32.65h0l-.08.13h-.45l-.37.17-.27-.09-.11-.08-.15.08-.3.09-.11.09-.3-.09h-.34l-.15-.13-.15-.18v-.78l-.12-.39v-1l-.08-1.48V26.46l-.12-1.7v-.87l.07-.43V15.79l.08-.65V2.51l.11-.31.27-.13h.3l.26-.09.19.09h2.4l.38-.09h.68l.56-.22h4.51l.41.08h1.13l.34.09.3-.13.6.08h1.47l.45-.13.71.09h.53l.23.13.07.3.19.35v.39l.15.44.19.48-.11.26v.78l-.11.31v.35l.07.3-.26.35V6.8l-.26.31-.23.22-.3.13h-.1l.11.21-.08.18-.22-.09-.19.18h-1.16l-.19.08L386.87,8.01h-.26l-.15.17-.11.09h-.19l-.07-.13h-.68l-.26-.13-.23.09h-.56v.09h.08v.08h-1.09l-.22.09-.27-.05h-.37l-.27.13h-1.58l-.75.09-.26.3v4.58h1.65l.27.09h2.6l.41.09h.38l1.05.09.53-.05.6.09v.35l.12.17v.44l.07.26v.52l.11.22v.17l-.07.48v.48l-.08.39.08.22-.08.13-.15.13v.26l.19.14h.22l.08.17-.38.09-.45.31-.15.3h-.15l-.11.18-.11.13.11.09h.19l-.08.17-.34.18-.18.26h-.23l-.19-.05-.22.09h-.3l-.31-.09-.3.09-.41.13h-2.5l-.56.09h-1.51v1.05l-.08.78v3.96l.07.74-.07.22v1.3l-.11.44v.39l-.08-.26v-.39h0l-.08.09v.34l.08.35-.08.44v1.26l.15.09.12-.13h.07v.18l-.07.17.11.26-.38.18v.34l-.19.09-.15.18-.07-.09h-.12l-.11.14-.19.13H377.87l-.11.17-.12-.13-.22.13-.15.13-.3.09-.08.22h0Zm2.29-5.44v-.14l-.07-.06h0v.17l.07.09Zm0-1.49v0Zm.11,1.83h0v-.09l-.12.05.08.08Zm8.82-19.82V7.65h-.12Z"/>
      <path class="cls-1" d="M397.55,34.01l-1-.35-.79-.39-.79-.53-1-.78-.64-.57-.71-.78-.61-.74-.44-.86-.52-.87-.42-.91-.22-.61-.49-1.18-.26-.87-.38-1.83-.19-1.65V18.15l.07-.74V16.8l.11-1.22.12-.7.15-.87.22-.74.19-1.09.19-.74.22-.48.08-.44.11-.48.15-.48.08-.3.19-.35.18-.65.23-.53.15-.47.38-.53.18-.69.46-.53.37-.69.68-1,.79-.83.82-.7.61-.48,1.09-.56,1-.44,1.77-.48.68-.13,2.18-.17h.56l.79.13.79.26.71.35,1.36.83,1.05,1,.9,1.13.94,1.52.57,1.44.45,1.44.22,1.13.12.83.15,1.13V12l.08,1v4.45l-.08,1.17-.11,1-.11.7-.11.13-.19.83-.19,1.09v.43l-.19.35.07.39-.11.31-.15.74-.19,1.18-.37,1.39-.34,1.13-.08.39-.18.26-.27.53-.3.48-.19.43-.3.18-.75.87-1.16,1-.68.34-.83.35-.82.22-.68.13-.64.22h-2.4l-.86.21Zm3.35-6.8.56-.13h.23l.22-.09.15-.17.38-.17.45-.22.15-.18.26-.34.3-.48.34-.53.23-.87.22-.69.08-.44.11-.31v-.47l.15-.48.15-.26v-.53l.07-.3.19-.31-.11-.35.18-.39v-.35l.07-.56V17.8l.11-.61v-.43l.08-1V13.5l-.08-.78V11.67l-.19-.74-.41-1.31-.38-.69-.86-1.09-.72-.48-.86-.26-1.39.22-.6.21h-.42l-.19.09-.37.22-.49.43-.3.27-.08.26-.33.3-.49.83-.3.57-.12.34-.18.53-.08.43-.15.39-.15.75-.15.6-.08.4-.15.61-.11,1-.15,1.53-.08,1.3.12,1.09.07,1.05.15,1.17.23.83.11.48.26.57.57,1,1.2,1.22,1.2.83.61.3.15.09-.08.17.15.09Zm-.46.48-.11-.13h-.15l-.07.09h.15l.15.13Zm.53,0v-.08h-.1v.08Zm.49-.26h0l-.11.17Zm3.61-5.49v-.13l.07-.3V21.2h0l-.07.22v.26l-.08.35.08.05Z"/>
      <path class="cls-1" d="M425.93,32.48l-.48-1-.87-1.39-.49-.74-1-1.57-.9-1.39-.64-1-.41-.44-.49-.65-1.61-2.22-.38-.48h-.15v2.61l-.07,1.48v1l-.08.53v4l-.27.57-.37.13-.3-.09-.34.13-.21005.05-.3-.09-.38.26-.19-.13h-.13l-.19.13h-.34l-.26.13-.21-.1-.22.14-.15-.09-.15.09-.53.08-.41-.08h-.42l-.15-.09V23.55l.07-.57V16.36l.07-1.09V7.56l.07-.7-.07-.34V3.21l-.07-.79v-.5l.08-.09h1.16l.08-.08h.68l.18-.13.34.05.23-.05h1l.19-.13h.68l.22.09h1.66l.45-.13.37.13h.34l1.36.08.37.09.53.09.45.17h.26l.27.21,1,.4.87.61.52.52.38.56.38.88.37,1.17.3,1.09.19.65.11.35.08.7.22,1,.08.48-.08.65.15.61v.17l-.12.48v.57l-.15.3-.15.83-.34,1-.18.48-.64,1.09-.15.3-.53.57-.38.52-.33.31-.53.43-.34.35.19.48.19.39.52.7.91,1.26.9,1.44.67,1.05.57.95.9,1.44.64,1.13.34.79.3.65-.26.26-.3.31-.23.13h-.11l-.08.22-.11.21h-.34l-.19.44h-.22v.13l-.23.13h-.26l-.11.26h-.11l-.23.13-.11.22h-.15l-.19.18-.22.22-.15.08-.12.09h-.08v.13l-.18.09h-.42Zm-6.35-17.64h.72l.94-.09.22-.17.49-.35.6-.57.45-.52.27-.69v-.83l-.15-1.22-.42-1.39-.45-.35-.41-.17-.75-.31-.68-.08h-1.62l-.07.56v6.01l.11.14Z"/>
      <path class="cls-1" d="M443.57,33.17l-.64-.21-.53-.31h-.49l-.48-.26-.79-.44-.49-.31-.3-.26-.49-.35-.34-.3-.34-.22-.26-.3-.38-.35-.3-.39v-.18l-.08-.22v-.21l.12-.18.22-.21.15-.22v-.24l.08-.13.26-.31h.11v-.26l.15-.18v-.17l.07-.13.3-.31.16-.43.18-.09.12-.39.15-.35.18-.13v-.26l.08-.09.11-.26h.3l.19.17.11.13h0l-.08-.09v-.17l.22-.13.75.56.94.74.72.48.67.13.79.05,1-.44.6-.43.53-1,.41-1.43v-.87l-.15-.35-.18-.48-.15-.52-.15-.22-.19-.26-.26-.18-.19-.22-.27-.39-.26-.35-.3-.3-.19-.26-.26-.22-1.17-1.13-.75-.92-.53-.69-1.05-1.44-.75-1.53-.23-.69-.22-1-.08-1.22V8.24l.15-.7.49-1.09.3-.56.27-.44.18-.3.53-.66.49-.56.49-.48.6-.52.75-.57,1-.39.52-.26.83-.31,1.58-.22,1.77.09,1.05.13.38.09.22.13v.17l-.11.05-.08.08.11.18.19.09v.34l-.11.22.15.61h-.15l.08.13v.57l.12.26v.13h-.15v.18l.22.26-.11.13.19009-.01v.44l-.19.08v.09l.11.13.23.13.11.13V6.1h-.11l-.19.09v.7l-.12.17v.48l-.11.57-.11.17-.27-.22-.9-.3-.67-.13h-1.47l-.9.35-.27.26-.3.26-.33.43-.15,1,.45.87.79.87,1,1.14.64.65.6.74L450.87,15.4l1,1.48.94,1.48.3.74.49,1.62.26,1.56.07,1-.06,1.14-.45,1.65-.38.92-.79,1.57-.67.87-.42.39-.26.39-1.09,1.09-.72.52-.79.48-.94.39-.75.27-.6.13-1.35.13Z"/>
      <path class="cls-1" d="M458.61,32.87h-1.32l-.37.14-.27-.14h-.37l-.61.09-.22-.05h-.34l-.19-.13h-.15l-.07-.34.11-.53v-.65l.07-.87V26.77l.08-.26V23.2l.08-1.48V14.31l-.07-1.52V11.13l-.07-1.52V8.78l.07-1.61.08-.79V2.25l.26-.09.15-.17h.53l.52-.05h1.28l.15-.05h1.02l.22-.13h.41l.68-.09h.64l.3-.09h.38l1.09.09,1.8.08.53.05h.45l.53.13h2.51l.15-.05.34.05h1.96l.15.26.15.31.07.43.23.31.23,1.13-.12.52-.07.31v.22l.07.43.15.44.12.21-.23.18-.3.17-.34.57-.45.57-.26.26-.19.13h-.26l-.19-.13h-1.58l-.19.13H461.09V13.3l.15.09h3.63l1.12.08h.42l.33.13h1.02l.6.18h.49l.38.44.07.35V15l.26.35-.15.35.15.3v.74l.15.31-.15.26-.07.35v.61l.15.13.11.13-.11.17h-.15l-.3.27-.26.52.11.26v.13l-.45.13-.26.35h-.64l-2.94-.09-1.24.05-1.65.08-.94.13-.3.22v.26l-.07.92V26.2h1.58l.38.09h1.09l.45.08.94.13h3.53l.49-.09.6.05.6-.05.45.09.15.26.12.35v.35l.23.22v.21l-.15.22.15.18.08.3-.15.09h-.12v.13l.19.18.08.3-.15.44-.08.56-.15.31.27.22.15.13-.08.08-.3.18-.34.26-.11.35v.43l-.75.44h-3.16l-.72.05h-.45l-.3.09h-6.2l-.15.17h0Z"/>
      <path class="cls-1" d="M486.5,32.48l-.49-1-.86-1.39-.49-.74-1-1.57-.9-1.39-.64-1-.41-.44-.49-.65-1.62-2.22-.37-.48h-.15v2.61l-.08,1.48v1l-.07.53v4l-.27.57-.37.13-.3-.09-.34.13-.12.09-.3-.09-.37.26-.19-.13h-.08l-.18.13h-.34l-.26.13-.23-.09-.23.14-.15-.09-.15.09-.52.08-.42-.08h-.41l-.15-.09V23.55l.07-.57V16.36l.07-1.09V7.56l.07-.7-.07-.34V3.21l-.08-.79v-.5l.08-.09h1.16l.08-.08h.67l.19-.13.34.05.22-.05h1l.19-.13h.67l.23.09h1.65l.46-.13.37.13h.34l1.35.08.38.09.53.09.45.17h.26l.26.21,1,.4.86.61.53.52.38.56.37.88.38,1.17.3,1.09.19.65.11.35.08.7.22,1,.08.48-.08.65.15.61v.17l-.11.48v.57l-.15.3-.15.83-.34,1-.19.48-.64,1.09-.15.3-.52.57-.38.52-.34.31-.52.43-.34.35.19.48.19.39.52.7.9,1.26.91,1.44.67,1.05.57.95.9,1.44.64,1.13.34.79.3.65-.27.26-.3.31-.22.13h-.12l-.07.22-.11.21h-.34l-.19.44h-.23v.13l-.23.13h-.26l-.11.26H488.87l-.23.13-.11.22h-.15l-.19.18-.23.22-.15.08-.11.09h-.15v.13l-.19.09-.49.21Zm-6.35-17.64h.71l.94-.09.23-.17.49-.35.6-.57.45-.52.26-.69v-.83l-.15-1.22-.41-1.39-.45-.35-.42-.17-.75-.31-.67-.08h-1.62l-.08.56v6.01l.11.14Z"/>
      <path class="cls-1" d="M498.2,33.3l-.27-.17-.37-1.57-.19-.3-.15-.53-.15-.39-.08-.48-.3-.91-.22-.61-.34-1-.27-.91-.52-2-.53-1.7-.41-1.66-.26-.87-.27-.91-.34-1.09-.3-1-.18-.7-.57-1.74-.6-1.66-.23-.69-.37006-1.4-.72-2.62-.15-.52L490.15,7l-.19-.74-.26-1V4.91l.08-.13-.08-.48V3.81l.27-.21h.22l.08-.18.33-.13.3-.22h.11l.16-.13.3-.13.22-.22.19-.13.3-.09.56-.39.49-.26.3-.22.45-.26.46-.09.26.22.3.65.38,1.31.3,1,.22.83.27,1.09.18.52v.35l.19.61L496.87,8.91l.27.78.56,1.7.56,1.75.27.82.45,1.4.45,1.26.22.87.34,1.18.34,1.13.08.09.11-.26.22-.74.13-.88.23-.91.22-.57.27-1,.3-1.09.19-.79.33-1.21.27-.88.22-.91.19-.78.41-1.27.3-1,.34-1.13.45-1.35.34-1,.34-.91.26-.79.23-.39.34-.65.22-.52.19-.05.19.18.19.08.34.13.45.22.33.18.16.08h.18l.12.13h.22l.19.05v.17l.19.09h.41l.3.21h.45l.19.09.11.13h.41l.23.18.08.35-.16.3-.11.22-.11.26v.13l.19-.22.38-.65V2.99h.08l-.15.52-.49,1.22L510.53,6.01l-.23.61-.11.56-.32.83-.3,1-.23.74-.26.91-.42,1.48-.22.88-.23.82-.45,1.57-.34,1.26-.3,1.09-1,3.36-.49,1.52-.27.74-.49,1.57-.52,1.79-.57,1.7-.22.74-.26.78-.38,1.22-.26.74-.12.35-.15.09h-.07v.13l-.07.21v.4l-.23.13-.37.08h-1l-1,.05-.53-.05-.45.05Zm6.12-7.1h-.07v.17Zm1.62-5.09v-.18l.07-.13v-.04l-.07.13v.12l-.08.13h0Zm1.13-4,.07-.34v-.22l.08-.09h0l-.08.08v.47l-.12.26v.22Zm1-3.7v-.09h0v.09Zm.27-1.13v-.13l.08-.13-.08.08-.07.18h.07Zm1.76-5.75h0v0Z"/>
      <path class="cls-1" d="M513.31,33.35l-.08-.57-.41-.09h-.19l-.19-.31-.18-.52-.08-.57-.19-.3v-.78l.11-.22v-.21l.15-.35v-.66l-.11-.3V24.85l.11-.43-.08-.87V18.01l-.11-.34v-.4l-.08-.39V14.97l.15-2.27.07-.74V2.16l.19-.35.23-.09.26.09h2.14l.15-.05.15.09.19-.09.19.05h.26l.27-.13.18.13.16-.09.3-.08.33.17h.27l.22-.09.27.09V8.17l-.08.31v.13h.11v.26l-.18.08-.08.05.08.22.07.3.08.26v1.35l-.08.44-.07.22h-.12l.17993.22v.65h.11l.07.09v.57h-.12v.87l.12.09v.17l-.12.17v1.39l.15.14-.07.17v.74l.15.83.07.91v5.93l-.07.74v3.18l-.07,1.13v.44l-.15.61v.43l-.18.26-.27.22h-.22l-.27.17-.07.26h-.15l-.19-.09-.19.13h-.19l-.18.09-.15-.14h-.12l-.11.26-.08.39v-.2l-.11-.44h-.15l-.11.18-.3.34v.18h0l-.07-.18-.19-.13-.15.18-.08.52v.35h0l-.08-.26v-.34l-.07-.13-.15.09-.19.09-.08.26-.07.61-.08.39h-.07ZM517.87,4.6V4.51l-.08.09v.09Zm.15,6.14v-.17h-.08v.09Z"/>
      <path class="cls-1" d="M531.69,33.17l-1-.08-.82-.18-.94-.43-1-.27-.68-.34-.78-.48-.6-.38-1.8-1.35-.53-.69-.37-.44-.19-.43-.11-.09-.38-.65-.53-1-.56-1.39-.45-1.44-.26-.83-.15-.26-.12-.52-.19-.79L520.01,20l-.11-1.09-.08-1V15.62l.15-1.22.15-.65.07-.44.12-.43.07-.53.49-1.34.49-1.52.26-.66.22-.48.45-1,.76-1.13.67-.83.76-.82L525,4l.72-.61.64-.52,1.31-.79,1.43-.61,1.24-.43,1.17-.18h2.1l1.5.22.83.17.83.35,1.1.41.48.21.19.22-.07.17-.08.14.12.17-.12.39-.15.35-.11.09.07.13V4.1l.08.08v.09l-.12.17-.3.05-.22-.05.22.22.19.39-.11.13h-.08l-.07.05v.22l-.12.34-.11.18.08.22-.15.21-.08.05.08.09V6.8l-.16.3-.07.31-.11.52-.3.52-.49.26-.53-.17-.37-.22-.72-.17-1.28-.26-.82-.09-1,.09-.6.17-.42.13h-.18l-.27.13-.34.22-.82.57-.6.56-.15.22-.27.26-.41.61-.34.61-.23.52-.26.57-.26.7-.15.52-.19.83-.15.69-.11.26-.08.53-.07.39v1l.15,1.21v.66l.11.52.19,1,.22,1,.38,1.13.71,1,1.06.91,1.24.78.64.31.48.13h.49l.27.09.86.13.3-.09.53-.08h.45l.56-.26.42-.27.33-.26.53-.39.68-.65.67-.61.3-.22h.23v.22l.26.22.11.3.12.26h.34v.22l-.08.21.27.22H538.87l-.15.22-.11.22h.48l-.11.3-.08.35.15.13.38-.26.19.09v.21l-.15.22v.09h0l.15-.13h.19l-.08.17-.11.22v.13h.15l.11-.09.22-.08.08.13-.11.13-.15.22.07.08h.19v.35l.11.09.11.3v.4l-.52.61-.08.13h.11l.11.08-.37.53-.72.69-.6.57-.34.22-.52.43-.83.57-.45.26-.75.22-1.09.26-.94.09-.45-.05-.45.05Zm6.51-2.16h-.15v.09Zm.82-.69.53-.52.26-.31h-.07v.08l-.34.27-.3.3-.23.17v.14Z"/>
      <path class="cls-1" d="M545.12,32.87h-1.31995l-.38.14-.26-.14h-.38l-.6.09-.22-.05h-.34l-.19-.13h-.15l-.07-.34.11-.53v-.65l.07-.87V26.77l.07-.26V23.2l.08-1.48V14.31l-.07-1.52V11.13l-.08-1.52V8.78l.08-1.61.07-.79V2.25l.27-.09.15-.17h.52l.53-.05h1.28l.15-.05h1.01l.23-.13h.41l.68-.09h.64l.3-.09h.38l1.09.09,1.8.08.53.05h.45l.52.13h2.52l.15-.05.34.05h1.96l.15.26.15.31.07.43.23.31.22,1.13-.11.52-.07.31v.22l.07.43.15.44.11.21-.22.18-.3.17-.34.57-.45.57-.26.26-.19.13h-.27l-.18-.13h-1.58l-.19.13h-6.96V13.3l.15.09h3.64l1.13.08h.41l.34.13h1.02l.6.18h.49l.37.44.08.35V15l.26.35-.15.35.15.3v.74l.15.31-.15.26-.07.35v.61l.15.13.11.13-.11.17h-.15l-.3.27-.26.52.11.26v.13l-.45.13-.27.35h-.63l-2.94-.09-1.24.05-1.65.08-.94.13-.3.22v.26l-.07.92V26.2h1.58l.37.09h1.1l.45.08.94.13H554.87l.49-.09.6.05.6-.05.45.09.15.26.12.35v.35l.23.22v.21l-.15.22.15.18.08.3-.15.09h-.12v.13l.19.18.08.3-.15.44-.08.56-.15.31.26.22.15.13-.07.08-.3.18-.34.26-.11.35v.43l-.75.44h-3.16l-.72.05h-.45l-.3.09h-6.04l-.15.17h0Z"/>
      <path class="cls-1" d="M1.38,72.73l-.15-.13-.11.22-.11.13h0L.9,72.77l-.15-.21L.6,72.3v-.31l-.12-.52v-.52l.08-.22-.08-.31V67.94l-.19-.48V67.2l.19-.31v-.78L.4,65.46l-.07-.13h0v.17H.07V62.54L0,62.1l.11-.39-.07-.3.07-.53V56.92l-.08-.31v-.17l.08-.52v-.61l.07-.35v-.48l.08-.44v-.39l-.08-.22v-.21l.11-.26V51.87l.08-.74v-4.1l.07-2.09V41.8l.22-.17H.81l.34-.13.34.09h.38l.15-.22h.34l.15.17.11-.08.12-.18H3.75l.23-.13h.37l.38-.13H5.4l.19.13.19-.09.15.13H9.15l1.54.22.64.17,1.12.44.72.43,1.24,1.14,1,1.3.46.83.63,1.87.34,2,.12,1.87v.83l-.11,1.13-.27,1-.26.66-.56,1.17-.68,1.14-.64.91-.9.92-1.39,1.09-1.22.7-1.06.27-1.09.17-2,.13H6.25l-.07.44v.43l-.12.22.12.35v.43l-.15.44.07.3.08.22-.12.31v1.87l-.07,1v1l-.12,1.09-.12,1.13-.26.35-.15.18-.08-.13-.18.08-.19.22-.19.31-.22.3H4.29l-.15.13H3.99v-.3H3.92l-.05.06-.18.09-.08.3v.13H3.46l-.15-.18v-.21H3.24l-.08.13-.11.13-.19.09-.11.3-.11.22H2.47v-.3H2.32l-.19.3H1.8Zm-1-10.37v-.65l-.07-.52h0v1.17Zm6.49-7.35,1.39-.31.61-.09.71-.3.6-.44.26-.17.38-.53.38-1.09V50.73l-.53-1.52-.9-.65-.45-.18H8.98l-.34-.09H7.55l-1,.13-.07.7-.08.91v.87l-.08,1.22v1.74l-.12.66.08.52h.11Zm7.71,2.78.07-.21-.15.08-.07.22Z"/>
      <path class="cls-1" d="M31.38,72.08l-.49-1-.86-1.4-.49-.74-1-1.57-.88-1.36-.64-1-.41-.44-.49-.65L24.5,61.7l-.37-.48h-.15v2.61l-.11,1.45v1l-.07.53v4l-.27.57-.37.13-.3-.09-.34.14-.11.08-.31-.08-.37.26-.19-.13h-.07l-.19.13h-.41l-.26.13-.23-.08-.23.13L20,71.91l-.15.09-.52.08L18.91,72H18.5l-.15-.09V63.2l.07-.56V55.96l.07-1.09V47.16l.07-.69-.07-.35V42.81l-.08-.78V41.5l.08-.08h1.38l.08-.09h.67l.19-.13h1.57l.19-.13h.68l.22.08h1.65l.46-.13.37.13h.34l1.35.09.38.08.53.09.45.17h.26l.26.22,1,.39.87.61.52.52.38.57.37.87.38,1.17.3,1.09.19.66.11.34.08.7.22,1,.08.48-.08.65.15.61v.18l-.11.48v.56l-.15.31-.15.82-.34,1-.19.48-.63,1.09-.15.31-.53.56-.38.52-.34.31-.52.44-.34.34.19.48.19.39.52.7.9,1.26.81,1.5.67,1,.57,1,.9,1.43.64,1.14.34.78.3.65-.27.26-.3.31-.22.13h-.11l-.08.22-.11.22h-.33l-.19.43h-.21v.14l-.23.13h-.26l-.11.26h-.12l-.23.14-.11.21h-.15l-.19.17-.23.22-.15.09-.11.08h-.15v.13l-.19.09h-.41ZM25.03,54.44l.71-.05.94-.08.23-.18.49-.35.6-.56.45-.53.26-.69v-.83l-.15-1.22-.45-1.31-.45-.35-.41-.17-.76-.3-.62-.09H24.25l-.07.56v6.02l.11.13Z"/>
      <path class="cls-1" d="M43.64,73.56l-1-.35-.79-.39-.79-.52-1-.79-.64-.56-.72-.79-.6-.74-.45-.78-.52-.87-.42-.92-.22-.61L36,65.07l-.26-.88-.38-1.83-.19-1.65V57.75l.07-.74V56.4l.11-1.22.12-.7.15-.87.22-.74.19-1.09.19-.74.22-.48.08-.44.11-.47.15-.48.08-.31.19-.35.18-.65.23-.52.15-.48.38-.53.18-.69.45-.52.38-.7.68-1,.79-.83.82-.69.61-.48,1.09-.57,1-.44,1.88-.4.68-.14,2.18-.17h.56l.79.13.79.27.71.34,1.36.83,1,1,.9,1.13.94,1.61.57,1.44.45,1.44.22,1.12.12.83.15,1.13v.79l.08,1v4.45l-.08,1.17-.11,1-.11.69-.11.13-.19.83-.19,1.09v.44l-.19.34.07.4-.11.3-.15.74-.19,1.18-.37,1.39-.27,1.1-.08.4-.18.26-.27.52-.3.48-.19.43-.3.18-.75.87-1.16,1-.68.35-.83.34-.83.22-.67.13-.64.22H45.97l-.86.22Zm3.35-6.8.56-.13h.22l.23-.08.15-.18.38-.17.45-.22.15-.17.26-.35.3-.48.34-.52.23-.88.22-.69.08-.44.11-.3v-.48l.15-.48.15-.26V60.4l.07-.3.19-.31-.11-.34.18-.4v-.34l.07-.57v-.79l.11-.6v-.44l.08-1V53.04l-.08-.78V51.21l-.19-.74-.41-1.3-.38-.7-.86-1.09-.72-.48-1.05-.26-1.39.22-.6.22h-.42l-.19.08-.37.22-.49.44-.3.26-.08.26-.34.3-.48.83-.3.57-.12.35-.19.52-.07.43-.15.4-.15.74-.15.61-.08.39-.15.61-.11,1-.15,1.53-.06,1.39.12,1,.07,1,.15,1.27.23.82.11.48.26.57.57,1,1.2,1.22,1.2.83.61.3.15.09-.08.17.15.09Zm-.46.48-.11-.13h-.15l-.07.09h.15l.15.13Zm.53,0v-.09h-.11v.09Zm.49-.26h0l-.11.18Zm3.61-5.49v-.13l.07-.31v-.3h0l-.07.21v.27l-.08.34h.08Z"/>
      <path class="cls-1" d="M66.05,72.91l-.27-.18-.37-1.57-.19-.3-.15-.52-.15-.4-.05-.48-.3-.91-.22-.61-.34-1.05-.29-.88-.53-2-.53-1.7-.41-1.65-.26-.88-.27-.91-.33-1.09-.31-1-.18-.7-.57-1.74-.6-1.66-.22-.67-.38-1.44-.71-2.61-.16-.53L58,46.56l-.19-.74-.26-1v-.34l.08-.14-.08-.47v-.48l.27-.22h.22l.08-.18.34-.13.3-.21h.11l.15-.13.3-.13.22-.22.19-.13.3-.09.57-.39.48-.26.31-.22.45-.26.45-.09.26.22.3.65.38,1.41.3,1,.22.83.27,1.09.18.52v.35l.19.61.26,1.13.27.79.56,1.7.56,1.74.27.83.49,1.41.45,1.26.23.87.33,1.18.34,1.13.08.09.11-.26.23-.74.18-.87.23-.92.22-.56.27-1,.3-1.18.19-.78.33-1.22.27-.87.22-.92.19-.78.42-1.27.3-1,.33-1.16.46-1.35.33-1,.34-.91.27-.78.22-.4.34-.65.22-.52h.19l.19.18.19.09.34.13.45.21.34.18.15.09h.18l.12.13h.41v.17l.19.09h.41l.3.22h.45l.19.08.11.13h.42l.22.17.08.35-.15.3-.12.22-.11.26v.13l.19-.21.38-.66v-.13h.08l-.15.53-.49,1.21-.41,1.27-.23.61-.11.56-.3.83-.3,1-.23.74-.26.92-.41,1.48-.23.87-.23.82-.42,1.64-.34,1.27-.3,1.08-1,3.36-.49,1.52-.27.74-.48,1.57-.53,1.79-.53,1.67-.23.74-.26.78-.33,1.24-.26.74-.11.35-.16.09h-.07v.13l-.07.22v.39l-.22.13-.38.09H66.62Zm6.12-7.1H72.1v.17Zm1.62-5.1v-.17l.07-.14v-.04l-.08.13v.13l-.07.13v.05Zm1.13-4,.07-.35v-.22l.08-.09h0l-.08.09v.47l-.11.27v.21Zm1-3.71v.01h0V53Zm.27-1.13v-.13l.08-.13-.08.09-.07.17h.07Zm1.76-5.75h0v0Z"/>
      <path class="cls-1" d="M81.16,72.95l-.07-.57-.42-.08h-.19l-.18-.31-.19-.52-.08-.56-.19-.31v-.79l.11-.21v-.22l.12-.37v-.65l-.11-.31V64.46l.11-.45L80,63.14V57.62l-.13-.35v-.39l-.08-.4V54.57l.15-2.27.08-.74v-9.8l.18-.35.23-.08.26.08h2.3l.15.08.18-.08h.45l.27-.13.19.13.15-.08.3-.09.34.17h.26l.22-.08.27.08v6.36l-.08.31v.13h.12v.26l-.19.09h-.08l.08.22.07.3.08.26v1.35l-.08.44-.07.22H85.5l-.07.22v.65h.11l.07.08v.26l.26.45h-.12v.87l.12.13v.17l-.12.18v1.35l.15.13-.03.17v.74l.15.83.08.91v5.97l-.07.74v3.18l-.16,1.08v.44l-.15.61v.44l-.18.26-.27.21h-.22l-.27.18-.07.26h-.15l-.19-.09-.19.13h-.19l-.18.09-.15-.13h-.12l-.11.26-.07.4V72.3l-.12-.44h-.15l-.11.18-.3.35v.17h0l-.07-.17-.19-.13-.15.17-.07.52v.35h0l-.33-.29v-.35l-.07-.13-.15.09-.19.09-.07.26-.08.61-.07.39h-.08ZM85.75,44.2v-.09l-.08.09v.09Zm.12,6.14v-.17h-.08v.09l.08.09Z"/>
      <path class="cls-1" d="M90.18,72.47v-.26h-.33l-.42-.14h-.75l-.38-.13-.07-.56.07-.53.15-.34v-.22l-.08-.09.08-.48-.12-.52v-.78l.11-.22-.08-.31v-.43l-.09-.45v-.17l-.11-.53V48.9l.12-2.09.15-1.74v-3l.15-.48.61-.13h.94l.41-.13h.33l.07.09h.45l.27-.09H92l.6-.13h.3l.22-.09h.75l.71-.09h.64l2.26.09h.6l1,.13.94.26.86.4.64.43.56.48.68.57.34.39.45.43.68.61.45.53.45.65.56.91.6,1.22.57,1.31.3,1.18.22,1,.23,1.18.19,1.09.07,1,.15,1.09.08,1.3v1.49l-.12.87-.11,1-.19,1-.15.74-.3,1.18-.51,1.46-.33.83-.19.47-.38.66-.3.83-.56.78-.42.57-.22.43-.23.18-.3.3-.22.39-.42.35-.56.44-.23.08-.37.22-.3.22h-.27l-.41.31-.64.17-.86.22-.87.13-.75.09-.38-.05h0l.15-.22.15-.3-.15-.13h-.67l-.15.27.07.26v.13H95.67v-.09h-.03v-.13l.07-.09h.16l.38.09.26-.13h-.9l-.3.09-1-.09h-.26l-.3.09h-.56l-.42-.13-.22.13H91.34l-.22-.13v-.13l-.3.26-.38.13v.61h-.08Zm5.68-6,.71-.08,1.21-.18.94-.43,1-.48.64-.57.38-.48.3-.52.34-.7.49-1.62.45-1.88.11-1.78V56.66l-.07-.48v-1l-.19-1.31-.11-.56-.19-.4-.42-1.22-.94-1.39L98.6,48.47l-.49-.26h-.34l-.34-.13-.94-.13-1.39-.13h-.83l-.11.08v9.89l-.29.22v3.05l-.11.22V63.5l.08.39v.22l-.07.39v.87l.15.74v.35l.07.05.64.08Z"/>
      <path class="cls-1" d="M113.53,72.47h-1.31l-.38.13-.26-.13h-.38l-.6.09h-.57l-.18-.13h-.15l-.08-.35.11-.53V70.9l.08-.87V66.26l.08-.26V62.8l.07-1.48V53.91l-.08-1.52V50.73l-.07-1.52v-.83l.07-1.61.08-.78V41.84l.26-.09.15-.17h3.67l.23-.13h.41l.68-.08h.63l.3-.09h.38l1.09.09,1.81.09h.97l.53.13h4.96l.15.26.15.31.08.43.22.31.23,1.13-.11.52-.08.31v.22l.08.43.15.44.11.22-.23.17-.3.17-.34.57-.45.57-.26.26-.19.13h-.26l-.19-.13h-.41l-.6-.05-.57.05-.19.13h-6.93v4.92l.15.09h3.65l1.13.09h.41l.34.13h1.01l.61.17h.48l.38.44.07.35v.43l.27.35-.15.35.15.3v.75l.15.3-.15.26-.08.35v.61l.15.13.11.13-.11.18h-.15l-.31.26-.26.52.11.26v.13l-.45.13-.26.35h-.64l-2.93-.09h-1.24l-1.66.08-.94.13-.3.22v.26l-.08.92v4.27h1.32l.38.09h1.09l.45.09.94.13.9-.05h2.64l.48-.08h1.21l.45.08.15.26.11.35v.35l.22.22v.22l-.15.21.15.18.07.3-.15.09h-.11v.13l.19.18.07.3-.15.44-.07.57-.15.3.26.22.15.13-.08.09-.3.17-.34.26-.11.35v.44l-.75.43H120.1l-.3.09h-6.01l-.15.17h0Z"/>
      <path class="cls-1" d="M141.43,72.08l-.49-1-.87-1.4-.49-.74-1-1.57-.88-1.36-.63-1-.42-.44-.49-.65-1.61-2.22-.38-.48h-.15v2.61l-.15,1.45v1l-.08.53v4l-.26.57-.38.13-.3-.09-.34.14-.11.08-.3-.08-.38.26-.19-.13h-.07l-.19.13h-.4l-.26.13-.23-.08-.22.13-.15-.09-.15.09-.53.08-.41-.08h-.42l-.15-.09V63.2l.07-.56V55.96l.07-1.09V47.16l.08-.69-.08-.35V42.81l-.07-.78V41.5l.08-.08h1.37l.07-.09h.68l.19-.13h1.56l.19-.13h.68l.22.08h1.66l.45-.13.38.13h.33l1.36.09.37.08.53.09.45.17h.26l.27.22,1,.39.87.61.53.52.37.57.38.87.37,1.17.3,1.09.19.66.11.34.08.7.23,1,.07.48-.07.65.15.61v.18l-.11.48v.56l-.16.31-.15.82-.33,1-.19.48-.64,1.09-.15.31-.53.56-.37.52-.34.31-.53.44-.34.34.19.48.19.39.53.7.9,1.26.9,1.44.68,1,.56,1,.9,1.43.64,1.14.34.78.3.65-.26.26-.3.31-.23.13h-.11l-.08.22-.11.22h-.34l-.19.43h-.22v.14l-.22.13h-.27l-.11.26h-.11l-.23.14-.11.21h-.15l-.19.17-.22.22-.15.09-.12.08h-.15v.13l-.19.09h-.42Zm-6.36-17.64.72-.05.94-.08.22-.18.49-.35.6-.56.45-.53.27-.69v-.83l-.15-1.22-.45-1.31-.45-.35-.41-.17-.75-.3-.68-.09h-1.62l-.07.56v6.02l.12.13Z"/>
      <path class="cls-1" d="M151.54,72.77l-.64-.21-.52-.31h-.49l-.49-.26-.79-.44-.49-.3-.3-.26-.49-.35-.34-.31-.34-.22-.26-.3-.38-.35-.3-.39v-.18l-.07-.21v-.22l.11-.18.22-.21.15-.22v-.22l.08-.13.26-.3.12-.05v-.26l.15-.17v-.18l.08-.13.3-.3.15-.44.19-.09.11-.39.15-.35.19-.13v-.26l.08-.09.11-.26h.3l.19.17.11.13h0l-.07-.09v-.17l.22-.13.76.57.94.74.71.47.68.14h.79l1-.44.61-.43.52-1,.42-1.44v-.87l-.15-.35-.19-.48-.15-.52-.15-.22-.19-.26-.26-.18-.19-.21-.26-.4-.27-.34-.3-.31-.19-.26-.26-.22-1.16-1.13-.76-.92-.52-.69-1.06-1.44-.75-1.52-.22-.7-.23-1.05-.07-1.22V47.72l.15-.7.49-1.08.3-.57.27-.44.19-.3.52-.65.49-.57.49-.48.6-.52.75-.57,1-.39.53-.26.82-.31,1.58-.21,1.77.08,1.05.13.38.09.22.13v.18h-.11l-.08.09.12.17.18.09v.35l-.12.21.15.61h-.15l.08.13v.57l.11.26v.13h-.15v.17l.23.26-.12.13.12.13v.43l-.19.09v.08l.11.13.23.13.11.13v.09h-.11l-.19.08v.7l-.11.17v.48l-.12.57-.11.17-.26-.21-.91-.31-.67-.13h-1.47l-.9.35-.26.26-.3.26-.34.44-.15,1,.45.88.79.87,1,1.13.64.65.6.74,1.16,1.25,1,1.48.94,1.48.3.75.49,1.61.26,1.56.08,1v1.12l-.45,1.65-.38.92-.79,1.57-.67.87-.42.39-.26.39-1.09,1.09-.71.52-.79.48-.94.4-.75.26-.61.13-1.35.13Z"/>
      <path class="cls-1" d="M172.48,71.6h-.15l-.22-.18-.23.09v-.13h-.11l-.19.13h-.6l-.19-.09-.15-.17-.11.22-.19.17-.22-.13-.23-.26h-.3l-.19-.13-.11.09-.11.13-.12-.17-.26-.13-.07-.35.18-.79.23-1.3.11-.83.3-1.53.27-1.39.15-.87.15-1,.22-1.31.26-1.39.31-1.7.18-1,.3-1.92.19-1,.3-1.92.19-1.26.23-1,.33-1.57.24-1.4.33-1.48.27-1.31.37-1.22.23-.74.19-.78.18-.52v-.22h.15l.15-.13.38.13.3.09.19-.17.34.08.22.13.19-.08.3.08h.3l.3.09.38-.13h.3l.15.09.34-.13h1.16l.34-.08.19.08.19-.17h.34l.3.83.26.78.34,1.09.37,1.35.3,1.09.19.74.3,1.35.19.83.11.48.27,1,.22,1,.23.83.15.83.26.87.19.83.37,1.61.16.83.33,1.74.27,1.31v.34l.15.7.42,2.05.22,1.13.08.57.07.65.15,1,.19.87v.57l.11.52v.44l-.22.26-.27.13-.33.22-.15.13h-.23l-.26.13-.19.22h-.15l-.38.26h-.11l-.15.13h-.15l-.11.3-.23.18-.22-.13h-.19l-.3.44-.12-.09-.15-.13-.52.26-.53.13-.22-.31-.23-1.08-.3-1.27-.3-1.43-.3-1.36-.57-.08h-4.09l-.57.09-.07.26-.12.53-.11.39-.19,1-.15.82-.19.83-.26,1-.26.22h-.26l-.27.22h-.3l-.11.09h-.19l-.22-.22-.16.31h-.07Zm6.81-11.94-.08-.82-.22-1.18-.12-.57-.07-.34-.23-.88-.15-.87-.19-.78-.18-.87-.12-.39-.07-.09-.08.13-.15.87-.19,1-.18,1.14-.27,1.7-.15.78-.15,1.09.42.09h.76l1.09.09Z"/>
      <path class="cls-1" d="M203.42,72.51l-.15-.56h-.22l-.38.18-.22-.09-.15.13-.19-.09-.3-.3-.49-.92-.6-1.39-.53-1.35-.41-.83-.38-.91-.75-1.61-.72-1.48-.79-1.83-.6-1.27-.41-.78-.9-1.66-.64-1.48-.57-1.26-.37-.7-.42-.74v-.09h-.08v4.87l-.07,1.09v5.71l-.15.22.08.26v.35l-.08.26.08.35v.22l-.07.35-.3.13v.21l.11.18.08.13h.11l.07-.05h0v.26l-.22.09-.26.09-.12.3.12.22h0l.08.13v.09l-.08.35v.26l.11-.09.12.09-.08.22-.11.22.07.43v.59h.15l.11.35v.35l-.18.08-.23.13h-.34l-.41.13h-.3l-.23.09h-.3l-.22.09-.15-.05-.31.18-.3.26-.26-.22h-.11l-.15.34h-.08l-.15-.08-.11.08h-.87l-.18.08-.19-.08-.23-.18v-.61l.19-.48.26-.13.15-.13v-.13l-.11-.17h-.34l-.19-.48V69.01l-.07-.53v-.74l.07-1-.07-1,.07-1V61.12l.07-1.87-.07-1.74V48.47l.07-1.18.08-.69V45.07l.08-.74.11-1.52v-.93l.19-.09.37-.08.38.08h.9l.19-.08.45.17.15-.13h.64l.3.09h.3l.15-.13h.38l.15-.09h.94l.22-.13.42.35.6,1,.83,1.44.86,1.61.38.78.41.74.79,1.57.38.74.9,1.7.71,1.53.49,1.17.71,1.35.38.74.45.83h.08V54.73l-.07-.35v-.57l-.11-.35.19-.87-.11-.43v-.48l.07-.57V43.27l.11-1v-.39l.37-.13h1.81l.22.08.23-.08h.34l.3-.13h.33l.53-.09h1.28l.22.09h.19V44.1l-.07,1.57v1.26l-.07,1.57v.87l-.08,1.48v1.79l-.08,1.26v7.49l.08,1.27v6.35l-.11,1.35v1.09l-.12.61-.22.09h-.6l-.23.17h-.11l-.19-.09-.23006-.22-.11.18-.08.09v-.05l-.3-.13-.3.35h-.19l-.18-.26h-.19l-.15.26h-.19l-.08-.21h-.08l-.11.22-.08.47-.07.35h-.11Z"/>
      <path class="cls-1" d="M212.3,72.47v-.26h-.34l-.42-.14h-.75l-.37-.13-.08-.56.08-.53.15-.34v-.22l-.08-.09.08-.48-.11-.52v-.78l.11-.22-.07-.31v-.43l-.07-.44v-.17l-.12-.53V48.9l.11-2.09.15-1.74v-3l.15-.48.6-.13h.94l.41-.13h.34l.08.09h.45l.26-.09h.34l.6-.13h.3l.23-.09h.67l.72-.09h.64l2.25.09h.6l1,.13.94.26.86.4.64.43.57.48.67.57.34.39.45.43.68.61.45.53.45.65.56.91.61,1.22.56,1.31.3,1.18.23,1,.22,1.18.19005,1.22.08,1,.15,1.09.07,1.3v1.49l-.11.87-.12,1-.18,1-.16.74-.3,1.18-.43,1.33-.34.83-.19.47-.37.66-.31.83-.56.78-.41.57-.23.43-.22.18-.3.3-.23.39-.41.35-.57.44-.22.08-.38.22-.3.22h-.26l-.42.31-.64.17-.86.22-.86.13-.76.09-.37-.05h0l.15-.22.15-.3-.15-.13h-.68l-.15.27.08.26v.13h-1.24v-.09h-.04v-.13l.08-.09h.15l.37.09.26-.13h-.9l-.3.09-1-.09h-.27l-.3.09h-.56l-.44-.37-.23.13h-1.2l-.23-.13v-.13l-.3.26-.37.13v.61h-.08Zm5.67-6,.72-.08,1.2-.18.94-.43,1-.48.64-.57.37-.48.3-.52.34-.7.49-1.65.45-1.88.11-1.78V56.63l-.08-.48v-1l-.18-1.31-.12-.56-.11-.44-.41-1.22-.94-1.39-1.92-1.83-.49-.26h-.41l-.34-.13-.94-.13-1.39-.13h-.82l-.12.08v9.89l-.08.26v3.08l-.12.22V63.5l.07.39v.22l-.08.39v.87l.15.74v.35l.07.05.64.08Z"/>
      <path class="cls-1" d="M250.76,72.77l-1-.08-.79-.18-.94-.43-1-.26-.67-.35-.79-.48-.6-.39-1.81-1.35-.52-.7-.38-.44-.19-.43-.11-.09-.38-.65-.52-1.05-.57-1.39-.45-1.44-.26-.83-.15-.26-.11-.52-.19-.78-.23-1.14-.11-1.09-.08-1V55.22l.15-1.22.15-.65.08-.44.11-.43.07-.53.38-1.35.49-1.52.26-.65.23-.48.45-1,.75-1.13.68-.83.75-.82.53-.57.71-.61.64-.52,1.31-.79,1.43-.61,1.24-.43,1.17-.18h2.1l1.51.22.82.18.83.35,1.13.39.49.21.19.22-.08.18-.07.13.11.17-.11.39-.15.35-.12.09.08.13v.22l.07.09v.08l-.11.18h-.53l.23.21.19.4-.12.13h-.15V45l-.11.35-.12.17.08.22-.15.22h-.08l.08.09v.26l-.15.3-.08.31-.11.52-.3.52-.49.26-.53-.17-.37-.22-.72-.17-1.27-.26-.83-.09-1,.09-.6.17-.41.13h-.19l-.26.13-.34.22-.83.57-.6.56-.15.22-.26.26-.42.61-.34.61-.22.52-.27.57-.26.7-.15.52-.19.83-.15.7-.11.26-.08.52-.07.39v1l.15,1.22v.65l.11.52.19,1,.23,1,.37,1.13.72,1,1.05.91,1.24.79.64.3.49.13h.49l.26.09.86.13.3-.08.53-.09h.45l.57-.26.41-.26.34-.27.52-.39.68-.65.68-.61.3-.22h.22v.22l.26.22.12.3.11.26h.34v.22l-.07.22.26.21h.22l-.15.21-.11.22H258.09l-.11.3-.07.35.15.13.37-.26.19.09v.22l-.15.21v.09h0l.15-.13h.25l-.08.17-.11.22v.13h.19l.11-.08.23-.09.07.13-.11.13-.15.22.07.09h.19v.34l.11.09.12.31v.39l-.53.61-.08.13h.11l.12.09-.38.52-.71.69-.6.57-.34.22-.53.43-.83.57-.45.26-.75.22-1.09.26-.94.09h-.9Zm6.5-2.17h-.15v.08Zm.83-.7.52-.52.27-.31h-.08v.09l-.34.26-.3.3-.22.18v.13Z"/>
      <path class="cls-1" d="M267.22,73.56l-1-.35-.79-.39-.79-.52-1-.79-.64-.56-.71-.79-.6-.74-.45-.78-.53-.87-.41-.92-.23-.61-.49-1.17-.26-.88-.38-1.83-.18-1.65V57.75l.11005-.74V56.4l.11-1.22.11-.7.15-.87.23-.74.19-1.09.21-.77.23-.48.07-.44.12-.47.15-.48.07-.31.19-.35.19-.65.22-.52.15-.48.38-.53.19-.69.45-.52.38-.7.67-1,.79-.83.83-.69.6-.48,1.09-.57,1-.44,1.76-.47.68-.14,2.18-.17h.57l.78.13.79.27.72.34,1.35.83,1,1,.91,1.13.98993,1.71.56,1.44.45,1.44.23,1.13.11.83.15,1.13v.79l.07,1v4.45l-.07,1.09-.11,1-.12.69-.11.13-.19.83-.19,1.09v.44l-.19.34.08.4-.11.3-.15.74-.19,1.18-.38,1.39-.26,1.17-.07.4-.19.26-.26.52-.3.48-.19.43-.3.18-.75.87-1.17,1-.68.35-.82.34-.83.22-.68.13-.64.22h-2.52l-.86.22Zm3.35-6.8.56-.13h.23l.22-.08.16-.18.37-.17.45-.22.15-.17.27-.35.3-.48.33-.52.23-.88.23-.69.07-.44.11-.3v-.48l.15-.48.15-.26V60.4l.07-.3.19-.31-.11-.34.17-.44v-.34l.08-.57v-.79l.12-.6v-.44l.07-1V53l-.07-.78V51.17l-.19-.74-.41-1.3-.37-.7-.87-1.09-.71-.48-.87-.26-1.39.22-.6.22h-.41l-.19.08-.38.22-.49.44-.3.26-.07.26-.34.3-.49.83-.3.57-.11.35-.19.52-.08.43-.15.4-.15.74-.15.61-.07.39-.15.61-.12,1-.15,1.53-.07,1.3.11,1.09.08,1,.15,1.18.22.82.12.48.26.57.56,1,1.07,1.35,1.2.83.6.3.15.09-.07.17.15.09Zm-.45.48-.11-.13h-.14l-.08.09h.15l.15.13Zm.53,0v-.09h-.11v.09Zm.48-.26h0l-.12.18Zm3.61-5.49v-.13l.08-.31v-.3h0l-.08.21v.27l-.07.34h.07Z"/>
      <path class="cls-1" d="M283.17,72.64l-.16.05-.45-.57V70.77l.15-.26V61.67l.07-1.09V52.22l.08-1.75V48.38l.07-1.7.19-1.74V42.93l.08-.78.26-.48h.57l.86-.09.41-.08h.98l.15-.13.19-.13.15.09.19.09.19-.13h.3l.33-.18.08.13.08-.08.15-.09.15.13h.45l.37.09.08.13v.3l.45,1,.34.79.34,1,.18.53.23.52.3.83.23.65v.26l.15.22.41,1,.34,1,.07.31.19.39.26.65.27.74.15.39.22.48.3,1,.12.3.07.31h.15l.12-.26.07-.35.11-.26v.31l-.19.61-.15.34h.19l.11-.26.19-.35.11-.56.23-.57.19-.39.11-.44.26-.52.08-.39.07-.22.15-.22.12-.47.19-.61.15-.27.11-.69.22-.79.15-.26.19-.52.19-.74.19-.39.07-.31.19-.35.41-1,.53-1.52.19-.61v-.35l.11-.09h.38l.33-.09h1.43l.57-.09.33.09h.15l.19-.26.27.09.11.08.11-.08.26-.13h.79l.23.08.15.22h.08v-.26h.08v1.17l.07.79v5.92l-.07.87v.92l-.07,1.87v13.5l-.07,1.7v3.62l-.15.09-.11-.18h0v.44l-.3.26-.34-.09h-.41l-.19.22-.15-.09h-.34l-.11.13v-.13l-.07-.13h-.12v.22h-.15l-.12-.18-.18-.17h-.12v.22l-.08.31h-.53l-1,.09-.3-.18h-.27l-.11-.43-.08-.44V56.14l-.08-.35-.34.65-.3.83-.34.87-.45,1.09-.22.52-.27.65-.3.74-.07.31-.15.17v.22l-.11.09-.08.26-.34.22-.18.09-.12-.09.12-.09h0l-.27-.09h-.52l-.34.09-.11.13-.46.22h-1.2l-.56.09h-.42l-.18-.26.07-.57-.26-.74-.19-.35-.19-.57-.52-1.3-.31-.7-.22-.57-.15-.35-.08-.39-.22-.56-.19-.44h0v.48l-.12.09v-.18l-.12-.34-.11-.3h-.19v.65l-.07,1.18v6.34l-.08,1.22v4.31l-.12,1v1l-.08.22H288l-.15.35-.38.18-.6-.15-.53.35-.22-.17-.12.13-.15.13h-.33l-.3-.08-.19.17v.13h-.68l-.41.13-.3.08h-.26Zm9.24-10,.19-.05h-.34v.09Zm11.88-1.27V61.2h-.07v.17Zm0,4.1v-.31h-.08v.26Zm0,2.7V66.08h-.07v2.06Zm.07-9.06v-.52h0l-.07.26.07.3Zm0,4.88v-.44h-.08l-.07.09v.39Zm.23-17-.08-.3v.78Z"/>
      <path class="cls-1" d="M307.38,72.64l-.15.05-.45-.57V70.77l.15-.26V61.67l.07-1.09V52.22l.07-1.75V48.38l.07-1.7.19-1.74V42.93l.07-.78.26-.48h.57l.86-.09.42-.08h.97l.15-.13.19-.13.15.09.19.09.19-.13h.3l.34-.18.07.13.08-.08.15-.09.15.13h.45l.37.09.08.13v.3l.45,1,.34.79.34,1,.19.53.22.52.3.83.23.65v.26l.15.22.41,1,.34,1,.08.31.18.39.27.65.26.74.15.39.23.48.3,1,.11.3.07.31h.15l.12-.26.07-.35.12-.26v.31l-.19.61-.15.34h.19l.12-.26.18-.35.12-.56.22-.57.19-.39.11-.44.27-.52.07-.39.08-.22.15-.22.11-.47.19-.61.15-.27.11-.69.23-.79.15-.26.18-.52.19-.74.19-.39.08-.31.18-.35.42-1,.91016-1.35.19-.61v-.35l.11-.09h.38l.34-.09h1.42l.57-.09.34.09h.15l.18-.26.27.09.11.08.11-.08.27-.13h.79l.22.08.15.22h.08v-.26h.08v1.17l.07.79v5.92l.17014.72v.92l-.07,1.87V66.3l-.07,1.7v3.62l-.15.09-.11-.18h0v.44l-.3.26-.34-.09h-.41l-.19.22-.15-.09h-.34l-.11.13v-.13l-.07-.13h-.11v.22h-.15l-.12-.18-.18-.17h-.12v.22l-.08.31h-.52l-1,.09-.3-.18h-.27l-.11-.43-.07-.44V56.14l-.08-.35-.34.65-.3.83-.33.87-.46,1.09-.22.52-.26.65-.31.74-.07.31-.15.17v.22l-.11.09-.08.26-.33.22-.19.09-.12-.09.12-.09h0l-.27-.09h-.52l-.34.09-.11.13-.45.22h-1.21l-.56.09h-.42l-.18-.26.07-.57-.26-.74-.19-.35-.19-.57-.52-1.3-.3-.7-.23-.57-.15-.35-.07-.39-.23-.56-.19-.44h0v.48l-.12.09v-.18l-.08-.39-.11-.3h-.19v.65l-.07,1.18v6.39l-.08,1.22v4.31l-.11,1v1l-.08.22h-.22l-.15.35-.38.18-.3-.18-.53.35-.22-.17-.11.13-.15.13H309.68l-.3-.08-.19.17v.13h-.68l-.41.13-.3.08h-.26Zm9.24-10,.19-.05h-.34v.09Zm11.88-1.27V61.2h-.07v.17Zm0,4.1v-.31h-.08v.26Zm0,2.7V66.08h-.07v2.06Zm.07-9.06v-.52h0l-.07.26.07.3Zm0,4.88v-.44h-.08l-.07.09v.39Zm.23-17-.08-.3v.78Z"/>
      <path class="cls-1" d="M339.44,73.17h-2.1l-.26-.18-.76-.22-.82-.43-.79-.61-1-.65-.64-.66-.33-.43-.38-.53-.38-.87-.33-.69-.19-.7-.3-1.18-.12-1.43V61.93l-.08-.57V57.4l.07-1V53.91l.07-1.69V47.9l.07-.78.12-1.61V41.64l.22-.26.34-.09.15.09h.26l.23.09.19-.13.37.09.16.13h.07l.19-.17.22.09h.34l.38.09.22-.17h.15l.15.08h1.06l.3.09H337l.08.56v8.41l.07,1.09v5.38l-.07.65L337,59.19l-.07,2-.08,1.52-.07.7.07.57.15.78.49.57.72.65,1.05.3.71.14.68.17h.64l.64-.4.6-.48.3-.13.3-.43.38-.92.26-1,.08-.83.07-.91.08-.44-.08-.22v-1l.08-1.21V44.16l-.11-.43.07-.44-.11-.43.08-.48-.08-.35v-.31l.08-.17h.19l.26-.13h.34l.37.09.27-.13h.34l.18.08.23-.13.19.13h.56l.56-.09.31.09.52-.09.9.13.3-.08.23.13v.91l.19,1.55v4.62l-.08,3v3.31l.08,1.48v4.27l-.08.52V62l-.07,1.57-.19,1.13-.08.7-.26.65-.38.91-.56,1.22-.72.92-.45.65-.48.61-.72.52-.49.31-.26.3-.26.22-.76.39-.6.27-.75.34-.49.22-.56.13-.34.09-.19.13h-.26Z"/>
      <path class="cls-1" d="M367.87,72.51l-.15-.56h-.23l-.37.18-.25-.12-.15.13-.19-.09-.3-.3-.49-.92-.6-1.39-.52-1.35-.42-.83-.37-.91-.76-1.61-.71-1.48-.79-1.83-.6-1.27-.41-.78-.91-1.66-.64-1.48-.58-1.23-.38-.7-.41-.74v-.09h-.07v4.87l-.07,1.09v5.71l-.15.22.08.26v.35l-.08.26.08.35v.22l-.08.35-.3.13v.21l.12.18.07.13h.11l.08-.05h0v.26l-.23.09-.26.09-.11.3.11.22h0l.07.13v.09l-.07.35v.26l.11-.09.11.09-.07.22-.12.22.08.43v.59h.15l.11.35v.35l-.19.08-.22.13h-.34l-.42.13h-.3l-.22.09h-.3l-.23.09-.15-.05-.3.18-.3.26-.26-.22h-.12l-.15.34h-.07l-.15-.08-.11.08h-.87l-.19.08-.18-.08-.23-.18v-.61l.19-.48.26-.13.15-.13v-.13l-.11-.17h-.34l-.19-.48V69.01l-.08-.53v-.74l.08-1-.08-1,.08-1V61.12l.08-1.87L351.87,57.49V48.47l.08-1.18.08-.69V45.07l.08-.74.11-1.52v-.93l.18-.09.38-.08.38.08h.9l.19-.08.45.17.15-.13h.64l.3.09h.3l.15-.13h.37l.15-.09h.94l.23-.13.41.35.61,1,.82,1.44.87,1.61.37.78.42.74.79,1.57.37.74.9,1.7.72,1.53.49,1.17.71,1.35.38.74.45.83h.07V54.73l-.08-.35v-.57l-.11-.35.19-.87-.12-.43v-.48l.08-.57V43.27l.11-1v-.39l.38-.13h1.8l.23.08.22-.08h.34l.3-.13h.34l.53-.09h1.27l.23.09h.19V44.1l-.07,1.57v1.26l-.08,1.57v.87l-.08,1.48v1.79l-.07,1.26v7.49l.07,1.27v6.35l-.11,1.35v1.09l-.11.61-.23.09h-.6l-.22.17h-.12l-.18-.09-.16-.09-.11.18-.07.09v-.05l-.3-.13-.3.35h-.19l-.19-.26h-.19l-.15.26h-.18l-.08-.21h-.07l-.12.22-.07.47-.08.35h-.11Z"/>
      <path class="cls-1" d="M375.87,72.95l-.07-.57-.42-.08h-.18l-.19-.31-.19-.52-.07-.56-.19-.31v-.79l.11-.21v-.22l.2-.37v-.65l-.11-.31V64.46l.11-.45-.07-.87V57.62l-.11-.35v-.39l-.07-.4V54.57l.15-2.27.08-.74v-9.8l.19-.35.22-.08.27.08h2.29l.15.08.19-.08h.45l.26-.13.19.13.15-.08.3-.09.34.17h.26l.23-.08.26.08v6.36l-.07.31v.13H380.87v.26l-.19.09h-.08l.08.22.08.3.07.26v1.35l-.07.44-.08.22h-.11l-.08.22v.65h.11l.08.08v.57h-.11v.87l.11.09V54l-.11.18v1.35l.15.13-.08.17v.74l.15.83.08.91v5.97l-.08.74V68.2l-.08,1.13v.44l-.15.61v.44l-.19.26-.26.21h-.23l-.26.18-.08.26h-.15l-.19-.09-.18.13h-.19l-.19.09-.15-.13h-.11l-.12.26-.07.4v-.22l-.11-.44h-.15l-.12.18-.3.35v.17h0l-.08-.17-.19-.13-.15.17-.07.52v.35h0l-.07-.26v-.35l-.08-.13-.15.09-.19.09-.07.26-.08.61-.07.39H375.87Zm4.59-28.75v-.09l-.08.09v.09Zm.15,6.14v-.17h-.07v.09l.07.09Z"/>
      <path class="cls-1" d="M388.43,71.9l-.3-.13V68.81l.07-.39-.07-.61v-5.8l-.26-1.87V57.57l.07-3.39V50.95l-.07-1.26V48.64l-.07-.69h-2.34l-1.84.17h-1.56l-.3-.18.07-.21v-.48l.11-.48-.11-.22v-.26l.04-.28-.11-.26.07-.35V44.31l.12-.48v-.35l.07-.34.12-1.05v-.31l.42-.39.67-.08.68.13h1.28l.41.08.57-.08h.49l.37-.09h5.49l1.28-.18,1.13-.13h5.78l.07.52-.11.52.08.4-.08.43.08.31-.12.21-.07.31-.12.13.08.22v.35l.07.17v.26l-.19.18-.11.21.26.4-.26.34-.11.35v.26l.15.18-.26.26-.22.35h-.3l-.49.05-.38.21-.38.09h-3.6l-.42.17-.45.31v.83l.08,1.39V63.04l-.08,1v3.97l-.08,1.22v1.96l-.22.18-.49.13-.34.39h-.15l-.11-.35-.19.13-.11.09-.15-.09-.15.31V71.8h-.15l-.07.13-.19.09-.23-.09h-.15l-.15-.13-.15.09h-.71l-.19-.09-.11.09h-.19l-.11.18Z"/>
      <path class="cls-1" d="M408.1,72.91l-.19-.31V67.42l.08-.35-.19-.92V64.59l.07-1.44V62.01l-.08-1.26v-.79l.07-1v-.7l-.67-1.25-.52-.92-.34-.48-1.21-2.09-.33-.56-.19-.22-.34-.57L403.55,51.01l-.68-1.1-.53-1-.47-.9-.3-.56-.45-.83-.46-.87-.22-.48-.3-.7v-.52l.26-.22.23-.13.26-.17h.27l.11-.22.37-.21.23-.09.15-.17h.31l.07-.08h.19l.22-.08.12-.18.19-.09.15-.08.11-.09.19-.17.11.08.11-.17.27-.26.37-.09.34-.26.3-.09.38.65.52,1.14.57,1,.67,1.26.57,1,.45.74,1.46,2.36.19.52.45.78.38.79.07.08h.08l.23-.61.37-1,.19-.57.26-.48.38-.82.15-.57.15-.39.07-.31.23-.13.19-.35v-.39l.34-.87.45-1.09.23-.39.26-.7.3-.74v-.22l.15-.26.08-.34.15-.22.15-.13.26.08h.34l.34.17.34.09.37.17.26.22.16-.09.22.13h.26v.22l.19.08.27-.08.07.13h.23l.18.08h.19l.12.13h.19l.45.13.52.14.3.13v.08l-.07.26-.49,1-.53,1.31-.41.91-.45,1.09-1,2.27-.53,1.34-.6,1.52-.5,1.34-.72,1.7-.37.91-.56,1.53-.26.74-.08.13-.22.48-.08.74v2.22l-.12,1.61v1.14l-.08.69v6.9l-.26.13h-.26l-.15.09-.38-.09-.38.13-.41-.09-.11.18h-.19l-.15-.09-.11-.08-.15.08h-.12l-.18-.09-.15.13h-.61l-.15-.13h-.11l-.3.13h-.75Zm-.23-6.36V66.5h0Z"/>
      <path class="cls-1" d="M429.04,72.64l-.15.05-.46-.57V70.77l.15-.26V61.67l.07-1.09V52.22l.07-1.75V48.38l.07-1.7.19-1.74V42.93l.08-.78.26-.48h.57l.86-.09.42-.08h.97l.15-.13.19-.13.15.09.19.09.19-.13h.3l.34-.18.07.13.08-.08.15-.09.15.13h.45l.37.09.08.13v.3l.45,1,.34.79.34,1,.19.53.22.52.3.83.23.65v.26l.15.22.41,1,.34,1,.07.31.19.39.27.65.26.74.15.39.23.48.3,1,.11.3.07.31h.15l.12-.26.07-.35.11-.26v.31l-.19.61-.15.34h.19l.11-.26.19-.35.12-.56.22-.57.19-.39.11-.44.26-.52.08-.39.08-.22.15-.22.11-.47.19-.61.15-.27.11-.69.23-.79.15-.26.18-.52.19-.74.19-.39.07-.31.19-.35.42-1,.52-1.52.19-.61v-.35l.11-.09h.38l.33-.09h1.43l.57-.09.34.09h.15l.18-.26.27.09.11.08.11-.08.27-.13h.79l.22.08.15.22h.08v-.26h.08v1.17l.07.79v5.92l-.07.87v.92l-.07,1.87v13.5l-.07,1.7v3.62l-.15.09-.11-.18h0v.44l-.3.26-.34-.09h-.41l-.19.22-.15-.09h-.34l-.11.13v-.13l-.07-.13h-.11v.22h-.15l-.12-.18-.18-.17h-.12v.22l-.08.31h-.53l-1,.09-.3-.18h-.27l-.11-.43-.07-.44V56.14l-.08-.35-.34.65-.3.83-.34.87-.45,1.09-.22.52-.26.65-.31.74-.07.31-.15.17v.22l-.11.09-.08.26-.34.22-.18.09-.12-.09.12-.09h0l-.27-.09h-.52l-.34.09-.11.13-.45.22h-1.21l-.56.09h-.42l-.18-.26.07-.57-.26-.74-.19-.35-.19-.57-.52-1.3-.3-.7-.23-.57-.15-.35-.08-.39-.22-.56-.19-.44h0v.48l-.12.09v-.18l-.08-.39-.11-.3h-.19v.65l-.07,1.18v6.39l-.08,1.22v4.31l-.11,1v1l-.08.22h-.22l-.15.35-.38.18-.3-.18-.53.35-.22-.17-.11.13-.15.13h-.34l-.3-.08-.19.17v.13h-.68l-.41.13-.3.08h-.26Zm9.24-10,.19-.05h-.34v.09Zm11.88-1.27V61.2h-.07v.17Zm0,4.1v-.31h-.08v.26Zm0,2.7V66.08h-.07v2.06Zm.07-9.06v-.52h0l-.07.26.07.3Zm0,4.88v-.44h-.08l-.07.09v.39Zm.23-17-.08-.3v.78Z"/>
      <path class="cls-1" d="M456.48,72.47h-1.31l-.38.13-.26-.13h-.38l-.6.09h-.57l-.18-.13h-.15l-.08-.35.11-.53V70.9l.08-.87V66.26l.11005-.25V62.8l.07-1.48V53.91l-.08-1.52V50.73l.01-1.52v-.83l.07-1.61.08-.78V41.84l.26-.09.15-.17h3.5l.23-.13h.41l.68-.08h.63l.31-.09h.37l1.09.09,1.81.09h.97l.53.13h4.96l.15.26.15.31.08.43.22.31.23,1.13-.11.52-.08.31v.22l.08.43.15.44.11.22-.23.17-.3.17-.34.57-.45.57-.26.26-.19.13h-.26l-.19-.13h-.41l-.6-.05-.57.05-.19.13h-6.95v4.92l.15.09h3.65l1.11008-.04h.41l.34.13h1.01l.6.17h.49l.38.44.08.35v.43l.27.35-.15.35.15.3v.75l.15.3-.15.26-.08.35v.61l.15.13.11.13-.11.18h-.15l-.31.26-.26.52.11.26v.13l-.45.13-.26.35h-.64l-2.93-.09h-1.24l-1.66.08-.94.13-.3.22v.26l-.08.92v4.27H459.87l.38.09h1.09l.45.09.94.13.9-.05h2.63l.49-.08h1.21l.45.08.15.26.11.35v.35l.22.22v.22l-.15.21.15.18.07.3-.15.09h-.11v.13l.19.18.07.3-.15.44-.07.57-.15.3.26.22.15.13-.08.09-.3.17-.33.26-.12.35v.44l-.75.43h-4.32l-.3.09h-6.06l-.15.17h0Z"/>
      <path class="cls-1" d="M471.93,72.64l-.15.05-.45-.57V70.77l.15-.26V61.67l.08-1.09V52.22l.08-1.75V48.38l.08-1.7.19-1.74V42.93l.08-.78.26-.48h.56l.87-.09.41-.08h.98l.15-.13.19-.13.15.09.19.09.18-.13h.3l.34-.18.08.13.07-.08.15-.09.15.13h.8501l.37.09.08.13v.3l.45,1,.34.79.33,1,.19.53.23.52.3.83.22.65v.26l.15.22.42,1,.34,1,.07.31.19.39.26.65.27.74.15.39.22.48.3,1,.12.3.07.31h.15l.11-.26.08-.35.11-.26v.31l-.19.61-.15.34h.19l.11-.26.19-.35.11-.56.23-.57.19-.39.11-.44.26-.52.08-.39.07-.22.15-.22.12-.47.18-.61.15-.27.12-.69.22-.79.15-.26.19-.52.19-.74.19-.39.07-.31.19-.35.41-1,.53-1.52.19-.61v-.35l.12-.09h.37l.34-.09h1.43l.56-.09.34.09h.15l.19-.26.26.09.12.08.11-.08.26-.13h.79l.23.08.15.22h.07v-.26h.08v1.17l.08.79v5.92l-.07.87v.92l-.08,1.87v13.5l.03009,1.73v3.62l-.15.09-.11-.18h0v.44l-.3.26-.34-.09h-.41l-.19.22-.15-.09h-.34l-.11.13v-.13l-.07-.13h-.12v.22h-.15l-.11-.18-.19-.17h-.11v.22l-.07.31h-.53l-1,.09-.3-.18H487.87l-.11-.43-.08-.44V56.14l-.07-.35-.34.65-.3.83-.34.87-.45,1.09-.22.52-.27.65-.3.74-.07.31-.15.17v.22l-.11.09-.08.26-.34.22-.19.09-.11-.09.11-.09h0l-.26-.09h-.53l-.33.09-.12.13-.45.22h-1.2l-.56.09h-.42l-.19-.26.08-.57-.26-.74-.19-.35-.19-.57-.53-1.3-.3-.7-.22-.57-.15-.35-.08-.39-.22-.56-.19-.44h0v.48l-.11.09v-.18l-.07-.39-.12-.3h-.18v.65l-.08,1.18v6.39l-.07,1.22v4.31l-.12,1v1l-.07.22h-.23l-.15.35-.37.18-.3-.18-.53.35-.22-.17-.12.13-.15.13h-.34l-.3-.08-.18.17v.13h-.68l-.41.13-.3.08h-.27Zm9.25-10,.19-.05h-.34v.09Zm11.88-1.27V61.2h-.07v.17Zm0,4.1v-.31h-.07v.26Zm0,2.7V66.08h-.07v2.06Zm.08-9.06v-.52h0l-.07.26.07.3Zm0,4.88v-.44h-.08l-.07.09v.39Zm.22-17-.07-.3v.78Z"/>
      <path class="cls-1" d="M499,72.51h-.49l-.41-.13-.75-.08-.6-.13-.42-.27-.49-.17-.07-.17-.11-.09v-.26l-.11-.44.07-.74V65.41l-.07-1.78V60.06l.07-1.79.12-1.61.07-.87.08-1.09V53.52l.08-1V51.26l.07-1.09V47.08l.08-1.53-.08-1.65.08-1.39v-.88l.26-.17h1.51l.37-.08h3.35l.26-.13.42-.08.71-.09h1.8l1.36.18,1.5.56,1.05.83.6.57.57.74.68,1,.45,1.35.3,1.26v.74l.11.83v1.18l-.15,1.17-.38,1.18-.26,1-.34.56.19.48.48.66.23.43.11.44.64,1.17.6,1.66.38,1.35.07.78.12.74v1.44l-.08,1.35-.07.61-.12.39-.07.57-.26.61-.27.65-.56,1-.45.88-.08.08h-.11l-.26.26-.34.26-.19.35-.41.44-.34.39h-.27l-.18.09-.38.22-.34.13-.56.26-.49.26-.08-.08v-.18l-.6.13-1,.18-1.28.17h-4.22l-.78.13Zm4.33-6.44h.18l.27-.26.45-.13h.37l.49-.17,1-.31.56-.61.22-.58.37-1.09.11-1.18v-.61l-.07-.22-.19-.65-.34-.44-.22-.26-.49-.3-.38-.18-.49-.08-.6-.22-1-.13h-1.24l-.72.17-.07,1v5.94h.37v.18h.67Zm-.27-13.64,1-.13.31-.17.45-.18.45-.26.22-.48.15-.61v-.74l-.19-.43-.23-.44-.35-.48-.3-.3-.37-.09h-1.81l-.26.08-.08.61v2.44l-.18.76v.39h.38Z"/>
      <path class="cls-1" d="M518.33,72.47h-1.31994l-.38.13-.26-.13h-.38l-.6.09h-.56l-.19-.13h-.15l-.07-.35.11-.53V70.9l.07-.87V66.26l.07-.26V62.8l.08-1.48V53.91l.11993-1.52V50.73l-.08-1.52v-.83l.08-1.61V41.84l.27-.09.15-.17h3.49006l.23-.13h.41l.68-.08h.64l.3-.09h.38l1.09.09,1.8.09h.98l.52.13h4.97l.15.26.15.31.07.43.23.31.22,1.13-.11.52-.07.31v.22l.07.43.15.44.11.22-.22.17-.3.17-.34.57-.45.57-.26.26-.19.13h-.27l-.18-.13h-.42l-.6-.05-.56.05-.19.13H520.87v4.92l.15.09h3.64l1.13.09h.41l.34.13h1.02l.6.17h.49l.37.44.08.35v.43l.26.35-.15.35.15.3v.75l.15.3-.15.26-.07.35v.61l.15.13.11.13-.11.18h-.15l-.3.26-.26.52.11.26v.13l-.45.13-.27.35h-.63l-2.94-.09h-1.24l-1.65.08-.94.13-.3.22v.26l-.07.92v4.27h1.58l.38.09h1.09l.45.09.94.13.9-.05h2.63l.49-.08H530.01l.45.08.15.26.12.35v.35l.23.22v.22l-.15.21.15.18.08.3-.15.09h-.12v.13l.19.18.08.3-.15.44-.08.57-.15.3.26.22.15.13-.07.09-.3.17-.34.26-.11.35v.44l-.75.43h-4.33l-.3.09h-6.28l-.15.17h0Z"/>
      <path class="cls-1" d="M546.22,72.08l-.49-1-.86-1.4-.49-.74-1-1.57-.9-1.39-.61-.97-.41-.44-.49-.65-1.62-2.22-.37-.48H538.87v2.61l-.08,1.48v1l-.07.53v4l-.26.57-.37.13-.31-.09-.33.14-.12.08-.3-.08-.37.26-.19-.13h-.08l-.18.13h-.34l-.27.13-.22-.08-.23.13-.15-.09-.15.09-.52.08-.42-.08h-.41l-.15-.09V63.23l.08-.56V55.96l.08-1.09V47.16l.08-.69-.08-.35V42.81l-.08-.78V41.5l.07-.08h1.17l.08-.09h.67l.19-.13h1.56l.19-.13h.67l.23.08h1.65l.45-.13.38.13h.34l1.35.09.38.08.52.09.46.17h.26l.26.22,1,.39.86.61.53.52.38.57.37.87.38,1.17.3,1.09.19.66.11.34.07.7.23,1,.08.48-.08.65.15.61v.18l-.11.48v.56l-.15.31-.15.82-.34,1-.19.48-.64,1.09-.15.31-.52.56-.38.52-.34.31-.52.44-.34.34.19.48.18.39.53.7.9,1.26.91,1.44.67,1,.57,1,.9,1.43.64,1.14.34.78.3.65-.27.26-.3.31-.22.13h-.12l-.07.22-.11.22h-.34l-.19.43h-.23v.14l-.23.13h-.26l-.12.26h-.11l-.22.14-.11.21h-.15l-.26.43-.23.22-.15.09-.11.08h-.15v.13l-.19.09h-.41Zm-6.35-17.64.71-.05.94-.08.23-.18.49-.35.6-.56.45-.53.26-.69v-.83l-.15-1.22-.45-1.31-.45-.35-.42-.17-.75-.3-.67-.09h-1.62l-.08.56v6.02l.11.13Z"/>
      <path class="cls-1" d="M556.34,72.77l-.64-.21-.53-.31h-.45l-.49-.26-.79-.44-.49-.3-.3-.26-.49-.35-.33-.31-.34-.22-.26-.3-.38-.35-.3-.39v-.18l-.08-.21v-.22l.11-.18.23-.21.15-.22v-.22l.07-.13.27-.3.11-.05v-.26l.15-.17v-.18l.07-.13.3-.3.15-.44.19-.09.11-.39.15-.35.19-.13v-.26l.07-.09.12-.26h.3l.19.17.11.13h0l-.08-.09v-.17l.23-.13.75.57.94.74.72.47.67.14h.79l1-.44.6-.43.53-1,.41-1.44v-.87l-.15-.35-.19-.48-.15-.52-.15-.22-.18-.26-.27-.18-.19-.21-.26-.4-.26-.34-.3-.31-.19-.26-.26-.22-1.17-1.13-.75-.92-.53-.69-1-1.44-.75-1.52-.23-.7L552.87,50.47l-.08-1.22V47.64l.15-.7.49-1.08.3-.57.26-.44.19-.3.53-.65.49-.57.48-.48.61-.52.75-.57,1-.39.53-.26.83-.31,1.58-.21,1.76.08,1.06.13.37.09.23.13v.18h-.12l-.07.09.11.17.19.09v.35l-.11.21.15.61h-.15l.07.13v.57l.11.26v.13h-.15v.17l.22.26-.11.13.11.13v.51l-.18.09v.08l.11.13.22.13.12.13v.09h-.12l-.18.08v.7l-.11.17v.48l-.11.57-.12.17-.26-.21-.9-.31-.68-.13h-1.46l-.91.35-.26.26-.3.26-.34.44-.15,1,.45.88.79.87,1,1.13.64.65.6.74L563.59,55.01l1,1.48.94,1.48.3.75.49,1.61.27,1.56.07,1-.02,1.12-.46,1.65-.37.92-.79,1.57-.68.87-.41.39-.26.39-1.09,1.09-.72.52-.79.48-.94.4-.75.26-.6.13-1.35.13Z"/>
    </g>
  </g>
</Floater>
    )
}
export default ProfessionalTraining;
