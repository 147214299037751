import React from 'react'
import {Row, Col} from 'react-bootstrap'
import GetStartedCards from './GetStartedCards'

const ReadyToGetStarted = () => {
    return (
        <>
            <Row>
                <Col >
                <h2 className="sectionTitle" style={{marginBottom: '2rem'}}>
                    Ready to get started?
                </h2>   
                <h3 className="text-center subSectionTitle">
                Local counselors offer comprehensive in-person or telehealth mental health treatment options to meet your needs.
                </h3>       
                </Col>
            </Row>
            <Row className="my-4">
                <Col className="mb-4">
                    <GetStartedCards />
                </Col>
            </Row>
        </>
    )
}

export default ReadyToGetStarted