import React from "react"
import PaperBackground from "../PaperBackground/PaperBackground"

import { Container, Row, Col } from 'react-bootstrap'

import AmoebaSlim from "../Amoebas/AmoebaSlim"
import ProgramCards from "./ProgramCards"
import PurpleButton from "./PurpleButton"
import StaffAndBoard from "./StaffAndBoard/StaffAndBoard"
import ReadyToGetStarted from "./ReadyToGetStarted/ReadyToGetStarted"
import WeHaveHelped from "./WeHaveHelped/WeHaveHelped"
import FacebookFeed from "./FacebookFeed/FacebookFeed"
import NewsCards from "../NewsCards/NewsCards"

const FreeCounselingProgram = props => {
  return (
    <>
      <PaperBackground>
        <Container
          fluid
          style={{
            height: "auto",
            padding: "1rem",
          }}
        >
          <h2 className="sectionTitle">
            How our free counseling program works
          </h2>
          <ProgramCards />
        </Container>
        <div style={{ bottom: 0 }}>
          <AmoebaSlim
            style={{ width: "auto", height: "100%", bottom: 0 }}
            fillColor={"#87C3CC"}
          />
          <div
            style={{
              position: "absolute",
              bottom: 0,
              padding: "10px",
              marginTop: "10px",
            }}
          />
          <Container
            fluid
            style={{
              background: "#87C3CC",
              marginTop: "-5px"
            }}
          >
            <Container>
              <Row>
                <Col className="my-4">
                  <h3 className="text-center subSectionTitle">
                    Our mission is to promote mental wellness in Teton Valley
                    through education, community action, and the coordination of
                    resources.{" "}
                  </h3>
                  <p className="purpleText"
                    style={{
                        fontWeight: 300
                    }}
                  >
                    We are mental health providers, small business owners, parents, medical professionals, local leaders, and dedicated community members who share a common passion for fostering mental health in Teton Valley. Teton Valley Mental Health Coalition formed in the Fall of 2009 and later became a 501(c)(3) nonprofit to help foster collaboration among mental health care providers in the community. We are dedicated to addressing emergent community mental health needs and fostering mental wellness through the following programs and services:
                  </p>
                </Col>
              </Row>
            </Container>
            <Container>
                <PurpleButton />
            </Container>

            <Container>
                <NewsCards />
            </Container>
          </Container>
          
        </div>
        <div style={{
            marginTop: '-5px'
        }} className="rotate">
        <AmoebaSlim
            style={{ width: "auto", height: "100%", top: 0, }}
            fillColor={"#87C3CC"}
          />
        </div>

        <Container className="mb-4">
            <ReadyToGetStarted />
        </Container>

        <Container fluid>
        <AmoebaSlim
            style={{ width: "auto", height: "100%", top: 0, }}
            fillColor={"#D8D8F6"}
          />
        <WeHaveHelped />
        <FacebookFeed 
          backgroundColor="#D8D8F6"
        />
        
        </Container>
        
      </PaperBackground>
      {/* <div 
        className="rotate"
        style={{
            marginTop: '-15px'
        }}>     
           <AmoebaSlim
            style={{ width: "auto", height: "100%", top: 0, }}
            fillColor={"#D8D8F6"}
          />
        </div> */}
    </>
  )
}

export default FreeCounselingProgram
