import React from 'react'
import styled from 'styled-components'

import FreeCounseling from "./Floaters/FreeCounseling"

import Fade from 'react-reveal/Fade';
import SuicidePrevention from './Floaters/SuicidePrevention';
import CounselingInSchools from './Floaters/CounselingInSchools';
import ProfessionalTraining from './Floaters/ProfessionalTraining';
import CommunityCollaboration from './Floaters/CommunityCollaboration';
import GriefSupport from './Floaters/GriefSupport';

const PurpleButtonWrapperLeft = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding-bottom: 0;
    flex-wrap: wrap;
    
    @media(min-width: 1024px) {
        flex-wrap: nowrap;
        padding-bottom: 4rem;
    }
`;

const PurpleButtonWrapperRight = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    padding-bottom: 0;
    flex-wrap: wrap;

    @media(min-width: 1024px) {
        flex-wrap: nowrap;
        padding-bottom: 4rem;

    > div {
        display:flex !important;
        justify-content: flex-end !important;
    }
}
`;

const ButtonWrapper = styled.div`
    @media(max-width: 1024px) {
    margin-top: 10px;
    margin-bottom: 10px;
    }
`;


const PurpleButton = (props) => {
    return (
        <>
        <PurpleButtonWrapperLeft>
            <Fade left>
                <ButtonWrapper>
                    <FreeCounseling />
                </ButtonWrapper>
            </Fade>
            <Fade right delay={500}>
                <ButtonWrapper>
                    <SuicidePrevention />
                </ButtonWrapper>

            </Fade>
        </PurpleButtonWrapperLeft>

        <PurpleButtonWrapperRight>
            <Fade left delay={1500}>
                <ButtonWrapper><CounselingInSchools /></ButtonWrapper>
            </Fade>
            <Fade right delay={1000}>
                <ButtonWrapper><ProfessionalTraining /></ButtonWrapper>
            </Fade>
        </PurpleButtonWrapperRight>

        <PurpleButtonWrapperLeft>
            <Fade left delay={2000}>
               <ButtonWrapper><GriefSupport /></ButtonWrapper>
            </Fade>
            <Fade right delay={2500}>
                <ButtonWrapper><CommunityCollaboration /></ButtonWrapper>
            </Fade>
        </PurpleButtonWrapperLeft>
        </>
    )
}

export default PurpleButton