import React from "react";
import styled from "styled-components"

const Floater = styled.svg `
margin: 0 20px 0 20px;
width: 85%;
  height: auto;
  .cls-1 {
        fill: #434081;
      }
`;

const CommunityCollaboration = (props) => {
    return (

<Floater xmlns="http://www.w3.org/2000/svg" viewBox="0 0 565.67999 73.20003">
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <path class="cls-1" d="M10.93,32.93l-.94-.09-.73-.16-.86-.44-.9-.26-.63-.3-.73-.48-.55-.39L3.92,29.42l-.48-.7-.35-.43-.17-.43-.11-.09-.34-.65-.49-1-.52-1.44-.42-1.43L.8,22.42l-.14-.26-.1-.52-.18-.78-.2-1.12L.07,18.68l-.07-1V15.42L.14,14.2l.14-.65.07-.43.1-.43.07-.52.35-1.35.45-1.51.24-.65.21-.48.42-1,.69-1.12.63-.83.69-.82.48-.56.66-.61.59-.52,1.22-.78,1.32-.61L9.61.9,10.69.73l.66-.05,1.28.05,1.39.21.76.18.76.34,1,.39.45.22.18.22-.07.17-.07.13.1.17-.1.39-.14.35-.1.09.07.13v.21l.07.09v.09l-.1.17h-.49l.21.22.17.39-.1.13h-.14v.22l-.1.34-.11.18.07.21-.13.22h-.07l.07.09v.4l-.14.3L16,7.29l-.11.52-.27.52-.3.25-.48-.18-.35-.21-.66-.18-1.18-.26-.75-.07-.9.08-.56.18-.38.13H9.89l-.24.13-.32.21-.76.57-.55.56-.14.22-.25.26-.38.6-.31.61-.21.52-.24.56-.24.7-.14.52-.18.82-.13.69-.11.26-.07.52-.07.39v1l.14,1.22v.65l.11.52.17.95.21,1,.34,1.13.66,1,1,.91,1.15.78.59.3.45.13h.45l.24.08.8.13.28-.09.48-.08h.42l.52-.26.38-.26.31-.26.49-.39.62-.65.63-.61.28-.21.14-.05.06.05v.21l.24.22.11.3.1.26h.31v.22l-.07.21.24.22h.21l-.14.22-.1.22h.17l.14-.05h.07l-.11.3-.07.35.14.13.35-.26.17.09v.21l-.14.22v.09h0l.13-.13h.18l-.07.17-.11.22v.13h.14l.1-.09.21-.09.07.14-.11.13-.13.21.07.09h.17v.34l.11.09.1.31v.39l-.48.6L18.4,29h.11l.1.09-.29.59-.66.69-.55.56-.31.22-.48.43-.76.57-.42.26-.69.21-1,.26-.87.09h-.83Zm6-2.17h-.14v.09Zm.76-.69.49-.52.24-.31h-.07v.09l-.31.26-.28.3-.21.17v.13Z"/>
      <path class="cls-1" d="M26.12,33.68l-.9-.35-.73-.39-.72-.52-.94-.74-.59-.56-.66-.78-.56-.74-.41-.78-.49-.86-.38-.92-.21-.6-.45-1.17-.24-.87-.35-1.82-.17-1.65V17.98l.07-.74v-.6l.1-1.22.11-.69.13-.87.21-.73.18-1.09.17-.73.21-.48.07-.43.1-.48.14-.48.07-.3.17-.35.18-.65.2-.52.14-.47.35-.52.17-.7.42-.52.35-.69.62-.95.73-.83.76-.69.56-.48,1-.56.91-.43L28.07.3l.62-.13,2-.17h.52l.73.13.73.26.66.35,1.25.82,1,1,.83,1.13.91,1.59.53,1.4.47,1.46.2,1.13.11.82.14,1.13V12l.07,1v4.42l-.07,1.17-.1,1-.11.69-.1.13-.17.83-.18,1.08v.43l-.18.35.07.39-.1.3-.14.74-.15,1.15-.35,1.38-.31,1.13-.07.39-.18.26-.24.52-.28.48-.17.43-.28.17-.69.87-1.08.95-.64.32-.76.35-.77.21-.62.13-.59.22H28.27l-.79.21Zm3.09-6.76.52-.13h.21l.21-.09.14-.17.34-.17.42-.22.14-.18.24-.34.28-.48.31-.46.21-.86.21-.7.07-.43.1-.3v-.51l.14-.48.14-.26v-.52l.07-.3.17-.3-.1-.35.17-.39v-.35l.07-.56v-.78l.1-.61v-.43l.07-1V13.29l-.12-.77V11.48l-.17-.74-.38-1.3-.45-.69L31.5,7.68l-.66-.48-.8-.26-1.28.22-.55.21h-.39l-.17.09-.33.22-.45.43-.27.26-.07.26-.32.3-.45.83-.27.56-.17.36-.17.52-.07.43-.14.39-.14.74-.14.6-.07.39-.14.61-.1,1-.14,1.52-.07,1.3.18,1.1.07,1,.13,1.17.21.82.11.48.24.56.52,1,1.11,1.21,1.11.83.5.33.14.09-.07.17.14.08Zm-.41.48-.11-.13h-.14l-.07.08h.14l.14.12Zm.48,0v-.09h-.11v.09Zm.45-.26v-.05l-.11.18Zm3.33-5.46v-.13l.07-.3v-.31h0l-.07.22v.26l-.07.35h.07Z"/>
      <path class="cls-1" d="M40.83,32.8h-.14l-.37-.52V30.93l.1-.25V21.87l.07-1.08V12.48l.07-1.74V8.66l.07-1.69.18-1.73v-2l.06-.78.25-.47h.52l.8-.09.38-.09h.9l.14-.13.17-.13.14.09.17.08.18-.13h.27l.32-.17.07.13.07-.09.13-.08.14.13h.8l.35.08.07.13v.31l.41,1,.31.78.32,1,.17.52.21.52.28.83.2.65v.35l.14.21.38,1,.32,1,.07.3.17.39.24.65.24.74.14.39.21.47.28,1,.1.31.07.3h.14l.11-.26.06-.34.11-.26v.3l-.17.61-.14.34h.21l.11-.26.17-.34.11-.57.2-.56.18-.39.1-.43.24-.52.07-.39.07-.22.14-.22.11-.47.17-.61.14-.26.1-.69.21-.78.14-.26.17-.52.18-.74.17-.39.07-.3.15-.23.38-1,.49-1.51.17-.61V2.21l.1-.08h.35l.31-.08h.42L56.95,2h.49l.52-.08.31.08.14.05.17-.26.25.08.1.09.1-.09.29-.19h.59l.14-.05.2.09.14.22h.07V1.68h.07V2.85l.07.78V9.52l-.06.87v.91l-.07,1.86V26.5l-.07,1.69v3.6l-.14.09-.1-.18h0v.43l-.28.26-.31-.08-.38-.05-.17.22-.14-.09h-.31l-.11.13v-.13l-.07-.13h-.11v.22h-.14l-.1-.17-.18-.18h-.1v.22l-.07.3h-.48l-.9.09-.28-.17h-.24l-.11-.44-.07-.43V24.48l-.29-1.8v-6.3l-.07-.35-.31.65-.28.82-.31.87-.42,1.08-.21.52-.24.65-.28.74-.07.3-.13.18v.21l-.1.09-.07.26-.32.21-.17.09-.1-.09.1-.08h0l-.24-.09h-.49l-.31.09-.1.13-.42.22H49.67l-.52.09h-.38l-.31-.29.07-.56-.21-.72-.17-.34-.18-.57-.48-1.3-.28-.69L47,17.94l-.14-.35-.06-.39-.24-.52-.18-.44h0v.13l-.06.31-.1.09v-.18l-.07-.39-.11-.3h-.17v.65l-.15,1.13v6.37l-.07,1.21v4.29l-.1,1v1l-.07.22h-.2l-.14.35-.35.17-.28-.17-.48.34-.21-.17-.11.13-.39.16H43l-.27-.09-.18.17v.13l-.23-.11h-.45l-.38.13-.28.09h-.24Zm8.54-10h-.15v.08Zm10.95-1.22V21.4h-.07v.18Zm0,4.1v-.3h-.07v.3Zm0,2.69V26.29h-.07v2.08Zm.07-9v-.52h0l-.07.26.07.31Zm0,4.86v-.44h-.07l-.07.09v.39Zm.21-17-.07-.3v.78Z"/>
      <path class="cls-1" d="M63.17,32.8h-.13l-.42-.56V30.89l.14-.26V21.87l.07-1.08V12.48l.06-1.74V8.66l.07-1.69.18-1.73v-2l.07-.78.24-.47h.52l.8-.09.38-.09h.9l.14-.13.17-.13.14.09.17.08.18-.13h.28l.31-.17.07.13.07-.09.14-.08.13.13h.42l.35.08.07.13v.31l.41,1,.32.78.31,1,.17.52.21.52.28.83.2.65v.35l.14.21.38,1,.32,1,.07.3.17.39.24.65.25.74.13.39.21.47.28,1,.1.31.07.3h.14l.11-.26.07-.34.1-.26v.3l-.17.61-.14.34h.18l.1-.26.17-.34.11-.57.21-.56.17-.39.1-.43.25-.52.07-.39.06-.22.14-.22.11-.47.17-.61.52-.17.1-.69.21-.78.14-.26.17-.52.18-.75.17-.39.07-.3.17-.35.39-1,.48-1.51.17-.61V2.17l.1-.08h.35l.31-.08h.42l.41-.05h.49l.52-.08.31.08.14.05.18-.26.24.08.1.09.11-.09.24-.13h.59l.14-.05.21.09.13.22h.07V1.7h.07V2.87l.07.78V9.54l-.07.87v.91l-.07,1.86V26.5l-.07,1.69v3.6l-.14.09-.1-.18h0v.43l-.28.26-.31-.08-.38-.05-.17.22-.14-.09h-.31l-.11.13v-.13l-.07-.13h-.11v.22h-.22l-.1-.17-.18-.18h-.1v.22l-.07.3h-.48l-.9.09-.28-.17h-.29l-.11-.44-.07-.43V16.38l-.07-.35-.31.65-.28.82-.31.87-.42,1.08-.21.52-.24.65-.28.74-.07.3-.13.18v.21l-.1.09-.07.26-.31.21-.18.09-.1-.09.1-.08h0l-.44-.13h-.49l-.31.09-.1.13-.42.22H71.89l-.52.09h-.38l-.18-.25.07-.56-.25-.74-.17-.34-.14-.55-.49-1.3-.28-.69-.23-.56-.13-.35-.07-.39-.22-.52-.18-.44h0v.48l-.11.09v-.18l-.07-.39-.11-.3h-.17v.65l-.19,1.09v6.37L68,25.26v4.29l-.1,1v1l-.06.22h-.21l-.14.35-.35.17-.28-.17-.48.34-.21-.17-.1.13-.14.13h-.31l-.28-.09-.18.17v.13h-.63l-.38.13-.27.09h-.56Zm8.54-10h-.14v.08Zm11-1.25v-.18h-.07v.18Zm0,4.07v-.3h-.06v.36Zm0,2.69V26.23h-.07v2.08Zm.07-9v-.52h0l-.07.26.07.31Zm0,4.86v-.44h-.07l-.07.09v.39Zm.21-17-.07-.3v.78Z"/>
      <path class="cls-1" d="M92.76,33.32H90.82l-.24-.18-.69-.21-.77-.44-.73-.6-.93-.65-.59-.65-.31-.44-.35-.47-.35-.86-.31-.7-.17-.69-.28-1.17-.11-1.43V22.14l-.07-.56v-3.9l.07-1V14.17l.07-1.69V8.19l.07-.78.11-1.61V1.94l.2-.26.32-.08.13.08h.25l.21.09.17-.13.35.08.13.13h.07l.18-.17.21.08h.31l.34.09.21-.18h.14l.14.09h.24l.28-.05.28.05h.17l.28.08h.66l.07.57v8.37l.07,1.08V17.5l-.07.65-.07,1.51-.07,1.95-.06,1.52-.07.69.07.57.13.78.46.56.65.65,1,.3.65.13.63.18.59-.05.59-.39.55-.47.28-.13.28-.44.35-.91.24-1,.07-.82.07-.91.07-.43-.07-.22v-.95l.07-1.22V4.7l-.11-.44.07-.43-.1-.43.07-.48-.07-.35v-.3l.07-.17h.18l.24-.13h.31l.35.08.32-.37h.31l.18.09.2-.13.18.13h.52l.52-.09.28.09.48-.09.83.13.28-.09.21.13v.83l.07,1.61V8.88l-.06,2.95v3.29l.07,1.47v4.25l-.07.52v2.32l-.17,1.13-.07.69-.25.65-.34.91-.52,1.21-.66.91-.42.65-.45.61-.66.52-.45.3-.24.3-.25.22-.69.39-.55.26-.7.35-.45.21-.52.13-.31.09-.17.13H94.2Z"/>
      <path class="cls-1" d="M118.96,32.68l-.14-.57h-.21l-.35.17-.21-.08-.13.13-.18-.09-.28-.3-.45-.91-.55-1.39-.49-1.34-.38-.82-.35-.92-.69-1.6-.66-1.47-.73-1.82-.55-1.26-.38-.78-.84-1.64-.59-1.48-.48-1.22-.34-.69-.38-.74v-.08h-.07v4.81l-.21,1.09V25.4l-.14.21.07.26v.35l-.07.26.07.35v.21l-.07.35-.27.13v.21l.11.18.07.13h.1l.07-.05h0v.26l-.21.09-.24.09-.11.3.11.22h0l.07.13v.08l-.07.35v.17l.1-.09.11.09-.07.22-.11.21.07.44v.6h.14l.1.35v.35l-.17.08-.21.13h-.31l-.39.13h-.27l-.21.09h-.28l-.21.09h-.14l-.27.17-.28.26-.24-.22h-.11l-.14.35h-.07l-.13-.09-.11.09h-.8l-.17.09-.17-.09-.21-.17v-.61l.17-.48.24-.13.14-.12v-.14l-.11-.17h-.31l-.17-.47V29.21l-.07-.52v-.74l.07-1-.07-1,.07-1V21.36l.07-1.87-.08-1.81V8.71l.07-1.17.07-.69V5.33l.07-.74.1-1.51V2.17l.18-.09.34-.09.35.09h.83l.18-.09.41.18.14-.13h.59l.28.09h.5l.14-.13h.34l.14-.08h.87l.21-.13.38.35.55.95.77,1.43.79,1.6.35.78.38.74.73,1.56.35.74.83,1.69.66,1.51.45,1.17.66,1.35.35.73.41.83h.07V14.93l-.07-.35v-.56l-.11-.35.18-.87-.11-.43v-.48l.07-.56V3.53l.1-.95V2.19l.35-.13h1.67l.2.09.21-.09h.31l.28-.13h.31l.49-.09h1.18l.21.08.17.05V4.44l.13,1.54V7.23l-.07,1.56v.87l-.07,1.47v1.78l-.07,1.25v7.46l.07,1.25V29.2l-.1,1.35v1.08l-.11.61-.2.08-.25.05-.31-.05-.21.18h-.1l-.58-.22-.13-.09-.11.17-.07.09h0l-.28-.13-.28.34h-.17l-.17-.26h-.18l-.14.26h-.17l-.07-.22h0l-.07-.05-.11.22-.07.48-.07.34h-.1Z"/>
      <path class="cls-1" d="M126.42,33.1l-.07-.56-.38-.09h-.18l-.17-.3-.18-.52-.06-.56-.18-.3v-.79l.1-.22v-.21l.14-.35v-.65l-.1-.3v-3.6l.1-.43-.07-.87v-5.5l-.1-.35v-.39l-.07-.39v-1.9l.14-2.26.07-.73V2.08l.17-.35.21-.09.24.09h2.12l.14.09.17-.09h.42l.24-.13.17.13.14-.09.28-.08.31.17h.3l.2-.09.25.09V8.06l-.07.3v.13h.1v.26l-.17.09h-.07l.07.22.07.3.07.26v1.34l-.07.44-.07.21-.11.05-.07.21v.65h.1l.07.08v.57h-.11v.86l.11.09v.17l-.11.18v1.34l.14.13-.07.17v.74l.14.82.07.91v5.9l-.06.74v3.16l.02,1.3v.44l-.14.6v.44l-.17.26-.24.21h-.21l-.24.17-.07.26-.14.05-.17-.09-.18.13h-.17l-.18.08-.13-.13h-.11l-.1.26-.07.39v-.22l-.11-.43h-.13l-.11.17-.28.35v.17h0l-.06-.17-.18-.13-.14.17-.07.52v.35h0l-.06-.26v-.34l-.07-.13-.14.08-.18.09-.07.26-.07.61-.06.39h-.07Zm4.23-28.6V4.42l-.07.08v.09Zm.14,6.11v-.17h-.07v.09l.07.09Z"/>
      <path class="cls-1" d="M137.97,32.06l-.28-.13V28.98l.07-.39-.07-.6V22.18l-.07-1.82V17.81l.07-3.38V11.22l-.07-1.26v-1l-.07-.69H135.4l-1.7.17-1,.05h-.52l-.28-.17.07-.22V7.62l.11-.47-.11-.22V6.67l.17-.35-.1-.26.07-.34V4.68l.1-.47V3.86l.07-.35.1-1v-.3l.39-.39.62-.09.63.13h1.17l.39.09.52-.09h.22l.34-.08h5.07l1.18-.17,1-.13h4.88l.42.05.07.52-.11.52.07.39-.07.43.07.3-.1.22-.07.3-.11.13.07.22v.35l.07.17v.26l-.17.17-.11.22.25.39-.25.35-.1.34v.26l.14.18-.24.26-.21.34h-.73l-.34.22-.35.08h-3.33l-.38.18-.42.3v.82l.07,1.39V23.48l-.07,1v3.68l-.07,1.22v1.95l-.21.17-.45.13-.31.39h-.14l-.11-.35-.17.13-.1.09-.14-.09-.14.3v-.17h-.14l-.07.13-.17.09-.21-.09H140.32l-.14-.13-.14.09-.17-.05-.24.05h-.31l-.18-.09-.1.09h-.18l-.1.17Z"/>
      <path class="cls-1" d="M156.11,33.06l-.17-.31V27.6l.07-.35-.18-.91V24.78l.07-1.43V22.18l-.07-1.25v-.78l.07-1v-.69l-.58-1.22-.49-.91-.31-.47-1.11-2.08-.31-.57-.17-.21-.32-.57-.66-1.12-.63-1.13-.49-1-.48-.91-.28-.57-.42-.82-.41-.87-.21-.47-.28-.7V4.32l.24-.21.21-.13.24-.18h.24l.11-.22.35-.22.27-.13.14-.17h.28l.07-.08.17-.05.21-.08.04-.17.18-.08.14-.09.1-.09.17-.17.11.09.1-.18.24-.26.35-.08.31-.26.28-.09.35.65.48,1.13.52,1,.63,1.26.52,1,.42.73,1.35,2.34.17.52.42.78.34.78.07.09h.07l.21-.61.35-1,.17-.56.24-.48.35-.82.14-.56.14-.39.07-.31.21-.13.17-.34V6.16l.32-.87.41-1.08.21-.39.24-.7.28-.73V2.17l.14-.26.06-.35.14-.21.14-.13.24.08.32.05.31.17.31.09.35.17.24.22.14-.09.21.13h.24v.22l.17.09.25-.09.07.13h.2l.18.09h.14l.1.13H166.32l.42.13.49.13.27.13v.08l-.07.26-.45,1-.46,1.34-.38.91-.42,1.09-.9,2.25-.5,1.33-.56,1.52-.44,1.34-.66,1.69-.34.87-.52,1.52-.25.74-.07.13-.21.47-.06.74v2.21l-.1,1.6v1.13l-.07.69v6.85l-.24.13h-.25l-.14.09-.34-.09-.35.13-.38-.09-.11.18h-.17l-.14-.09-.1-.08-.14.08h-.1l-.18-.09-.14.13h-.55l-.14-.13h-.25l-.28.13h-.93Zm-.17-6.33h0v0Z"/>
      <path class="cls-1" d="M185.42,32.93l-.93-.09-.73-.17-.87-.44-.9-.26-.62-.34-.73-.48-.56-.39-1.66-1.34-.49-.7-.34-.43-.18-.43-.1-.09-.35-.65-.48-1-.53-1.44-.41-1.43-.24-.83-.14-.26-.11-.52-.17-.78-.21-1.12-.1-1.09-.07-1V15.39l.14-1.22.13-.65.07-.43.11-.43.07-.52.34-1.35.46-1.51.24-.65.21-.48.41-1,.7-1.12L178,5.2l.69-.82.49-.56.66-.61.59-.52,1.21-.78,1.32-.61,1.15-.43L185.18.7l.66-.05,1.28.05,1.39.21.76.18.77.34,1,.39.45.22.17.22-.07.17-.07.13.11.17-.11.39-.13.35-.11.09.07.13V3.9l.06.09v.09l-.1.17h-.48l.2.22.18.39-.11.13h-.14v.22l-.11.34-.1.18.07.21-.14.22h-.07l.07.09v.43l-.14.3-.07.31-.1.52-.28.52-.45.26-.49-.18-.34-.21-.66-.18-1.18-.26-.76-.08-.91.08-.55.18-.38.13h-.18l-.24.13-.31.21-.76.57-.56.56-.14.22-.24.26-.38.6-.31.61-.21.52-.25.56-.24.7-.14.52-.17.82-.14.69-.1.26-.07.52-.07.39v1l.14,1.22v.65l.1.52.17.95.21,1,.54,1.13.66,1,1,.91,1.14.78.59.3.46.13h.45l.24.08.8.13.27-.09.49-.08h.42l.52-.26.38-.26.31-.26.49-.39.62-.65.62-.61.28-.21.14-.05.07.05v.21l.24.22.1.3.11.26h.31v.22l-.07.21.25.22h.21l-.14.22-.11.22h.18l.13-.05h.07l-.1.3-.07.35.14.13.35-.26.17.09v.21l-.13.22v.09h0l.14-.13h.17l-.07.17-.1.22v.13h.14l.11-.09.21-.09.06.14-.1.13-.14.21.07.09h.17v.34l.1.09.11.31v.39l-.49.6-.07.13h.1l.11.09-.35.52-.66.69-.56.56-.31.22-.48.43-.77.57-.41.26-.7.21-1,.26-.87.09h-.83Zm6-2.17h-.1v.09Zm.76-.69.48-.52.25-.31h-.07v.09l-.31.26-.28.3-.21.17v.13Z"/>
      <path class="cls-1" d="M200.62,33.68l-.9-.35-.73-.39-.73-.52-.94-.74-.59-.56-.65-.78-.56-.74-.42-.78-.48-.86-.38-.92-.21-.6-.45-1.17-.24-.87-.35-1.82-.18-1.64V17.98l.07-.74v-.6l.11-1.22.1-.69.14-.87.21-.73.17-1.09.18-.73.2-.48.07-.43.11-.48.14-.48.07-.3.17-.35.17-.65.21-.52.14-.47.35-.52.17-.7.42-.52.34-.69.63-.95.73-.83.76-.69.55-.48,1-.56.9-.43L202.55.3l.63-.13,2-.17h.52l.73.13.73.26.65.35,1.25.82,1,1,.84,1.13.86,1.51.56,1.48.42,1.43.21,1.13.1.82.14,1.13v.78l.07,1v4.42l-.07,1.17-.11,1-.1.69-.11.13-.17.83-.17,1.08v.43l-.17.35.07.39-.11.3-.13.74-.09,1.18-.35,1.38-.31,1.13-.07.39-.17.26-.24.52-.28.48-.17.43-.28.17-.7.87-1.07.95-.63.35-.76.35-.76.21-.63.13-.59.22h-2.32l-.8.21Zm3.09-6.76.52-.13h.21l.2-.09.14-.17.35-.17.42-.22.13-.18.25-.34.27-.48.32-.52.2-.86.21-.7.07-.43.11-.3v-.45l.14-.48.13-.26v-.52l.07-.3.18-.3-.11-.35.18-.39v-.35l.07-.56v-.78l.1-.61v-.43l.06-1V13.29l-.06-.78V11.47l-.18-.74-.38-1.3-.35-.69L206,7.68l-.66-.48-.8-.26-1.28.22-.56.21h-.38l-.17.09-.35.22-.45.43-.28.26-.07.26-.31.3-.45.83-.28.56-.1.35-.18.52-.07.43-.14.39-.13.74-.14.6-.07.39-.14.61-.1,1-.14,1.52-.07,1.3.1,1.08.07,1,.14,1.17.21.82.1.48.24.56.52,1,1.11,1.21,1.11.83.56.3.14.09-.07.17.14.08Zm-.42.48-.1-.13h-.14l-.07.08h.14l.14.12Zm.49,0v-.09h-.1v.09Zm.45-.26v-.05l-.1.18Zm3.33-5.46v-.13l.07-.3v-.31h0l-.07.22v.26l-.07.35h.07Z"/>
      <path class="cls-1" d="M222.61,31.68l-.29-.1h-2.08l-.49.05h-.9l-1.39-.05h-1.28l-.11.05h-.76l-.42-.22-.1-.39v-1l.11-.3V16.36l.07-.52V14.15l.07-1.47.1-1.82.07-1.52V6.79l.07-1.22-.07-.73V2.55l.07-.34v-.7l.1-.47.28-.05h1.19l.1.08.1-.08.21.08h.21l.14-.09.21.18h.07l.1-.09h.33l.24-.17h.35l.34-.09.25.05.14-.09h.27l.07.09h.31l.28-.05.11.18.13-.09.07.13v1.6l.07.44V5.54l-.07.95h-.14l-.1.48V8.96l-.07,1-.07,1.34v2.22l-.18,1.16-.07,1.13V18.8l-.07,1.08-.07.83v.86l-.1.22.07.43-.07.65v.52l-.14.39.07.52v.39l.18.31h1.79l1,.08.65.09.46-.05h.3l.28.09h.41l.7.09h2.74v1.34l-.14.4.14.08.1.13v.13l-.1.26v.22l-.07.09v.43l-.14.21.14.09.24.09.14.17-.18.13-.17.26.07.22.17.08v.09l-.1.09-.14.17v.35l-.11.13-.07.13v.26l-.18.34-.17.22h-.97l-.17.21-.28.13-.14-.17v-.13h-.21l-.11.13-.1-.13-.28-.08h-.41l-.14.17h-.11l-.34-.17h-.87l-.21.22h-.55Z"/>
      <path class="cls-1" d="M237.39,31.68l-.34-.05h-2.09l-.48.05h-.9l-1.39-.05h-1.28l-.11.05h-.76l-.42-.22-.1-.39v-1l.11-.3V16.41l.07-.52V14.2l.07-1.47.1-1.82.07-1.52V6.84L230.32,5.68l-.07-.73V2.55l.07-.34v-.7l.1-.47.28-.05h.93l.11.08.1-.08.21.08h.21l.14-.09.21.18h.06l.11-.09h.28L233.3.9h.35l.34-.09.25.05.13-.09h.28l.07.09h.31l.28-.05.11.18.13-.09.07.13v1.6l.07.44V5.54l-.07.95h-.14l-.1.48V8.96l-.07,1-.07,1.34v3.43l-.07,1.13v2.99l-.07,1.08-.06.83v.86l-.1.22.07.43-.07.65v.52l-.14.39.07.52v.39l.18.31h1.97l1,.08.66.09.45-.05h.25l.27.09h.42l.69.09h2.75v1.34l-.13.4.13.08.11.13v.13l-.11.26v.22l-.07.09v.43l-.14.21.14.09.24.09.14.17-.18.13-.17.26.07.22.17.08v.09l-.39-.09-.14.17v.35l-.1.13-.07.13v.26l-.18.34-.17.22h-.97l-.18.21-.19.19-.28-.09v-.13h-.2l-.11.13-.1-.13-.28-.08h-.42l-.13.17h-.11l-.35-.17h-.86l-.21.22h-.56Z"/>
      <path class="cls-1" d="M246.38,31.76h-.14l-.21-.18-.21.09v-.13h-.11l-.17.13h-.55l-.18-.09-.14-.17-.1.22-.17.17-.21-.13-.21-.26h-.28l-.17-.12-.11.08-.1.13-.1-.17-.25-.13-.07-.35.18-.78.21-1.3.1-.82.28-1.52.24-1.39.14-.86.14-.95.21-1.3.24-1.39.28-1.69.17-1,.28-1.9.17-1,.28-1.91.17-1.25.21-1,.31-1.56.28-1.47.31-1.48.24-1.3.35-1.21.21-.74.17-.78.18-.52V1.52h.14l.14-.13.35.13.27.09.18-.18.31.09.21.13.17-.09.28.09h.27l.28.08.35-.13h.28l.14.08.31-.13H253l.32.13.17.09.17-.17.14-.05h.17l.28.82.24.78.32,1.09.34,1.34.28,1.08.17.74.28,1.34.18.83.1.47.24,1,.21,1,.21.82.14.82.24.87.17.82.35,1.61.14.82.31,1.73.24,1.3v.35l.14.69.38,2,.21,1.13.07.56.07.65.14,1,.17.87v.56l.11.52v.44l-.21.26-.24.13-.32.21-.13.13h-.21l-.25.13-.17.22h-.14l-.34.26h-.11l-.14.12h-.14l-.1.3-.21.18-.21-.13h-.17l-.28.43-.01007-.16-.14-.13-.49.26-.48.13-.21-.31-.21-1.08-.28-1.26-.27-1.42-.28-1.35-.52-.08h-.66l-.59-.05-1.94.05-.59-.05-.52.09-.07.26-.11.52-.1.39-.17.95-.14.83-.18.82-.24,1-.24.22h-.24l-.25.22h-.27l-.11.09h-.17l-.21-.22-.14.31h-.07Zm6.28-11.87-.07-.83-.21-1.17-.11-.56-.06-.35-.21-.86-.14-.87-.18-.78-.17-.87-.1-.39-.07-.08-.07.13-.14.86-.17,1-.18,1.08-.24,1.69-.14.78-.14,1.09.38.08h.73l1,.09Z"/>
      <path class="cls-1" d="M263.41,32.68h-.45l-.38-.13-.7-.09-.55-.13-.38-.26-.45-.17-.07-.18-.11-.04v-.26l-.11-.44.07-.73v-4.6l-.07-1.77v-3.6l.07-1.78.1-1.6.07-.87.07-1.08V13.78l.07-1V11.53l.07-1.09V7.37l.07-1.52-.07-1.65.07-1.38V1.95l.25-.17h1.38l.35-.09h3.09l.24-.13.38-.09.66-.08h1.66l1.25.17,1.38.57,1,.82.56.56.52.74.62,1,.42,1.34.28,1.26v.73l.11.83v1.17l-.14,1.17-.34,1.17-.25.95-.31.56.17.48.46.65.2.43.11.44.59,1.17.55,1.64.35,1.35.07.78.1.73v1.43l-.07,1.34-.07.61-.1.39.11993.81-.24.61-.25.65-.52,1-.41.86-.07.09h-.11l-.24.26-.31.26-.17.34-.39.44-.31.39h-.24l-.17.09-.35.22-.31.13-.52.26-.45.26-.07-.09v-.17l-.55.13-.94.17-1.18.17-1.46.05h-1.87l-.59-.05-.73.13Zm4-6.41h.17l.25-.26.41-.13h.35l.45-.17.9-.3.52-.61.25-.56.34-1.09.11-1.17v-.6l-.07-.22-.18-.65-.31-.43-.21-.26-.45-.31-.34-.17-.46-.09-.55-.21-.9-.13h-1.15l-.66.17-.07,1v5.89h.35v.18h.62Zm-.25-13.59,1-.13.28-.17.41-.17.42-.26.21-.48.14-.61v-.72l-.17-.44-.21-.43-.31-.48-.28-.3-.35-.09h-1.66l-.25.09-.06.6v2.43l-.07.78v.39l.34.05Z"/>
      <path class="cls-1" d="M285.09,33.68l-.9-.35-.73-.39-.73-.52-.93-.78-.59-.56-.66-.78-.56-.74-.41-.78-.49-.86-.38-.92-.21-.6-.45-1.17-.24-.87-.35-1.82-.14-1.61V17.98l.07-.74v-.6l.1-1.22.1-.69.14-.87.21-.73.17-1.09.18-.73.21-.48.07-.43.1-.48.14-.48.07-.3.17-.35.17-.65.21-.52.14-.47.35-.52.17-.7.42-.52.34-.69.63-.95.73-.83.76-.69.56-.48,1-.56.9-.43,1.63-.48.63-.13,2-.17h.52l.73.13.73.26.66.35,1.25.82,1,1,.83,1.13L296.32,5.28l.5,1.4.41,1.43.21,1.13.11.82.13,1.13v.78l.07,1v4.42l-.07,1.17-.11,1-.1.69-.1.13-.18.83-.17,1.08v.43l-.17.35.07.39-.1.3-.14.74-.11,1.18-.34,1.38-.31,1.13-.07.39-.18.26-.24.52-.28.48-.17.43-.28.17-.69.87-1.08.95-.62.35-.77.35-.76.21-.62.13-.59.22h-2.33l-.79.21Zm3.09-6.76.52-.13h.21l.21-.09.14-.17.34-.17.42-.22.14-.18.24-.34.28-.48.31-.52.21-.86.21-.7.07-.43.1-.3v-.45l.14-.48.14-.26v-.52l.07-.3.17-.3-.1-.35.17-.39v-.35l.07-.56v-.78l.1-.61v-.43l.07-1V13.29l-.07-.78V11.47l-.17-.74-.39-1.3-.34-.69-.8-1.08-.66-.48-.8-.26-1.28.22-.56.21h-.38l-.17.09-.47.24-.45.43-.28.26-.07.26-.31.3-.45.83-.28.56-.16.36-.17.52-.07.43-.14.39-.14.74-.14.6-.07.39-.14.61-.1,1-.14,1.52-.07,1.3.1,1.08.07,1,.14,1.17.21.82.1.48.25.56.52,1,1.11,1.21,1.11.83.55.3.14.09-.07.17.14.08Zm-.42.48-.1-.13h-.14l-.07.08h.13l.14.12Zm.49,0v-.09h-.1v.09Zm.45-.26v-.05l-.11.18Zm3.33-5.46v-.13l.07-.3v-.31h0l-.07.22v.26l-.07.35h.07Z"/>
      <path class="cls-1" d="M311.32,32.23l-.45-1-.8-1.39-.45-.74-.9-1.56-.83-1.38-.59-1-.38-.43-.45-.65-1.49-2.21-.35-.48h-.14v2.6l-.17,1.49v1l-.07.52v3.99l-.24.56-.35.13-.34-.1-.32.13-.1.09-.28-.09-.35.26-.17-.12h-.07l-.17.12h-.31l-.25.13-.2-.09-.21.13-.14-.08-.14.08-.49.09-.38-.09H299.32l-.14-.08v-8.7l.07-.57V16.2l.07-1.08V7.45l.07-.69-.07-.35V3.16l-.06-.78V1.86l.07-.09h1.33l.07-.08h.63l.17-.13h1.46l.17-.13h.63l.2.09h1.53l.42-.13.34.13h.32l1.24.08.35.09.49.09.41.17h.25l.24.22.9.39.8.61.48.52.35.56.35.87.35,1.17.27,1.08.18.65.1.35.07.69.21.95.07.48-.07.65.14.61v.17l-.1.48v.56l-.14.3-.14.83-.31.95-.18.48-.65,1.16-.14.3-.49.57-.35.52-.31.3-.48.43-.32.35.15.53.17.39.49.69.83,1.26.83,1.43.63,1,.52,1,.83,1.43.59,1.13.31.77.28.66-.25.25-.27.31-.21.13h-.1l-.07.21-.11.22h-.31l-.17.43h-.21v.13l-.2.13h-.25l-.1.26h-.11l-.21.13-.1.22h-.14l-.17.18-.21.21-.14.09-.11.09h-.13v.13l-.17.08h-.38Zm-5.9-17.55h.66l.87-.08.21-.18.45-.34.55-.57.42-.52.24-.69v-.82l-.14-1.22-.42-1.3-.42-.34-.38-.18-.69-.3-.63-.09h-1.49l-.07.57V14.6l.11.13Z"/>
      <path class="cls-1" d="M318.32,31.76h-.14l-.21-.18-.2.09v-.13h-.1l-.18.13h-.55l-.17-.09-.14-.17-.11.22-.17.17-.21-.13-.21-.26h-.27l-.18-.12-.1.08-.11.13-.1-.17-.24-.13-.07-.35.17-.78.21-1.3.1-.82.28-1.52.24-1.39.14-.86.14-.95.21-1.3.24-1.39.28-1.69.17-1,.28-1.9.17-1,.28-1.91.18-1.25.2-1,.32-1.56.27-1.47.32-1.48.24-1.3.35-1.21.2-.74.18-.78.17-.52V1.52h.14l.14-.13.35.13.28.09.17-.18.31.09.21.13.17-.09.28.09h.28l.28.08.34-.13h.28l.14.08.31-.13H324.88l.31-.09.17.09.18-.17.14-.05h.17l.28.82.24.78.31,1.09.35,1.34.29,1.22.17.74.28,1.34.17.83.1.47.25,1,.2,1,.21.82.14.82.24.87.18.82.34,1.61.14.82.32,1.73.24,1.3v.35l.14.69.38,2,.21,1.13.07.56.07.65.14,1,.17.87v.56l.1.52v.44l-.21.26-.24.13-.31.21-.14.13h-.21l-.24.13-.17.22h-.14l-.35.26h-.1l-.14.12h-.14l-.11.3-.2.18-.21-.13h-.18l-.17007.35-.11-.08-.14-.13-.48.26-.49.13-.21-.31-.2-1.08-.28-1.26-.28-1.42-.28-1.35-.52-.08h-.66l-.59-.05-1.94.05-.59-.05-.52.09-.07.26-.1.52-.11.39-.17.95-.14.83-.17.82-.24,1-.25.22h-.24l-.22.29h-.28l-.1.09h-.18l-.21-.22-.13.31h-.07Zm6.28-11.87-.07-.83-.21-1.17-.1-.56-.07-.35-.21-.86-.14-.87-.17-.78-.17-.87-.11-.39-.07-.08-.07.13-.14.86-.17,1-.17,1.08-.25,1.69-.15991.79-.14,1.09.38.08h.76l1,.09Z"/>
      <path class="cls-1" d="M334.84,32.06l-.28-.13V28.98l.07-.39-.07-.6V22.18l-.24-1.82V17.81l.07-3.38V11.22l-.07-1.26v-1l-.07-.69H332.1l-1.7.17-1,.05h-.52l-.27-.17.07-.22V7.62l.1-.47-.1-.22V6.67l.17-.35-.1-.26.07-.34V4.68l.1-.47V3.86l.07-.35.1-1v-.3l.38-.39.62-.09.63.13h1.18l.38.09.52-.09h.45l.35-.08h5.06l1.18-.17,1-.13h4.88l.42.05.07.52-.11.52.07.39-.07.43.07.3-.1.22-.07.3-.1.13.06.22v.35l.07.17v.26l-.17.17-.1.22.24.39-.24.35-.11.34v.26l.14.18-.25.26-.21.34h-.72l-.35.22-.35.08h-3.33l-.38.18-.42.3v.82l.07,1.39V23.48l-.07,1v3.68l-.07,1.22v1.95l-.21.17-.45.13-.31.39h-.14l-.1-.35-.18.13-.1.09-.14-.09-.14.3v-.17h-.14l-.07.13-.17.09-.21-.09h-.14l-.14-.13-.13.09-.18-.05-.24.05h-.24l-.18-.09-.1.09h-.17l-.11.17Z"/>
      <path class="cls-1" d="M348.16,33.1l-.07-.56-.38-.09h-.17l-.18-.3-.17-.52-.07-.56-.17-.3v-.79l.11-.22v-.21l.13-.35v-.65l-.1-.3v-3.6l.1-.43-.06-.87v-5.5l-.11-.35v-.39l-.07-.39v-1.9l.13-2.26.07-.73V2.08l.18-.35.21-.09.24.09h2.11l.14.09.29-.14h.41l.25-.13.17.13.14-.09.28-.08.31.17h.44l.24.09V8.1l-.07.3v.13h.1v.26l-.17.09h-.07l.07.22.07.3.07.26V11l-.07.44-.07.21-.1.05-.07.21v.65h.11l.07.08v.57h-.1v.86l.1.09v.17l-.1.18v1.34l.14.13-.07.17v.74l.14.82.06.91v5.9l-.07.74v3.16l-.03,1.26v.44l-.18.56v.44l-.17.26-.25.21h-.21l-.24.17-.07.26-.14.05-.17-.09-.17.13h-.18l-.17.08-.14-.13h-.1l-.11.26-.07.39v-.22l-.1-.43h-.14l-.1.17-.28.35v.17h0l-.07-.17-.17-.13-.14.17-.07.52v.35h0l-.07-.26v-.34l-.07-.13-.14.08-.17.09-.07.26-.21982.49-.07.39h-.07Zm4.23-28.6V4.42l-.07.08v.09Zm.14,6.11v-.17h-.07v.09l.07.09Z"/>
      <path class="cls-1" d="M361.97,33.68l-.9-.35-.73-.39-.73-.52-.94-.78-.59-.56-.66-.78-.55-.74-.42-.78-.48-.86-.39-.92-.2-.6-.45-1.17-.25-.87L354.32,22.57l-.18-1.64V17.98l.07-.74v-.6l.11-1.22.1-.69.14-.87.21-.73.17-1.09.17-.73.21-.48.07-.43.1-.48.14-.48.07-.3.18-.35.17-.65.21-.52.14-.47.34-.52.18-.7.41-.52.35-.69.62-.95.73-.83.77-.69.55-.48,1-.56.9-.43,1.63-.48.62-.13,2-.17h.52l.72.13.73.26.66.35,1.25.82,1,1,.83,1.13.87,1.51.52,1.43.42,1.43.21,1.13.1.82.14,1.13v.78l.07,1v4.42l-.07,1.17-.1,1-.1.69-.11.13-.17.83-.17,1.08v.43l-.17.35.07.39-.11.3-.14.74-.02,1.23-.35,1.38-.31,1.13-.07.39-.17.26-.25.52-.27.48-.18.43-.27.17-.7.87-1.07.95-.63.35-.76.35-.76.21-.63.13-.59.22h-2.32l-.8.21Zm3.09-6.76.52-.13h.2l.21-.09.14-.17.35-.17.41-.22.14-.18.29-.28.27-.48.31-.52.21-.86.21-.7,0-.44.1-.3v-.5l.14-.48.14-.26v-.52l.07-.3.18-.3-.11-.35.17-.39v-.35l.07-.56v-.78l.11-.61v-.43l.07-1V13.29l-.07-.78V11.47l-.17-.74-.38-1.3-.35-.69-.97-1.06-.65-.48-.8-.26-1.29.22-.55.21h-.38l-.18.09-.34.22-.45.43-.28.26-.07.26-.31.3-.45.83-.28.56-.11.35-.17.52-.07.43-.14.39-.14.74-.13.6-.07.39-.14.61-.11,1-.14,1.52-.06,1.3.1,1.08.07,1,.14,1.17.21.82.1.48.24.56.52,1,1.11,1.21,1.11.83.56.3.14.09-.07.17.14.08Zm-.42.48-.11-.13h-.13l-.07.08h.14l.14.12Zm.48,0v-.09h-.1v.09Zm.46-.26v-.05l-.1.18Zm3.33-5.46v-.13l.07-.3v-.31h0l-.07.22v.26l-.07.35h.07Z"/>
      <path class="cls-1" d="M390.83,32.68l-.14-.57h-.21l-.34.17-.21-.08-.14.13-.17-.09-.28-.3-.45-.91-.57-1.44-.48-1.34-.38-.82-.35-.92-.7-1.6-.65-1.47-.73-1.82-.56-1.26-.38-.78-.77-1.64-.59-1.48-.52-1.17-.35-.69-.38-.74v-.08h-.16v4.81l-.1,1.09V25.4l-.14.21.07.26v.35l-.07.26.07.35v.21l-.07.35-.28.13v.21l.1.18.07.13h.1l.07-.05h0v.26l-.21.09-.24.09-.1.3.1.22h0l.07.13v.08l-.07.35v.17l.11-.09.1.09-.07.22-.1.21.07.44v.6h.14l.1.35v.35l-.17.08-.21.13h-.31l-.38.13h-.28l-.21.09h-.28l-.2.09h-.14l-.28.17-.28.26-.24-.22h-.1l-.14.35h-.07l-.14-.09-.1.09h-.8l-.18.09-.17-.09-.24-.25v-.61l.18-.48.24-.13.14-.12V31.2l-.1-.17h-.31l-.18-.47V29.13l-.07-.52v-.74l.07-1-.07-1,.07-1V21.28l.07-1.87-.07-1.73V8.71l.07-1.17.07-.69V5.33l.07-.74.1-1.51V2.17l.17-.09.35-.09.35.09h.83l.17-.09.42.18.14-.13h.59l.27.09h.28l.14-.13h.35l.13-.08h.87l.21-.13.38.35.56.95.76,1.43.8,1.6.34.78.39.74.72,1.56.35.74.83,1.69.66,1.51.45,1.17.66,1.35.35.73.42.83h.07V14.93l-.07-.35v-.56l-.1-.35.17-.87-.1-.43v-.48l.07-.56V3.53l.1-.95V2.19l.34-.13h1.67l.21.09.21-.09h.31l.28-.13h.31l.48-.09h1.18l.21.08.17.05V4.44l-.07,1.56V7.25l-.07,1.56v.87l-.07,1.47v1.78l-.07,1.25v7.46l.06,1.25v6.33l-.11,1.35v1.08l-.1.61-.21.08-.24.05-.32-.05-.2.18h-.11l-.17-.08-.14-.09-.1.17-.07.09h0l-.28-.13-.27.34h-.18l-.17-.26h-.17l-.14.26h-.18l-.07-.22h0l-.07-.05-.1.22-.07.48-.07.34h-.11Z"/>
      <path class="cls-1" d="M409.18,32.06l-.28-.13V28.98l.06-.39-.06-.6V22.18l-.07-1.82V17.81l.07-3.38V11.22l-.07-1.26v-1l-.07-.69h-2.3l-1.7.17-1,.05h-.52l-.28-.17.07-.22V7.62l.1-.47-.1-.22V6.67l.18-.35-.11-.26.07-.34V4.68l.11-.47V3.86l.07-.35.1-1v-.3l.38-.39.63-.09.62.13h1.18l.38.09.52-.09h.45l.35-.08h5.06006l1.18-.17,1-.13h4.88l.42.05.07.52-.1.52.07.39-.07.43.07.3-.11.22-.07.3-.1.13.07.22v.35l.07.17v.26l-.18.17-.1.22.24.39-.24.35-.11.34v.26l.14.18-.25.26-.2.34h-.73l-.35.22-.35.08h-3.33l-.38.18-.41.3v.82l.07,1.39V23.48l-.07,1v3.68l-.07,1.22v1.95l-.2.17-.46.13-.31.39h-.14l-.1-.35-.17.13-.11.09-.14-.09-.14.3v-.17h-.13l-.07.13-.18.09-.21-.09h-.13l-.14-.13-.14.09-.17-.05-.25.05h-.24l-.17-.09-.11.09h-.17l-.11.17Z"/>
      <path class="cls-1" d="M426.98,33.68l-.9-.35-.73-.39-.73-.52-.94-.78-.59-.56-.66-.78-.55-.74-.42-.78-.48-.86L420.6,27l-.21-.6-.45-1.17-.25-.87-.37-1.79-.18-1.64V17.98l.07-.74v-.6l.11-1.22.1-.69.14-.87.21-.73.17-1.09.17-.73.21-.48.07-.43.11-.48.13-.48.07-.3.18-.35.17-.65.21-.52.14-.47.34-.52.18-.7.41-.52.35-.69.63-.95.72-.83.77-.69.55-.48,1-.56.9-.43L428.88.3l.62-.13,2-.17h.52l.72.13.73.26.66.35,1.25.82,1,1,.84,1.13.86,1.51.52,1.43.42,1.43.21,1.13.1.82.14,1.13v.78l.07,1v4.42l-.07,1.17-.1,1-.1.69-.11.13-.17.83-.17,1.08v.43l-.17.35.07.39-.11.3-.14.74-.01,1.23-.35,1.38-.31,1.13-.07.39-.17.26-.24.52-.28.48-.18.43-.27.17-.7.87-1.07.95-.63.35-.76.35-.76.21-.63.13-.59.22h-2.32l-.8.21Zm3.09-6.76.52-.13h.2l.21-.09.14-.17.35-.17.41-.22.14-.18.28-.28.27-.48.31-.52.21-.86.21-.7,0-.44.1-.3v-.5l.14-.48.14-.26v-.52l.07-.3.18-.3-.11-.35.18-.39v-.35l.07-.56v-.78l.11-.61v-.43l.07-1V13.29l-.07-.78V11.47l-.17-.74-.38-1.3-.35-.69-.98-1.06-.65-.48-.8-.26-1.29.22-.55.21h-.38l-.18.09-.34.22-.45.43-.28.26-.07.26-.31.3-.45.83-.28.56-.11.35-.17.52-.07.43-.14.39-.14.74-.13.6-.07.39-.14.61-.11,1-.14,1.52-.06,1.3.1,1.08.07,1,.14,1.17.21.82.1.48.24.56.52,1,1.11,1.21,1.11.83.56.3.14.09-.07.17.14.08Zm-.42.48-.1-.13h-.14l-.07.08h.14l.14.12Zm.48,0v-.09h-.1v.09Zm.46-.26v-.05l-.1.18Zm3.33-5.46v-.13l.07-.3v-.31h0l-.07.22v.26l-.07.35h.07Z"/>
      <path class="cls-1" d="M450.12,31.76h-.14l-.21-.18-.21.09v-.13h-.11l-.17.13h-.55l-.18-.09-.14-.17-.09.27-.17.17-.21-.13-.21-.26h-.28l-.17-.12-.11.08-.1.13-.1-.17-.25-.13-.07-.35.18-.78.21-1.3.1-.82.28-1.52.24-1.39.14-.86.14-.95.21-1.3.17-1.4.28-1.69.17-1,.28-1.9.17-1,.28-1.91.17-1.25.21-1,.31-1.56.28-1.47.31-1.48.24-1.3.35-1.21.21-.74.17-.78.18-.52V1.56h.14l.14-.13.35.13.27.09.18-.18.31.09.21.13.17-.09.28.09h.27l.28.08.35-.13h.28l.14.08.31-.13h1.07l.32-.09.17.09.17-.17.14-.05h.17l.28.82.24.78.32,1.09.34,1.34.28,1.08.17.74L459.62,8.68l.18.83.1.47.24,1,.21,1,.21.82.14.82.24.87.17.82.35,1.61.14.82.31,1.73.24,1.3v.35l.14.69.38,2,.21,1.13.07.56.07.65.14,1,.17.87v.56l.11.52v.44l-.21.26-.24.13-.32.21-.13.13h-.21l-.25.13-.17.22h-.14l-.34.26h-.11l-.14.12h-.14l-.1.3-.21.18-.21-.13h-.17l-.28.43-.1-.08-.14-.13-.49.26-.48.13-.21-.31-.21-1.08-.28-1.26-.27-1.42-.28-1.35-.52-.08h-.66l-.59-.05-1.94.05-.59-.05-.52.09-.07.26-.11.52-.1.39-.17.95-.14.83-.18.82-.24,1-.24.22h-.24l-.25.22h-.27l-.11.09h-.17l-.21-.22-.14.31h-.07Zm6.28-11.87-.07-.83-.21-1.17-.11-.56-.06-.35-.21-.86-.14-.87-.17-.78-.18-.87-.1-.39-.07-.08-.07.13-.14.86-.17,1-.18,1.08-.2,1.69-.14.78-.14,1.09.38.08h.73l1,.09Z"/>
      <path class="cls-1" d="M465.87,32.62v-.26h-.32l-.38-.13h-.69l-.35-.13-.07-.56.07-.52.19-.34v-.22l-.07-.08.07-.48-.11-.52V28.6l.1-.21-.07-.31v-.43l-.07-.44v-.17l-.1-.52V9.18l.11-2.08.14-1.73V2.38l.14-.48.55-.13h.87l.38-.13h.32l.07.09h.41l.24-.08h.32l.55-.13h.28l.21-.09h.21l.27.05h.14l.66-.09h.59l2.08.09h.56l.93.13.87.26.8.39.55.42.52.48.62.56.31.39.42.44.63.6.41.52.42.65.52.91.55,1.22.52,1.3.28,1.17.21,1,.21,1.17.17,1.08.07,1,.14,1.08.07,1.3v1.47l-.1.87-.11,1-.17,1-.14.74-.27,1.17-.49,1.34-.31.82-.18.48-.34.65-.28.83-.52.77-.38.57-.21.43-.21.18-.28.3-.2.39-.39.34-.52.44-.2.08-.35.22-.28.22h-.24l-.38.3-.59.18-.8.21-.8.13-.69.09h-.35l.14-.21.14-.31-.14-.13H472.12l-.14.26.07.26v.13H470.9v-.08h.55l-.28-.05h-.31v-.13l.07-.09h.14l.35.09.24-.13h-.38l-.45-.05-.28.09-.94-.09h-.24l-.28.09h-.52l-.38-.13-.21.13-.38-.05-.48.05-.25-.05-.21-.13v-.13l-.28.26-.34.13v.61h-.07Zm5.23-5.94.66-.09,1.11-.17.87-.44.9-.47.59-.56.35-.48.28-.52.31-.69.45-1.65.42-1.86.1-1.78V16.89l-.07-.48v-1l-.17-1.3-.11-.57-.1-.43-.38-1.21-.87-1.39-1.77-1.82-.45-.26h-.31l-.31-.13-.87-.13-1.28-.13h-.77l-.1.09v9.84l-.07.26v3.04l-.11.21v2.21l.07.39v.22l-.07.39v.86l.14.74v.35h.07l.59.09Z"/>
      <path class="cls-1" d="M485.78,32.62v-.26h-.31l-.38-.13h-.7l-.34-.13-.07-.56.07-.52.13-.35v-.22l-.07-.08.07-.48-.1-.52v-.78l.1-.21-.06-.31v-.43l-.07-.44v-.17l-.11-.52V9.18l.1-2.08.14-1.73V2.38l.14-.48.56-.13h.86l.39-.13h.31l.07.09h.41l.25-.08h.31l.55-.13h.28l.21-.09h.21l.28.05h.13l.66-.09h.59l2.08.09h.56l.94.13.86.26.8.39.59.43.52.48.63.56.31.39.41.44.63.6.41.52.42.65.52.91.56,1.22.52,1.3.27,1.17.21,1,.21,1.17.17,1.08.07,1,.14,1.08.07,1.3v1.47l-.11.87-.1,1-.18,1-.13.74-.28,1.17-.49,1.34-.31.82-.17.48-.35.65-.28.83-.52.77-.38.57-.21.43-.21.18-.27.3-.21.39-.38.34-.52.44-.21.08-.35.22-.28.22h-.24l-.38.3-.59.18-.8.21-.8.13-.69.09h-.35l.14-.21.14-.31-.14-.13H492.11l-.14.26.07.26v.13h-1.15V32.7h.55l-.27-.05h-.32v-.13l.07-.09h.14l.35.09.24-.13h-.38l-.45-.05-.28.09-.93-.09h-.29l-.27.09h-.52l-.39-.13-.2.13-.39-.05-.48.05-.24-.05-.21-.13v-.13l-.27.26-.35.13v.61h-.07Zm5.24-5.94.66-.09,1.11-.17.86-.44.91-.47.59-.56.34-.48.28-.52.31-.69.45-1.65.42-1.86.1-1.78V16.89l-.07-.48v-1l-.17-1.3-.1-.57-.11-.43-.38-1.21-.9-1.33-1.76-1.82-.46-.26h-.31l-.31-.13-.87-.13-1.29-.13h-.76l-.11.09v9.84l-.07.26v3.04l-.1.21v2.21l.07.39v.22l-.07.39v.86l.14.74v.35h.06l.59.09Z"/>
      <path class="cls-1" d="M515.82,32.23l-.45-1-.8-1.39-.45-.74-.9-1.56-.83-1.38-.59-1-.38-.43-.45-.65-1.5-2.21-.34-.48h-.14v2.6l-.07,1.48v1l-.07.52v3.99l-.24.56-.35.13-.28-.09-.31.13-.1.09-.28-.09-.35.26-.17-.12h-.07l-.17.12h-.32l-.24.13-.21-.09-.21.13-.13-.08-.14.08-.49.09-.38-.09h-.38l-.14-.08v-8.7l.07-.57V16.2l.07-1.08V7.45l.07-.69-.07-.35V3.16l-.07-.78V1.86l.07-.09h1.07007l.07-.08h.63l.17-.13h1.46l.17-.13H508.32l.21.09h1.53l.41-.13.35.13h.31l1.19.16.35.09.49.09.41.17h.24l.25.22.9.39.8.61.48.52.35.56.35.87.34,1.17.28,1.08.17.65.11.35.07.69.21.95.07.48-.07.65.13.61V12l-.1.48v.56l-.14.3-.14.83-.31.95-.18.48-.59,1.08-.14.3-.48.57-.35.52-.31.3-.49.43-.31.35.17.48.18.39.48.69.84,1.26.83,1.43.62,1,.52,1,.84,1.43.59,1.13.31.77.28.66-.25.25-.27.31-.21.13h-.11l-.07.21-.1.22h-.31l-.18.43h-.19995v.13l-.21.13h-.24l-.1.26h-.11l-.21.13-.1.22h-.14l-.18.18-.21.21-.13.09-.11.09h-.14v.13l-.17.08h-.39Zm-5.86-17.55h.66l.87-.08.2-.18.45-.34.56-.57.42-.52.24-.69v-.82l-.14-1.22-.41-1.3-.42-.34-.38-.18-.69-.3-.63-.09H509.2l-.07.57V14.6l.11.13Z"/>
      <path class="cls-1" d="M524.32,32.62h-1.21l-.35.13-.24-.13h-.35l-.55.09h-.21l-.31-.05-.18-.13h-.14l-.07-.35.11-.52v-.65l.07-.86v-3.6l.07-.26V23.01l.07-1.48V14.17l.1-1.49V11.03l-.07-1.51V8.68l.07-1.6.06-.78V2.16l.25-.08.14-.18.17.05.31-.05h2.74l.21-.13h.38l.63-.09h.59l.27-.09h.35l1,.09,1.66.08.49.05h.41l.49.13h4.58l.14.26.14.3.06.44.21.3.21,1.13-.1.52-.07.3v.22l.07.43.14.43.1.22-.21.17-.28.18-.31.56-.41.56-.25.26-.17.13h-.24l-.18-.13h-1.45l-.18.13h-6.41v4.89l.14.09h3.36l1.05.08h.38l.31.13h.94006l.55.18.14-.05h.31l.35.43.07.35v.43l.24.35-.13.35.13.3v.74l.14.3-.14.26-.06.35v.6l.13.13.11.13-.11.18H534.32l-.28.26-.24.52.1.26v.13l-.42.13-.24.34h-.59l-2.7-.08h-1.15l-1.53.09-.86.13-.28.21v.26l-.07.91v4.25h1.45l.35.09h1l.41.08.87.13H533.32l.45-.09h1.11l.42.09.14.26.1.35v.34l.2.22v.22l-.14.21.14.18.07.3-.14.09h-.1v.13l.17.17.07.31-.14.43-.07.57-.14.3.24.21.14.13-.07.09-.27.18-.32.25-.1.35v.44l-.69.43-.66-.05-.73.05h-2.6l-.28.08h-5.59l-.13.18h0Z"/>
      <path class="cls-1" d="M541.32,32.93l-.59-.22-.49-.3h-.45l-.45-.26-.73-.43-.45-.3-.28-.26-.45-.35-.31-.3-.31-.22-.25-.3-.34-.35-.28-.39v-.17l-.07-.22v-.22l.11-.17.21-.22.13-.21v-.22l.07-.13.25-.3h.1v-.27l.13-.17v-.17l.07-.13.28-.3.13-.44.18-.08.1-.39.14-.35.17-.13V24.7l.07-.09.1-.26h.28l.17.17.11.13h0l-.07-.09v-.17l.21-.13.69.56.87.74.66.48.62.12h.73l.9-.44.56-.43.48-1,.38-1.43v-.87l-.14-.35-.17-.47-.14-.52-.14-.22-.17-.26-.25-.17-.17-.22-.24-.39-.25-.35-.27-.3-.18-.26-.24-.22-1.08-1.12-.69-.91-.49-.7-1-1.43-.69-1.51-.21-.7-.21-1-.07-1.21V8.08l.14-.7.45-1.08.28-.56.24-.44.17-.3.49-.65.65021-.67.45-.48.55-.52.69-.56.9-.39.49-.26.76-.31,1.46-.21,1.63.08,1,.13.35.09.2.13v.17l-.11.05-.07.08.11.18.17.08v.35l-.11.22.14.6h-.14l.07.13v.57l.1.26V4.2h-.14v.17l.21.26-.11.13.11.13v.44l-.17.08V5.5l.1.13.21.13.1.13v.09h-.1l-.17.09v.69l-.1.17v.48l-.11.56-.1.18-.24-.22-.84-.3-.62-.13h-1.35l-.84.34-.24.26-.28.26-.31.44-.14,1,.48993.88.73.86.93,1.13.59.65.56.74,1,1.21,1,1.47.87,1.48.28.73.45,1.61.24,1.55.07,1-.07,1.09-.42,1.65-.34.91-.73,1.56-.63.86-.38.39-.24.39-1,1.08-.66.52-.72.48-.87.39-.7.26-.55.13-1.25.13Z"/>
      <path class="cls-1" d="M556.06,32.93l-.59-.22-.48-.3h-.45l-.45-.26-.77-.47-.45-.3-.28-.26-.45-.35-.31-.3-.32-.22-.24-.3-.35-.35-.27-.39v-.17l-.07-.22V28.6l.11-.17.2-.22.14-.21v-.22l.07-.13.25-.3h.1v-.27l.14-.17v-.17l.07-.13.27-.3.14-.44.18-.08.1-.39.14-.35.17-.13v-.26l.07-.09.1-.26h.28l.17.17.11.13h0l-.07-.09v-.17l.21-.13.69.56.87.74.66.48.62.12h.73l.9-.44.55-.43.49-1,.38-1.43v-.87l-.14-.35-.17-.47-.14-.52-.14-.22-.18-.26-.24-.17-.17-.22-.24-.39-.25-.35-.28-.3-.17-.26-.24-.22-1.08-1.12-.69-.91-.49-.7-1-1.43L553.32,12.48l-.21-.7-.21-1-.07-1.21V7.97l.14-.7.45-1.08.28-.56.24-.44.17-.3.49-.65.45-.56.45-.48.55-.52.7-.56.9-.39.49-.26.76-.31,1.46-.21,1.63.08,1,.13.34.09.21.13v.17l-.11.05-.07.08.11.18.17.08v.35l-.11.22.14.6h-.14l.07.13v.57l.1.26V4.2h-.14v.17l.2.26-.1.13.1.13v.44l-.17.08V5.5l.1.13.21.13.1.13v.09h-.1l-.17.09v.69l-.1.17v.48l-.11.56-.1.18-.25-.22L562.32,7.68l-.62-.13h-1.35l-.84.34-.24.26-.28.26-.31.44-.14,1,.42.87.73.86.93,1.13.59.65.56.74,1,1.21,1,1.47.87,1.48.27.73.46,1.61.24,1.55.07,1-.07,1.09-.42,1.65-.34.91-.73,1.56-.63.86-.38.39-.24.39-1,1.08-.66.52-.73.48-.86.39-.7.26-.55.13-1.25.13Z"/>
      <path class="cls-1" d="M54.45,72.02H53.24l-.35.13-.24-.13H52.3l-.56.09-.2-.05h-.32l-.17-.13h-.14l-.07-.35.11-.52v-.65l.07-.87V65.95l.07-.26V62.4l.07-1.47V53.56l-.07-1.52V50.4l-.07-1.52v-.82l.07-1.61.23-.77V41.56l.25-.09.13-.17h.49l.49-.05h2.25l.21-.13h.38l.62-.08h.59l.28-.08h.35l1,.08,1.67.09h.9l.49.14H66l.13.25.14.31.07.43.21.3.21,1.13-.11.52-.06.3v.22l.06.43.14.44.11.21-.21.18-.28.17-.31.56-.42.57-.24.26-.17.13h-.25l-.17-.13H63.39l-.17.13H56.77v4.9l.14.08h3.36l1,.09h.38l.32.13h.93l.56.17h.45l.35.44.07.34v.44l.24.34-.14.35.14.3V56l.14.3-.14.26-.07.35v.61l.14.13.11.13-.11.17H64.5l-.27.26-.25.52.11.26v.13l-.42.13-.24.35h-.59l-2.71-.09H58.99l-1.53.09-.86.13-.28.22v.26l-.07.91v4.24l.7.05h.76l.35.08h1l.42.09.87.13h3.26l.45-.09h1.11l.42.09.13.26.11.34v.35l.21.22v.21l-.14.22.14.17.07.31-.14.08-.25-.19v.13l.18.17.07.3-.14.44-.07.56-.14.3.24.22.14.13-.07.09-.28.17-.31.26-.1.35v.43l-.7.43H63.16l-1.53.05H60.55l-.27.09H54.69l-.14.17h0Z"/>
      <path class="cls-1" d="M68.71,72.19h-.14l-.42-.57V70.28l.14-.26v-1l.03-1.34V61.26l.06-1.08V51.87l.07-1.73V48.05l.07-1.69.17-1.73V42.64l.07-.78.24-.48h.52l.8-.09.38-.08h.9l.14-.12.18-.14.13.09.18.09L72.4,41h.28l.31-.18.07.13.07-.08.14-.09.14.13h.41l.35.09.07.13v.3l.42,1,.31.78.31,1,.17.52.21.52.28.82.21.65v.26l.14.22.38,1,.31,1,.07.3.17.39.25.65.24.74.14.39.21.48.27,1,.11.3.07.3h.14l.1-.26.07-.35.1-.26v.3l-.18.61-.13.35h.17l.1-.26.18-.35.1-.56.21-.57.17-.39.11-.43.24-.52.07-.39.07-.22.14-.21.1-.48.17-.61.14-.26.11-.69.2-.78.14-.26.18-.52.17-.74.54007-.22.07-.3.18-.35.38-1,.48-1.52.18-.61v-.34l.11-.09h.34l.32-.09h1.34l.52-.09.32.09h.13l.18-.26.24.09.1.08.11-.08.24-.13h.73l.21.08.14.22h.08v-.26h.07v1.17l.06.78v5.89l-.07.87v.91l-.06,1.86V71.15l-.14.09-.1-.17h0v.43l-.27.26-.49-.08h-.39l-.17.22-.14-.09h-.3l-.1.13v-.13l-.1-.13h-.1v.22h-.14l-.1-.18-.17-.17H85.5v.21l-.07.31h-.49l-.9.09-.28-.18h-.24l-.1-.43-.07-.43V55.81l-.07-.35-.31.65-.28.83-.31.86-.41,1.09-.21.52-.25.65-.27.73-.07.31-.14.17v.22l-.1.08-.07.26-.31.22-.17.09-.11-.09.11-.09h0l-.25-.09h-.48l-.32.08-.1.13-.42.22H77.7l-.52.09H76.8l-.48-.29.07-.57-.24-.73-.18-.35-.17-.56-.48-1.3-.27-.7-.21-.56-.14-.35-.07-.39-.21-.56-.17-.43h0v.47l-.11.09v-.17l-.07-.39-.1-.31h-.18v.65l-.07,1.17v6.37l-.33,1.2v4.29l-.11,1v1l-.07.21H73l-.14.34-.35.18-.27-.18-.49.35-.21-.17-.1.13-.14.13h-.31l-.28-.08-.17.17v.13h-.62l-.38.13-.28.08h-.24Zm8.53-10H77.1v.09Zm11-1.26v-.17h-.07v.17Zm0,4.07v-.3h-.07v.26Zm0,2.69V65.61h-.07v2.08Zm.07-9V58.2h0l-.07.26.07.3Zm0,4.85v-.43h-.07l-.07.08v.39Zm.14-16.86-.07-.31v.78Z"/>
      <path class="cls-1" d="M94.03,72.02H92.82l-.35.13-.24-.13h-.35l-.55.09-.21-.05h-.31l-.18-.13h-.14l-.07-.35.11-.52v-.65l.07-.87V65.95l.07-.26V62.4l.07-1.47V53.56l-.07-1.52V50.4l-.07-1.52v-.82l.07-1.61.06-.78V41.56l.25-.09.14-.17h.48l.49-.05h2.25l.21-.13h.38l.63-.08h.59l.27-.08h.35l1,.08,1.66.09h.9l.49.14h4.75l.14.25.14.31.07.43.2.3.21,1.13-.1.52-.07.3v.22l.07.43.14.44.1.21-.21.18-.28.17-.31.56-.41.57-.25.26-.17.13h-.24l-.18-.13h-1.45l-.18.13H96.32v4.9l.14.08h3.36l1,.09h.38l.31.13h.94l.55.17h.45l.35.44.07.34v.44l.25.34-.14.35.14.3V56l.13.3-.13.26-.07.35v.61l.14.13.1.13-.1.17h-.14l-.28.26-.24.52.1.26v.13l-.42.13-.24.35h-.59l-2.7-.09H98.53L97,59.6l-.86.13-.28.22v.26l-.07.91v4.24l.69.05h.76l.35.08h1l.41.09.87.13h3.26l.45-.09h1.11l.42.09.14.26.1.34v.35l.2.22v.21l-.14.22.14.17.07.31-.14.08-.16-.19v.13l.17.17.07.3-.14.44-.07.56-.14.3.24.22.14.13-.07.09-.27.17-.32.26-.1.35v.43l-.69.43h-1.39l-1.53.05h-1.07l-.28.09H94.28l-.13.17h0Z"/>
      <path class="cls-1" d="M119.77,71.68l-.45-1-.8-1.38-.45-.74-.9-1.56-.85-1.44-.59-1-.38-.44-.45-.65-1.5-2.21-.34-.47h-.14v2.6l-.07,1.47v1l-.06.52v3.98l-.24.57-.35.13-.28-.09-.31.13-.1.09-.28-.09-.35.26-.17-.13h-.07l-.17.13h-.32l-.24.13-.21-.09-.2.13-.14-.09-.14.09-.49.09-.38-.09h-.38l-.14-.09V62.78l.07-.56V55.6l.07-1.09V46.84l.07-.69-.07-.35V42.5l-.07-.78V41.2l.07-.08h1.07l.07-.09h.63l.17-.13h1.46l.17-.13h.62l.21.08h1.53l.42-.13.34.13h.31l1.25.09.35.09.49.08.41.17h.24l.25.22.9.38.8.61.48.52.35.56.35.87.34,1.17.28,1.08.17.65.11.35.07.69.21,1,.07.47-.07.65.13.61v.17l-.1.48v.56l-.14.31-.14.82-.31,1-.18.48-.59,1.08-.14.31-.48.56-.35.52-.31.3-.49.44-.31.34.18.48.17.39.48.69.84,1.26.83,1.43.62,1,.52.95.84,1.43.59,1.13.31.78.28.65-.25.26-.27.3-.21.13h-.11l-.07.22-.1.22h-.31l-.18.43h-.2v.13l-.21.13h-.24l-.1.26h-.11l-.21.13-.1.22h-.14l-.18.17-.2.22-.14.09-.11.08h-.14v.13l-.17.09h-.39Zm-5.86-17.6h.66l.87-.09.2-.17.46-.35.55-.56.42-.52.25-.71v-.82l-.13-1.21-.42-1.3-.42-.35-.38-.17-.69-.31-.63-.08h-1.49l-.07.56v5.98l.11.13Z"/>
      <path class="cls-1" d="M131.95,73.15l-.94-.22h-.34l-.49-.26-.31-.17-.31-.13-.49-.31-.38-.13-.18-.26h-.24l-.1-.13-.35-.35-.62-.73-.42-.57-.24-.13-.14-.26-.38-.6-.28-.7-.14-.39-.14-.3-.34-.52-.25-.87-.24-.65-.1-.39v-.26l-.07-.21-.1-.18-.07-.39-.11-.34-.07-.7-.2-1-.07-.91-.11-.44v-.91l-.28-1.51L123.32,57.11l.07-.52V55.03l.1-.56v-.78l.13-.52V52l-.07-.17.14-.31.07-.3-.11-.13.14-.35v-.65l.14-.43.11-.35.13-.6.07-.48.21-.69.21-1,.24-.34.28-.61.42-1,.17-.48.21-.17.76-.95.52-.53.56-.6.48-.39,1.22-.74.79-.26.14.05.38-.14.49-.13.31-.17.24.05.21-.09h.28l.28-.13h.38l.21-.09H134.32l1.07.13h.25l.27.13.87.22h.24l.45.13.39.18.72.39.66.47.84.69.31.31.07.21-.07.27-.14.38-.24.22-.14.22v.34l-.17.13-.14.13v.31l-.17.26-.21.17v.17h-.21l-.07.31.07.26h-.28l-.1.21-.17.18v.26l-.17.08-.11.18-.1.26-.14.17-.1.17-.18.13-.2.26h-.28l-.45-.39-.49-.39-.45-.26-1-.26h-.87l-.21-.08h-.18l-1,.17-.2.09-.14.21-.66.26-.73.57-.52.6-.52,1.3-.4,1.48-.18.91v.78l-.07.91-.07.82-.07.48.07,1,.07.74v.3l.07,1.26.17,1.08.14.83.14.69.24.91.28,1,.28.74.38.95.8.78.62.35.63.3.69.26h.9l.52-.08.35-.18.35-.34.31-.44V63.8l-.07-1.48-.1-1.3v-.86l-.07-.31h-.32l-2.49.35-1,.17h-.28v-.09l.07-.09v-.08l-.14-.26V59.2l.1-.48V56.16l-.07-.26.07-.95.07-.17-.07-.31.07-.39v-.13l.48-.26,1-.26,1.84-.08h3.6l.21.13.21-.17h.28l.34-.08h.25l.14-.09h.45l.31-.09h.28l.1.35.07,1v6.54l.07,1.39V64.5l-.14.86v.91l-.1.61-.24.17-.21.74-.25.61-.06.13-.14.34-.28.48-.28.43-.55.74-.8.82-.52.39-.18.22-.34.26-.42.3-.17.26h-.28l-.28.09h-.24l-.28.22-.41.08-.28-.08h-.76l-.39-.09-.38.09-.41-.09-.35.13h-.21v.08Z"/>
      <path class="cls-1" d="M146.21,72.02h-1.22l-.34.13-.25-.13h-.34l-.56.09-.21-.05h-.31l-.17-.13h-.14l-.07-.35.1-.52v-.65l.07-.87V65.95l.07-.26V62.4l.07-1.47V53.56l-.06-1.52V50.4l-.07-1.52v-.82l.07-1.61.07-.78V41.56l.24-.09.14-.17h.49l.48-.05h2.26l.21-.13h.38l.62-.08h.59l.28-.08h.35l1,.08,1.67.09h.9l.48.14h4.58l.14.25.14.31.07.43.21.3.21,1.13-.11.52-.07.3v.22l.07.43.14.44.11.21-.21.18-.28.17-.31.56-.42.57-.24.26-.17.13h-.25l-.17-.13h-1.46l-.17.13H148.4v4.9l.14.08h3.36l1,.09h.38l.31.13h1.04l.56.17h.45l.34.44.07.34v.44l.24.34-.14.35.14.3V56l.14.3-.14.26.03.12v.61l.14.13.1.13-.1.17h-.14l-.28.26-.24.52.11.26v.13l-.42.13-.24.35h-.59l-2.71-.09h-1.14l-1.53.09-.87.13-.27.22v.26l-.07.91v4.24l.7.05h.55l.35.08h1l.42.09.87.13h3.26l.45-.09h1.11l.41.09.14.26.11.34v.35l.21.22v.21l-.13.22.13.17.07.31-.14.08-.1.05v.13l.17.17.07.3-.14.44-.06.56-.14.3.24.22.14.13-.07.09-.28.17-.31.26-.1.35v.43l-.7.43h-1.39l-1.52.05h-1.08l-.28.09h-5.58l-.14.17h0Z"/>
      <path class="cls-1" d="M174.62,72.06l-.14-.56h-.21l-.35.17-.2-.09-.14.13-.18-.08-.27-.31-.45-.91-.56-1.38-.48-1.35-.39-.82-.34-.91-.7-1.6-.66-1.48-.72-1.82-.51-1.29-.38-.78-.83-1.65-.59-1.47-.57-1.18-.35-.7-.38-.73v-.09h-.07v4.81l-.07,1.08v5.72l-.14.22.07.26v.34l-.07.26.07.35v.22l-.07.34-.28.13v.22l.11.17.07.13h.17v.22l-.21.08-.24.09-.1.3.1.22h0l.06.13v.09l-.07.35v.26l.1-.09.11.09-.07.21-.11.22.07.43v.61h.14l.1.34v.35l-.17.09-.21.13h-.31l-.38.13h-.28l-.21.09h-.28l-.21.09h-.13l-.28.18-.28.26-.24-.22h-.11l-.13.34h-.07l-.14-.08-.11.08h-.79l-.18.08-.17-.08-.21-.18v-.6l.17-.48.25-.13.14-.13v-.13l-.1-.17h-.32l-.17-.48V68.67l-.07-.52v-.73l.07-1-.07-1,.07-1v-3.6l.07-1.86-.07-1.74V48.14l.07-1.17.07-.69V44.76l.07-.73.1-1.52V41.6l.17-.09.35-.08.34.08h.84l.17-.08.42.17.13-.13h.59l.28.09h.28l.14-.13h.34l.14-.09h.87l.21-.13.38.35.56,1,.76,1.43.8,1.61.34.78.38.73.85,1.57.35.74.83,1.69.66,1.52.45,1.17.66,1.34.35.74.4.8h.07v-2.3l-.07-.35v-.95l.18-.86-.11-.44v-.47l.07-.57v-7.8l.1-1v-.39l.34-.13h1.67l.21.08.2-.08h.32l.27-.13h.32l.48-.09.28.05.28-.05h.62l.21.09h.17v2.47l-.07,1.56v1.26l-.06,1.56V49l-.07,1.48v1.78l-.07,1.26v7.45l.07,1.26V68.6l-.1,1.34v1.09l-.1.6-.21.09h-.56l-.21.17h-.1l-.17-.09-.14-.08-.11.17-.06.09h0l-.65-.3-.27.35h-.18l-.17-.26-.17-.05-.14.26h-.18l-.07-.21v-.05h-.07l-.1.22-.07.47-.07.35h-.11Z"/>
      <path class="cls-1" d="M186.03,71.46l-.28-.13V68.38l.06-.39-.06-.61v-5.8l-.07-1.82V57.2l.07-3.38V50.61l-.07-1.25v-1l-.07-.7h-2.15l-1.7.18h-1.52l-.28-.17.07-.22v-.47l.1-.48-.1-.22v-.26l.18-.34-.11-.26.07-.35V43.99l.11-.48v-.35l.07-.34.1-1v-.31l.38-.38.63-.09.62.13h1.18l.38.08.52-.08h.45l.35-.09h5.06l1.18-.18,1-.13h5.3l.07.52-.1.52.07.39-.07.43.07.31-.11.21-.07.31-.1.13.07.21v.35l.07.17v.26l-.18.18-.1.22.24.38-.24.35-.11.35v.26l.14.17-.24.26-.21.35h-.73l-.35.22-.34.09h-3.34l-.38.17-.41.3v.83l.07,1.38V62.88l-.07,1v3.69l-.07,1.21v1.95l-.2.17-.46.13-.31.39h-.14l-.1-.34-.17.13-.11.08-.14-.08-.14.3v-.17h-.14l-.07.13-.18.08-.2-.08h-.4l-.14-.13-.14.08h-.66l-.17-.08-.11.08h-.17l-.1.17Z"/>
      <path class="cls-1" d="M205.67,72.19h-.14l-.42-.57V70.28l.14-.26v-1l.07-1.34V61.26l.07-1.08V51.87l.07-1.73V48.05l.07-1.69.17-1.73V42.64l.07-.78.24-.48h.52l.8-.09.38-.08h.9l.14-.12.18-.14.13.09.18.09.17-.13h.28l.31-.18.07.13.07-.08.14-.09.14.13h.41l.35.09.07.13v.3l.42,1,.31.78.31,1,.17.52.21.52.28.82.21.65v.26l.14.22.38,1,.31,1,.07.3.17.39.25.65.24.74.14.39.21.48.27,1,.11.3.07.3h.14l.1-.26.07-.35.1-.26v.3l-.18.61-.13.35h.17l.1-.26.18-.35.1-.56.21-.57.17-.39.11-.43.24-.52.07-.39.07-.22.14-.21.1-.48.17-.61.14-.26.11-.69.21-.78.13-.26.18-.52.17-.74.17-.39.07-.3.18-.35.38-1,.48-1.52.18-.61v-.34l.11-.09h.34l.32-.09H222.32l.52-.09.32.09h.14l.17-.26.24.09.11.08.1-.08.24-.13h.73l.21.08.14.22h.08v-.26h.07v1.17l.07.78v5.89l-.07.87v.91l-.07,1.86V71.15l-.14.09-.1-.17h0v.43l-.28.26-.48-.08h-.39l-.17.22-.14-.09h-.3l-.1.13v-.13l-.07-.13h-.1v.22h-.14l-.1-.18-.17-.17h-.11v.21l-.07.31h-.49l-.9.09-.28-.18h-.24l-.1-.43-.07-.43V55.81l-.07-.35-.31.65-.28.83-.31.86L219,58.89l-.21.52-.24.65-.28.73-.07.31-.14.17v.22l-.1.08-.07.26-.31.22-.17.09-.11-.09.11-.09h0l-.25-.09h-.48l-.31.08-.11.13-.42.22h-1.11l-.52.09h-.38l-.17-.26.07-.57-.24-.73-.18-.35-.17-.56-.49-1.3-.27-.7-.21-.56-.14-.35-.07-.39-.21-.56-.17-.43h0v.47l-.11.09v-.17l-.07-.39-.1-.31h-.18v.65l-.07,1.17v6.37l-.7299,1.17v4.29l-.11,1v1l-.07.21h-.21l-.14.34-.47.16-.27-.18-.49.35-.21-.17-.1.13-.14.13h-.31l-.28-.08-.17.17v.13h-.62l-.38.13-.28.08h-.24Zm8.53-10h-.14v.09Zm11-1.26v-.17h-.07v.17Zm0,4.07v-.3h-.07v.26Zm0,2.69V65.61h-.07v2.08Zm.07-9V58.2h0l-.07.26.07.3Zm0,4.85v-.43h-.07l-.07.08v.39Zm.14-16.86-.07-.31v.78Z"/>
      <path class="cls-1" d="M230.99,72.02h-1.21l-.35.13-.24-.13h-.35l-.55.09-.21-.05h-.31l-.18-.13h-.14l-.07-.35.11-.52v-.65l.07-.87V65.95l.07-.26V62.4l.07-1.47V53.56l-.07-1.52V50.4l-.07-1.52v-.82l.07-1.61.07-.78V41.56l.24-.09.14-.17h.48l.49-.05h2.25l.21-.13h.38l.63-.08h.59l.27-.08h.35l1,.08,1.66.09h.9l.49.14h4.58l.14.25.14.31.07.43.2.3.21,1.13-.1.52-.07.3v.22l.07.43.14.44.1.21-.21.18-.28.17-.31.56-.41.57-.25.26-.17.13h-.24l-.18-.13h-1.45l-.18.13h-6.26v4.9l.14.08h3.37l1,.09h.38l.31.13h.94l.55.17h.45l.35.44.07.34v.44l.25.34-.14.35.14.3V56l.13.3-.13.26-.07.35v.61l.14.13.1.13-.1.17h-.14l-.28.26-.24.52.1.26v.13l-.42.13-.24.35h-.66l-2.7-.09h-1.15l-1.52.09-.87.13-.28.22v.26l-.07.91v4.24l.69.05h.76l.35.08h1l.41.09.87.13h3.26l.45-.09h1.11l.42.09.14.26.1.34v.35l.2.22v.21l-.14.22.14.17.07.31-.14.08-.1-.19v.13l.17.17.07.3-.14.44-.07.56-.14.3.24.22.14.13-.07.09-.27.17-.32.26-.1.35v.43l-.69.43h-1.39l-1.53.05h-1.07l-.28.09h-5.59l-.13.17h0Z"/>
      <path class="cls-1" d="M259.4,72.06l-.14-.56h-.2l-.35.17-.21-.09-.18.1-.17-.08-.28-.31-.45-.91L256.87,69l-.45-1.32-.38-.82-.35-.91-.69-1.6-.68-1.52-.73-1.82-.55-1.25-.39-.78-.83-1.65-.59-1.47-.5-1.18-.35-.7-.38-.73v-.09h-.07v4.81l-.07,1.08v5.72l-.14.22.07.26v.34l-.07.26.07.35v.22l-.07.34-.28.13v.22l.1.17.07.13h.18v.22l-.21.08-.24.09-.11.3.11.22h0l.07.13v.09l-.06.35v.26l.1-.09.1.09-.07.21-.1.22.07.43v.61h.14l.11.34v.35l-.18.09-.2.13H249l-.38.13h-.28l-.2.09h-.28l-.21.09h-.14l-.28.18-.27.26-.25-.22h-.1l-.14.34h-.15l-.14-.08-.1.08h-.8l-.17.08-.18-.08-.2-.18v-.6l.17-.48.24-.13.14-.13v-.13l-.11-.17h-.31l-.17-.48V68.67l-.07-.52v-.73l.07-1-.07-1,.07-1v-3.6l.07-1.86-.07-1.74V48.14l.07-1.17.07-.69V44.76l.07-.73.11-1.52V41.6l.17-.09.35-.08.35.08h.83l.17-.08.42.17.14-.13h.59l.28.09h.27l.14-.13h.35l.14-.09h.87l.2-.13.39.35.55,1,.76,1.43.8,1.61.35.78.38.73.73,1.56.35.74.83,1.69.66,1.52.45,1.17.66,1.34.34.74.42.82h.07v-2.3l-.07-.35v-.56l-.1-.35.17-.86-.1-.44v-.47l.07-.57v-7.8l.11-1V41.6l.35-.13H260.52l.21.08.21-.08h.31l.28-.13h.31l.49-.09.27.05.28-.05h.63l.21.09h.17v2.47l-.07,1.56v1.26l-.07,1.56v.86l-.07,1.48v1.78l-.07,1.26v7.45l.07,1.26v6.37l-.1,1.34v1.09l-.11.6-.21.09h-.55l-.21.17h-.1l-.18-.09-.14-.08-.1.17-.07.09h0l-.28-.13-.28.35h-.17l-.18-.26-.68-.31-.14.26h-.17l-.07-.21v-.05h-.07l-.1.22-.07.47-.07.35h-.1Z"/>
      <path class="cls-1" d="M270.82,71.46l-.28-.13V68.38l.07-.39-.07-.61v-5.8l-.22-1.82V57.2l.07-3.38V50.61l-.07-1.25v-1l-.07-.7H268.1l-1.7.18h-1.52l-.28-.17.07-.22v-.47l.11-.48-.11-.22v-.26l.17-.34-.1-.26.07-.35V43.99l.1-.48v-.35l.07-.34.1-1v-.31l.39-.38.62-.09.63.13h1.17l.39.08.52-.08h.45l.34-.09h5.07l1.18-.18,1-.13h5.3l.07.52-.11.52.07.39-.07.43.07.31-.1.21-.07.31-.11.13.07.21v.35l.07.17v.26l-.17.18-.11.22.25.38-.25.35-.1.35v.26l.14.17-.24.26-.21.35h-.73l-.34.22-.35.09h-3.33l-.38.17-.42.3v.83l.07,1.38V62.88l-.07,1v3.69l-.07,1.21v1.95l-.21.17-.45.13-.31.39h-.14l-.11-.34-.17.13-.1.08-.14-.08-.14.3v-.17h-.14l-.07.13-.17.08-.21-.08h-.14l-.14-.13-.14.08h-.65l-.18-.08-.1.08h-.18l-.1.17Z"/>
      <path class="cls-1" d="M282.58,71.15h-.14l-.21-.17-.21.09v-.13h-.11l-.17.13h-.55l-.18-.09-.14-.17-.1.21-.17.18-.21-.13-.21-.26h-.28l-.17-.13-.11.09-.1.13-.1-.18-.25-.13-.07-.34.18-.78.21-1.3.1-.83.28-1.51.24-1.39.14-.87.14-1,.21-1.3.24-1.39.28-1.69.17-1,.28-1.91.17-1,.28-1.9.17-1.26.21-1,.31-1.56.28-1.47.33-1.41.24-1.3.35-1.22.21-.73.17-.78.18-.52v-.22h.14l.14-.13.35.13.27.09.18-.17.31.08.21.14.17-.09.28.09h.27l.28.09.35-.13h.28l.14.09.31-.13h1.07l.32-.09.17.09.17-.18h.31l.28.83.24.78.32,1.08.34,1.34.28,1.09.17.73.28,1.35.18.82.1.48.24,1,.21,1,.21.82.14.83.24.86.17.83.35,1.6.14.82.31,1.74.24,1.3v.34l.14.7.38,2,.21,1.13.07.56.07.65.14,1,.17.87v.56l.11.52v.43l-.21.26-.24.13-.32.22-.13.13h-.21l-.25.13-.17.22h-.14l-.34.26h-.11l-.14.13h-.14l-.1.3-.21.17-.21-.13h-.17l-.28.43-.1-.09-.14-.13-.49.26-.48.13-.21-.3-.21-1.08L290.69,68.68l-.27-1.43-.28-1.34-.52-.09h-3.78l-.52.08-.07.26-.11.52-.1.39-.17,1-.14.82-.18.82-.24,1-.24.22h-.24l-.25.22h-.26l-.11.08h-.17l-.21-.21-.14.3h-.07Zm6.28-11.87-.07-.82-.21-1.17-.11-.57-.06-.34-.21-.87-.14-.87-.17-.78-.18-.86-.1-.39-.07-.09-.07.13-.14.87-.17,1-.18,1.09-.24,1.69-.14.78-.14,1.08.38.09h.73l1,.08Z"/>
      <path class="cls-1" d="M304.26,71.02h-7.35l-.42-.21-.11-.39v-1l.1-.3V55.85l.07-.52V53.64l.07-1.48.1-1.82.07-1.51V46.27l.07-1.21-.07-.74V41.95l.07-.35v-.69l.11-.48h1.21l.1.09.11-.09.21.09h.21l.13-.08.21.17h.07l.11-.08h.27l.25-.17h.34l.35-.09h.24l.14-.08h.28l.07.08h.59l.1.17.14-.08.07.12v1.65l.07.43v2.47l-.07,1h-.13l-.11.48V48.5l-.07,1,.25,1.18v3.43l-.07,1.12v2.99l-.07,1.09-.07.82V61l-.11.21.07.44-.07.65v.52l-.14.39.07.52v.39l.17.3h1.98l1,.09.66.08h.69l.28.09h.42l.4,0h2.74v1.34l-.14.39.14.09.11.13v.05l-.11.26v.21l-.07.09v.44l-.14.22.14.09.24.08.14.18-.17.13-.17.26.07.21.17.09v.09l-.1.08-.14.18v.34l-.1.13-.07.13v.26l-.17.35-.17.22h-.98l-.17.22-.28.13-.27-.09v-.13h-.21l-.1.13-.11-.13-.27-.09h-.42l-.14.17h-.1l-.35-.17h-.87l-.2.21h-.56Z"/>
      <path class="cls-1" d="M332.6,71.76V71.5l-.07-.13h-.07v.13l-.07.22-.2-.13-.11-.26v-.35l-.11-.3v-.3l-.07-.26h-.14v.3l-.1.18-.1-.09h-.11l.07.57v.52l-.18-.39V69.73l-.1-.39.1-.47.07.08v.44l.28.34h.27l.07-.17v-.22h-.35V68.03l.07-.78v-.09l.1.26.1.39-.1.13v.18l.1.08.11-.47.1-.7-.14-.17h-.17l-.1.22h-.07l-.07-.52V62.68l-.07-.43v-3.1l-.21-.17-.14-.26-.07.13-.14.43-.1.09v-.35l-.07-.3V58.2h-1.17l-3,.13-.38.05-.35-.05-.38.13h-1.46v6.22l-.07,1.87-.11,1.51-.07.7-.07.17-.1.17-.07.35-.1.3v.48l-.11.61-.24.21h-.21l-.14.17-.34.09h-.11l-.21.08-.2-.08-.21.17-.14-.13h0l-.07.05-.24-.13-.28.08-.2.18-.11-.22h-.1l-.28.05-.31-.13-.24.08-.14.22-.14-.09-.11-.13-.17.13h-.21l-.14-.22-.14-.35V69.14h-.1l-.07-.17V66.82l-.07-.48V52.68l.07-1.43V44.37l.13-1.12.18-1.35.1-.6.52-.09h.49l.1-.08h.59l.14-.08.17.12.21-.17.24.09h.28l.28-.09h.28l.14-.13.17-.09h1.25l.35.09v2.81l-.07.48v7.32l.07.22.13.08H326.09v.05l-.07.08h-.1l-.25-.08-.13.13v.08h.76l2.08.09h2.02l.07-.3V51.4l.07-.57v-.69l-.07-.43-.07-.52V47.67l.07-.61V45.72l.06-1.21v-.65l.07-1v-1l.35-.44.45.05.31-.05h.39l.24.05.31-.09.35.09.17-.05.1.09h.14l.18-.17.24.08.35-.12h.73l.34.09.49.08.07.35v3.46l-.07.57V55.88l.07.39v2.41l.07.91v6.29l-.06.61v1.39l-.07.26.07.34v.65l-.07.65-.17.39-.07.26h-.14l-.07.05v.2l-.07.09h-.38l-.14.13-.1-.13-.11-.17h-.14l-.07.18-.06.21h-.14v-.35l-.07-.21h0v.47l-.27.09-.18-.09-.07.22h0l-.07-.09h-.07v.3l-.04.35h-.14l-.11-.21-.17-.13-.1.26-.07.3h-.07Zm-1.7-13.17v-.26l-.14-.09-.07.17.14.22Zm.45,0v-.26h-.07v.26Zm.59,7.63-.07-.39h-.1v.26l-.1.35.2.13Zm-.07-2h-.07v.09Zm0,.91v-.47h0v.65h0Zm0,4v-.09h-.11l-.07.13h.07Zm3.09-.43h-.07v.09l.1.09Zm0,.91v0Zm.07-2v.17l.07.05Z"/>
      <path class="cls-1" d="M341.52,72.02H340.3l-.34.13-.25-.13h-.34l-.56.09-.21-.05h-.31l-.17-.13h-.14l-.07-.35.1-.52v-.65l.07-.87V65.95l.07-.26V62.4l.07-1.47V53.56l.1-1.52V50.4l-.07-1.52v-.82l.07-1.61V41.56l.24-.09.14-.17h.49l.48-.05h2.26007l.21-.13h.38l.62-.08h.59l.28-.08h.35l1,.08,1.67.09h.9l.49.14h4.57l.14.25.18985.16.07.43.21.3.21,1.13-.11.52-.07.3v.22l.07.43.14.44.11.21-.21.18-.28.17-.31.56-.42.57-.24.26-.17.13h-.25l-.17-.13h-1.46l-.17.13h-6.42v4.9l.14.08h3.33l1,.09h.38l.32.13h.93l.56.17h.45l.35.44.06.34v.44l.24.34-.14.35.14.3v.74l.14.3-.14.26-.07.35v.61l.14.13.11.13-.11.17h-.14l-.27.26-.25.52.11.26v.13l-.42.13-.24.35h-.59l-2.71-.09h-1.14l-1.53.09-.87.13-.27.22v.26l-.07.91v4.24l.7.05h.76l.35.08h1l.42.09.87.13h3.26l.45-.09h1.11l.41.09.14.26.11.34v.35l.21.22v.21l-.14.22.14.17.06.31-.13.08-.11.05v.13l.18.17.06.3-.13.44-.07.56-.14.3.24.22.14.13-.07.09-.28.17-.31.26-.1.35v.43l-.7.43h-1.38l-1.53.05h-1.08l-.27.09h-5.59l-.14.17h0Z"/>
      <path class="cls-1" d="M357.32,71.15h-.14l-.21-.17-.21.09v-.13h-.11l-.17.13h-.55l-.18-.09-.14-.17-.1.21-.17.18-.21-.13-.21-.26h-.28l-.17-.13-.11.09-.1.13-.1-.18-.25-.13-.07-.34.18-.78.21-1.3.1-.83.28-1.51.24-1.39.14-.87.14-1,.21-1.3.24-1.39.28-1.69.17-1,.28-1.91.17-1,.28-1.9.17-1.26.21-1,.31-1.56.28-1.47.31-1.47.24-1.3.35-1.22.21-.73.17-.78.18-.52v-.22h.14l.14-.13.35.13.27.09.18-.17.31.08.21.14.17-.09.28.09h.27l.28.09.35-.13h.28l.14.09.31-.13H363.87l.32-.09.17.09.17-.18h.31l.28.83.24.78.32,1.08.34,1.34.3,1.22.17.73.28,1.35.18.82.1.48.24,1,.21,1,.21.82.14.83.24.86.17.83.35,1.6.14.82.31,1.74.24,1.3v.34l.14.7.38,2,.21,1.13.07.56.07.65.14,1,.17.87v.56l.11.52v.43l-.21.26-.24.13-.32.22-.13.13h-.21l-.25.13-.17.22h-.14l-.34.26h-.11l-.14.13h-.14l-.1.3-.21.17-.21-.13h-.17l-.28.43-.1-.09-.14-.13-.49.26-.48.13-.21-.3-.21-1.08-.21-1.28-.27-1.43-.28-1.34-.51-.13h-3.78l-.52.08-.07.26-.11.52-.1.39-.17,1-.14.82-.18.82-.24,1-.24.22h-.24l-.25.22h-.27l-.11.08h-.17l-.21-.21-.14.3h-.07Zm6.28-11.87-.07-.82-.21-1.17-.11-.57-.06-.34-.21-.87-.14-.87-.17-.78-.18-.86-.1-.39-.07-.09-.07.13-.14.87-.17,1-.18,1.09-.24,1.69-.14.78-.14,1.08.38.09h.73l1,.08Z"/>
      <path class="cls-1" d="M378.95,71.02H371.6l-.42-.21-.11-.39v-1l.1-.3V55.85l.07-.52V53.64l.07-1.48.1-1.82.07-1.51V46.27l.07-1.21-.07-.74V41.95l.07-.35v-.69l.11-.48h1.21l.1.09.11-.09.21.09h.21l.13-.08.21.17h.07l.11-.08h.27l.25-.17h.34l.35-.09h.24l.14-.08h.28l.07.08h.59l.1.17.14-.08.07.12v1.65l.07.43v2.47l-.07,1h-.13l-.11.48V48.5l-.07,1,.25,1.18v3.43l-.07,1.12v2.99l-.07,1.09-.07.82V61l-.11.21.07.44-.07.65v.52l-.14.39.07.52v.39l.17.3h1.74l1,.09.66.08h.69l.28.09h.37l.69.08h2.74V66.1l-.14.39.14.09.11.13v-.03l-.11.26v.21l-.07.09v.44l-.14.22.14.09.24.08.14.18-.17.13-.17.26.07.21.17.09v.09l-.1.08-.14.18v.34l-.1.13-.07.13v.26l-.17.35-.05994.18h-.98l-.17.22-.28.13-.27-.09v-.13h-.21l-.1.13-.11-.13-.27-.09h-.42l-.14.17h-.1l-.35-.17h-.87l-.2.21h-.56Z"/>
      <path class="cls-1" d="M388.59,71.46l-.27-.13V68.38l.07-.39-.07-.61v-5.8l-.07-1.82V57.2l.07-3.38V50.61l-.07-1.25v-1l-.07-.7h-2.15l-1.7.18h-1.52l-.49-.16.07-.22v-.47l.1-.48-.1-.22v-.26l.18-.34-.11-.26.07-.35V44l.11-.48v-.35l.07-.34.11-1v-.31l.38-.38.63-.09.62.13h1.18l.38.08.52-.08h.45l.35-.09h5.07l1.17-.18,1-.13h5.29l.07.52-.1.52.07.39-.07.43.07.31-.11.21-.07.31-.1.13.07.21v.35l.07.17v.26l-.18.18-.1.22.24.38-.24.35-.1.35v.26l.14.17-.24.26-.21.35h-.73l-.35.22-.34.09h-3.33l-.39.17-.41.3v.83l.07,1.38V62.88l-.07,1v3.69l-.06,1.21v1.95l-.21.17-.45.13-.32.39h-.13l-.11-.34-.17.13-.11.08-.14-.08-.13.3v-.17h-.14l-.07.13-.17.08-.21-.08h-.14l-.14-.13-.14.08h-.66l-.17-.08-.11.08h-.17l-.1.17Z"/>
      <path class="cls-1" d="M415.2,71.76V71.5l-.07-.13h-.07v.13l-.07.22-.2-.13-.11-.26v-.35l-.11-.3v-.3l-.07-.26h-.14v.3l-.1.18-.1-.09h-.11l.07.57v.52l-.18-.39V69.73l-.1-.39.1-.47.07.08v.44l.27.34h.28l.07-.17v-.22h-.35V68.03l.07-.78v-.09l.11.26.1.39-.1.13v.18l.1.08.11-.47.1-.7-.14-.17h-.17l-.11.22h-.06l-.07-.52V62.68l-.07-.43v-3.1l-.21-.17-.14-.26-.07.13-.14.43-.1.09v-.35l-.07-.3V58.2h-1.17l-3,.13-.38.05-.5499-.1-.38.13h-1.46v6.27l-.16,1.84-.11,1.51-.07.7-.07.17-.1.17-.07.35-.1.3v.48l-.11.61-.24.21h-.21l-.14.17-.34.09h-.11l-.21.08-.2-.08-.21.17-.14-.13h0l-.07.05-.24-.13-.28.08-.2.18-.11-.22h-.1l-.28.05-.31-.13-.24.08-.14.22-.14-.09-.11-.13-.17.13h-.21l-.14-.22-.14-.35V69.11h-.1l-.07-.17V66.82l-.07-.48V52.68l.07-1.43V44.37l.13-1.12.18-1.35.1-.6.52-.09h.49l.1-.08h.59l.14-.08.17.12.21-.17.24.09h.28l.28-.09h.28l.14-.13.17-.09h1.25l.35.09v2.81l-.07.48v7.32l.07.22.13.08h2.09v.05l-.07.08h-.1l-.25-.08-.14.13v.08h.76l2.08.09h2.02l.07-.3V51.4l.07-.57v-.69l-.06-.43-.07-.52V47.67l.07-.61V45.72l.07-1.21v-.65l.07-1v-1l.35-.44.45.05.31-.05h.38l.25.05.31-.09.35.09.17-.05.1.09h.14l.18-.17.24.08.35-.12h.72l.35.09.49.08.07.35v3.46l-.07.57V55.88l.07.39v2.41l.07.91v6.29l-.06.61v1.39l-.07.26.07.34v.65l-.07.65-.17.39-.07.26h-.14l-.07.05v.2l-.07.09h-.38l-.14.13-.1-.13-.11-.17h-.14l-.07.18-.07.21h-.13v-.35l-.07-.21h0v.47l-.27.09-.18-.09-.07.22h0l-.07-.09h-.07v.3l-.06.39h-.14l-.11-.21-.17-.13-.11.26-.06.3h-.07Zm-1.7-13.17v-.26l-.14-.09-.07.17.14.22Zm.45,0v-.26h-.07v.26Zm.59,7.63-.07-.39h-.1v.26l-.11.35.21.13Zm-.07-2h-.07v.09Zm0,.91v-.47h0v.65h0Zm0,4v-.09h-.11l-.07.13h.07Zm3.09-.43h-.07v.09l.1.09Zm0,.91v0Zm.07-2v.17l.07.05Z"/>
      <path class="cls-1" d="M442.23,72.06l-.14-.56h-.21l-.35.17-.21-.09-.14.13-.17-.08-.28-.31-.45-.91-.55-1.38-.49-1.35-.38-.82-.35-.91-.69-1.6-.66-1.48-.73-1.82-.55-1.25-.38-.78-.84-1.65-.59-1.47-.52-1.22-.34-.7-.38-.73v-.09h-.07v4.81l-.07,1.08v5.72l-.14.22.07.26v.34l-.07.26.07.35v.22l-.07.34-.27.13v.22l.11.17.07.13h.17v.22l-.21.08-.24.09-.11.3.11.22h0l.07.13v.09l-.07.35v.26l.1-.09.11.09-.07.21-.11.22.07.43v.61h.13l.11.34v.35l-.17.09-.21.13h-.32l-.5.15h-.27l-.21.09h-.28l-.21.09h-.14l-.27.18-.28.26-.24-.22h-.11l-.14.34h-.07l-.14-.08-.1.08h-.75l-.17.08-.17-.08-.21-.18v-.6l.17-.48.24-.13.14-.13v-.13L428,70.6h-.31l-.17-.48V68.69l-.07-.52v-.73l.07-1-.07-1,.07-1v-3.6l.07-1.86-.07-1.74v-9.1l.07-1.17.07-.69V44.76l.06-.73.11-1.52V41.6l.18-.09.34-.08.35.08h.83l.18-.08.41.17.14-.13h.59l.28.09h.28l.13-.13h.35l.14-.09h.87l.21-.13.38.35.55,1,.77,1.43.79,1.61.35.78.38.73.73,1.56.35.74.83,1.69.66,1.52.45,1.17.66,1.34.35.74.41.82h.07v-2.3l-.07-.35v-.56l-.11-.35.18-.86-.11-.44v-.47l.07-.57v-7.8l.11-1V41.6l.35-.13h1.67l.2.08.21-.08h.31l.28-.13h.31l.49-.09.28.05.27-.05h.63l.21.09h.17v2.47l-.07,1.56v1.26l-.07,1.56v.86l-.07,1.48v1.78l-.07,1.26v7.45l.07,1.26v6.37l-.1,1.34v1.09l-.11.6-.2.09h-.56l-.21.17h-.1l-.18-.09-.13-.08-.11.17-.07.09h0l-.28-.13-.28.35h-.17l-.26-.57-.18-.05-.14.26h-.17l-.07-.21v-.05h-.07l-.11.22-.07.47-.07.35h-.12Z"/>
      <path class="cls-1" d="M452.04,72.02h-1.21l-.35.13-.24-.13h-.35l-.55.09-.21-.05h-.31l-.18-.13h-.13l-.07-.35.1-.52v-.65l.07-.87V65.95l.07-.26V62.4l.07-1.47V53.56l-.07-1.52V50.4l-.07-1.52v-.82l.07-1.61.07-.78V41.56l.24-.09.14-.17h.48l.49-.05h2.26l.2-.13h.38l.63-.08h.59l.28-.08h.34l1,.08,1.66.09h.91l.48.14H463.57l.14.25.14.31.07.43.21.3.2,1.13-.1.52-.07.3v.22l.07.43.14.44.1.21-.21.18-.27.17-.32.56-.41.57-.24.26-.18.13h-.24l-.17-.13h-1.46l-.17.13h-6.42v4.9l.14.08h3.37l1,.09h.38l.31.13h.94l.55.17h.45l.35.44.07.34v.44l.25.34-.14.35.14.3V56l.14.3-.14.26-.07.35v.61l.14.13.1.13-.1.17h-.14l-.28.26-.24.52.1.26v.13l-.41.13-.25.35h-.59l-2.7-.09h-1.15l-1.52.09-.87.13-.28.22v.26l-.07.91v4.24l.69.05h.77l.34.08h1l.42.09.86.13h3.26l.45-.09h1.11l.42.09.14.26.1.34v.35l.21.22v.21l-.14.22.14.17.07.31-.14.08-.24014-.19v.13l.17.17.07.3-.14.44-.07.56-.14.3.25.22.14.13-.07.09-.28.17-.31.26-.11.35v.43l-.69.43h-1.39l-1.53.05h-1.07l-.28.09h-5.58l-.14.17h0Z"/>
      <path class="cls-1" d="M469.32,72.02H468.1l-.35.13-.24-.13h-.35l-.55.09-.21-.05h-.31l-.17-.13h-.14l-.07-.35.1-.52v-.65l.07-.87V65.95l.07-.26V62.4l.07-1.47V53.56l-.07-1.52V50.4l-.07-1.52v-.82l.07-1.61.07-.78V41.56l.24-.09.14-.17h.49l.48-.05h2.26l.2-.13h.39l.62-.08h.59l.28-.08h.34l1,.08,1.67.09h.9l.48.14h4.58l.14.25.14.31.07.43.21.3.21,1.13-.11.52-.07.3v.22l.07.43.14.44.1.21-.2.18-.28.17-.31.56-.42.57-.24.26-.18.13h-.24l-.17-.13h-1.46l-.17.13h-6.42v4.9l.14.08h3.37l1,.09h.38l.31.13h.94l.55.17h.46l.34.44.07.34v.44l.24.34-.14.35.14.3V56l.14.3-.14.26-.07.35v.61l.14.13.1.13-.1.17H479.32l-.28.26-.24.52.1.26v.13l-.41.13-.25.35h-.58l-2.71-.09H473.8l-1.52.09-.87.13-.28.22v.26l-.07.91v4.24l.69.05h.77l.34.08h1l.42.09.86.13h3.18l.46-.09h1.11l.41.09.14.26.1.34v.35l.21.22v.21l-.14.22.14.17.07.31-.14.08-.1.05v.13l.17.17.07.3-.14.44-.07.56-.13.3.24.22.14.13-.07.09-.28.17-.31.26-.11.35v.43l-.69.43h-1.39l-1.52.05h-1.08l-.28.09h-5.58l-.14.17h0Z"/>
      <path class="cls-1" d="M484.9,72.02v-.26h-.32l-.38-.13h-.69l-.35-.13-.07-.57.07-.52.14-.34v-.22l-.07-.09.07-.47-.11-.52v-.78l.1-.22-.07-.3v-.44l-.07-.43v-.17l-.1-.52V48.58l.11-2.08.14-1.74V41.77l.14-.47.55-.13h.87l.38-.14h.32l.07.09h.41l.24-.09h.32l.55-.13h.28l.21-.08h.62l.66-.08h.59l2.08.08h.56l.93.13.87.26.8.4.59.43.52.47.62.57.31.39.42.43.63.61.41.52.42.65.52.91.55,1.21.52,1.3.28,1.17.21,1,.21,1.17.17,1.08.07,1,.11993,1.16.07,1.3v1.48l-.1.86-.11,1-.17,1-.14.73-.27,1.17-.49,1.35-.31.82-.18.48-.34.65-.28.82-.52.78-.38.56-.21.44-.21.17-.28.3-.2.39-.39.35-.52.43-.2.09-.35.22-.28.21-.24.05-.38.3-.59.17-.8.22-.8.13-.69.09h-.35l.14-.22.14-.3-.14-.13h-.62l-.14.26.07.26v.13h-1.15v-.09h.38l.17-.05h-.59v-.13l.07-.09h.14l.35.09.24-.13-.35013-.16h-.45l-.28.09-.94-.09h-.24l-.28.09-.24-.05h-.28l-.38-.13-.21.13h-1.11l-.21-.13v-.13l-.28.26-.34.13v.61h-.07Zm5.23-5.94.66-.08,1.11-.18.87-.43.9-.48.59-.56.35-.48.28-.52.31-.69.45-1.65.42-1.86.1-1.78V56.29l-.07-.48v-.95l-.17-1.3-.11-.56-.1-.44-.4-1.21-.87-1.39-1.77-1.82-.45-.26h-.31l-.31-.13-.87-.13-1.28-.13h-.77l-.1.09v9.79l-.07.26v3.04l-.11.22v2.27l.07.39v.21l-.07.39v.87l.14.74v.34h.07l.59.08Z"/>
      <path class="cls-1" d="M505.54,72.32l-.59-.21-.49-.31h-.45l-.45-.26-.73-.43-.45-.31-.28-.26-.45-.34-.31-.31-.31-.21-.24-.31-.35-.34-.28-.39v-.18l-.07-.21v-.22l.1-.17.21-.22.14-.22v-.21l.07-.13.24-.31h.1v-.26l.14-.17v-.18l.07-.13.28-.3.14-.43.17-.09.11-.39.13-.35.18-.13v-.26l.07-.08.11-.26h.27l.18.18.1.13h0l-.07-.08v-.18l.21-.13.69.57.87.73.66.48.62.13h.73l.9-.43.56-.43.49-1,.38-1.43v-.87l-.14-.34-.17-.48-.14-.52-.14-.22-.17-.26-.24-.17-.18-.22-.13989-.52-.24-.34-.28-.31-.17-.26-.25-.21-1.07-1.13-.7-.91-.48-.69-1-1.43-.7-1.52-.2-.69-.21-1,.00006-1.27v-1.6l.14-.69.45-1.09.28-.56.24-.43.18-.31.48-.64.45-.57.45-.48.56-.52.69-.56.9-.39.49-.26.76-.3,1.46-.22,1.63.09,1,.13.35.09.21.13v.17h-.1l-.07.09.1.17.18.09v.35l-.1.21.14.61h-.14l.07.13v.56l.1.26v.13h-.13v.17l.21.26-.1.13.1.13v.48l-.17.09v.08l.11.13.2.14.11.12v.09h-.11l-.17.09v.69l-.1.18v.47l-.1.57-.11.17-.24-.22-.83-.3-.63-.13h-1.35l-.83.35-.25.26-.27.26-.32.43-.13,1,.41.87.73.87.94,1.12.59.65.55.74,1,1.21,1,1.48.87,1.47.28.74.45,1.6.24,1.56.07,1-.07,1.08-.41,1.65-.35.91-.73,1.56-.62.87-.38.39-.25.39-1,1.08-.66.52-.73.48-.87.39-.69.26-.56.13-1.25.13Z"/>
    </g>
  </g>
</Floater>
    )
}
export default CommunityCollaboration;