import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"

import Layout from "../components/layout"
import SEO from "../components/seo"

import PageHero from "../components/PageHero/PageHero"
import FreeCounselingProgram from "../components/HomePage/FreeCounselingProgram"

import HomepageModal from "../components/HomePage/HomepageModal"

function Modal() {
  const [modalShow, setModalShow] = React.useState(false)

  useEffect(() => {
    // if (!sessionStorage.popupModal) {
      const modalTimer = setTimeout(() => {
        setModalShow(true)
        sessionStorage.popupModal = 1
      }, 1000)
      return () => {
        clearTimeout(modalTimer)
      // }
    }
  }, [])

  return (
    <>
      <HomepageModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  )
}

const IndexPage = ({ data }) => {
  
  const document = data.allPrismicPage.edges[0].node.data

  const prismicHeroImage = data.allPrismicPage.edges[0].node.data.hero_image.localFile
  console.log(prismicHeroImage)

  return (
  <Layout>
    <SEO title="Home" />
    <Modal />
    <PageHero
      heading={document.hero_title.text}
      //description={<div dangerouslySetInnerHTML={{ __html: body}} />}
      description={<><RichText render={document.hero_content.raw} /></>}
      image={prismicHeroImage}
      alt={data.allPrismicPage.edges[0].node.data.hero_image.alt}
      //mobileImage={heroMobilePhoto}
      style={{color: '#C97D60'}}
      fillColor="#434081"
      bgColor="#434081"
    />
    <FreeCounselingProgram />
    {/* <PageHeroBottom
      fillColor="#D8D8F6"
      bottomImage={bottomPhoto}
      heading="Teton Valley’s mental health matters and we’re here to support you."
    /> */}
 
  </Layout>
  )
}

export const query = graphql`
{
  allPrismicPage(filter: {uid: {eq: "home"}}) {
    edges {
      node {
        data {
          hero_title {
            text
          }
          hero_content {
            raw
          }
          hero_image {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
  holdingHands: file(relativePath:{eq:"holding-hands@2x.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2500, quality: 80) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  holdingHandsMobile: file(relativePath:{eq:"holding-hands-mobile.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2500, quality: 80) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  tetonsMoonrise: file(relativePath:{eq:"TetonsMoonrise.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2500, quality: 80) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`

export default IndexPage
